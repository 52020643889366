import React, { useEffect } from 'react';
import { faClose, faRocket, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DialogContent } from 'components';
import { Dialog } from 'components';
import { LotteryResult } from 'helpers';
import { useGetAccount, useGetActiveTransactionsStatus } from '../hooks';
import { useCheckIfUserHasAirdrop, useClaimAirdrop } from '../pages/Home/hooks';

export const ClaimAirdropModal = ({ show, onCloseModal }: { show: boolean; onCloseModal: () => void }) => {
  const { address } = useGetAccount();
  const { data: sft, refetch, isLoading } = useCheckIfUserHasAirdrop(address);
  const { claimAirdrop, lotteryResult, removeSignedTransction } = useClaimAirdrop();
  const { success, pending } = useGetActiveTransactionsStatus();

  const isLoadingLottery = isLoading || pending;

  const handleClaim = async () => {
    if (address && sft) {
      await claimAirdrop(sft.collection, sft.nonce);
    }
  };

  const handleCloseModal = () => {
    if (!isLoadingLottery) {
      removeSignedTransction();
      onCloseModal();
    }
  };

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success, refetch]);

  return (
    <Dialog open={show} onOpenChange={handleCloseModal}>
      <DialogContent
        hideCloseButton
        disableOutsideClick={isLoadingLottery}
        className='border-0  w-fit max-w-screen bg-gray-100  max-h-[80%]  sm:min-w-fit sm:w-full p-6'
      >
        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-lg'>Airdrop</h2>
          <FontAwesomeIcon icon={faClose} className='text-[#888888] h-5 w-5 cursor-pointer' onClick={handleCloseModal} />
        </div>
        <div className='pt-6'>
          {!!lotteryResult ? (
            <div className='mt-[-2rem]'>
              <p className='mb-2'>
                {lotteryResult === LotteryResult.WIN
                  ? 'Congratulations! You’ve won. Check your wallet!'
                  : 'Thank you for participating. Better luck next time!'}
              </p>
            </div>
          ) : (
            <div className='flex flex-col py-5 px-6 items-center text-center'>
              <div className={'text-md w-12 h-12 shadow-sm flex items-center justify-center bg-teal-100 text-teal-500 shadow-teal-300'}>
                <FontAwesomeIcon icon={faRocket} />
              </div>
              <p className='mt-6'>You’re eligible for the xPortal SFT airdrop!</p>
              <p className='mb-2'>By claiming it, you'll get a chance to win $BOBER.</p>
              <p>Prizes:</p>
              <ul>
                <li>1 x $100 - 384,615 $BOBER</li>
                <li>10 x $25 - 96,154 $BOBER</li>
                <li>100 x $5 - 19,231 $BOBER</li>
                <li>900 x $0.50 - 1,923 $BOBER</li>
              </ul>
              {isLoadingLottery && <p className='mt-6'>Please wait...</p>}
              <div className='flex justify-center'>
                <Button variant='outline' className='w-full mt-6 disabled:text-gray-600' disabled={isLoadingLottery} onClick={handleClaim}>
                  {isLoadingLottery ? <FontAwesomeIcon icon={faSpinner} className='animate-spin' /> : 'Try your luck'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
