import { IconDefinition, faCheck, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TRANSACTIONS_ENDPOINT } from '@multiversx/sdk-dapp/apiCalls/endpoints';
import { DataTestIdsEnum } from '@multiversx/sdk-dapp/constants';
import { TransactionServerStatusesEnum } from '@multiversx/sdk-dapp/types';
import { EXPLORER_URL } from 'config';
import NavigateIcon from '../../assets/img/ic_tx_navigate.svg?react';

const iconSuccessData = {
  icon: faCheck
};

const iconFailedData = {
  icon: faTimes
};

const iconPendingData = {
  icon: faCircleNotch
};

const iconData: Record<string, typeof iconPendingData> = {
  pending: iconPendingData,
  success: iconSuccessData,
  fail: iconFailedData,
  invalid: iconFailedData,
  timedOut: iconFailedData
};

export interface TransactionDetailsBodyPropsType {
  isTimedOut?: boolean;
  iconSrc?: {
    icon: IconDefinition;
  } | null;
  className?: string;
  status?: TransactionServerStatusesEnum;
  hash: string;
}

export const TransactionDetailsBodyComponent = ({ className, hash, status, iconSrc, isTimedOut }: TransactionDetailsBodyPropsType) => {
  return (
    <div className='flex items-center mr-6' key={hash} data-testid={DataTestIdsEnum.transactionDetailsToastBody}>
      <span className='text-gray-600 text-sm'>{`${hash.substring(0, 10)}...${hash.substring(hash.length - 20)}`}</span>

      <a href={`${EXPLORER_URL}/${TRANSACTIONS_ENDPOINT}/${hash}`} target='_blank' className='ml-2'>
        <NavigateIcon />
      </a>
    </div>
  );
};
