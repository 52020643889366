import { useEffect, useState } from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import { ActionOrConnect, Button } from 'components';
import { ClaimAirdropModal } from 'components/ClaimAirdropModal';
import { useClaimAirdropModal } from 'hooks/useClaimAirdropModal';
import { RouteNamesEnum } from 'localConstants';
import { LaunchCountdownRenderer } from 'pages/LaunchCountdown/components';
import { useCheckIfUserHasAirdrop } from '../hooks';

export const Hero = () => {
  const endDate = moment.utc('2024-04-08 17:00');
  const startDate = moment.utc('2024-04-03 10:00');

  const computePercentage = () => {
    const now = moment().unix();
    const percent = Math.min(100, Math.max(0, (now - startDate.unix()) / (endDate.unix() - startDate.unix())) * 100);
    return percent;
  };

  const [percentage, setPercentage] = useState(computePercentage());

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(computePercentage());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const coins = document.querySelector('#coins');
    const computer = document.querySelector('#computer');

    const onScroll = () => {
      const { scrollY } = window;

      if (coins) {
        coins.style.marginTop = `${-0.025 * scrollY}px`;
      }

      if (computer) {
        computer.style.marginTop = `${0.1 * scrollY}px`;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const { showModal, eligible, onCloseModal, onShowModal } = useClaimAirdropModal();

  return (
    <>
      {showModal && <ClaimAirdropModal show={showModal} onCloseModal={onCloseModal} />}
      <div className='bg-hero scroll-mt-16' id='home'>
        <div className='container mx-auto text-center py-32 flex flex-col gap-6 sm:py-20 sm:gap'>
          {eligible && !showModal && (
            <div className='mt-[-2rem]'>
              <p className='mb-2 text-primary-200'>You received the xPortal SFT airdrop for a chance to win $BOBER</p>
              <Button variant='outline' className='sm:w-full disabled:text-gray-600' onClick={onShowModal}>
                Try your luck
              </Button>
            </div>
          )}
          <h1 className='text-title text-title-primary lg:text-[48px] sm:text-2xl sm:leading-relaxed'>
            Welcome <br /> to MemeversX!
          </h1>
          <p className='text-description lg:text-md lg:leading-7'>
            Create. Launch. Explore. <br /> The Best Memecoins on MultiversX
          </p>
          <div className='flex gap-4 justify-center sm:flex-col'>
            <ActionOrConnect
              defaultComponent={<Button className='sm:w-full'>Create memecoin</Button>}
              connectedComponent={
                <Link to={RouteNamesEnum.createdLaunchpads}>
                  <Button className='sm:w-full'>Create memecoin</Button>
                </Link>
              }
            />
            <Link to={RouteNamesEnum.explore}>
              <Button variant='outline' className='sm:w-full'>
                Explore memecoins
              </Button>
            </Link>
          </div>
        </div>
        <div className='mx-auto pointer-events-none	bg-hero-pixels xl:bg-[length:contain]'>
          <div className='flex justify-center relative'>
            {percentage < 100 ? (
              <>
                <div className='absolute top-[-150px] xl:top-[-200px] lg:top-[-80px] md:top-[-80px] sm:top-[-20px] z-20'>
                  <img src='/img/coins.webp' alt='' id='coins' />
                </div>
                <div className='relative px-4 overflow-hidden' id='computer'>
                  <img
                    src='/img/computer-launch.webp'
                    alt=''
                    className='max-w-5xl h-auto z-10 relative xl:max-w-2xl lg:max-w-xl md:max-w-md sm:w-[400px] countdown-sm:w-[330px] xs:w-[275px]'
                  />
                  <div className='absolute top-0 z-0 w-full h-[60%]'>
                    <div className='m-[10.5%] w-[calc(100%-26%)] py-12 bg-primary-100 h-full flex flex-col gap-12 md:gap-8 sm:gap-6 justify-center items-center countdown-sm:gap-4 xs:w-[calc(100%-33%)] xs:m-[11%]'>
                      <img
                        src='/img/logo-countdown.png'
                        alt=''
                        className='max-w-[240px] xl:max-w-[160px] lg:max-w-[140px] md:max-w-[120px] sm:max-w-[100px] countdown-sm:max-w-[80px] h-auto xs:max-w-[60px]'
                      />
                      <Countdown date={endDate.toDate()} renderer={LaunchCountdownRenderer} />
                      <div className='px-28 w-full xl:px-20 lg:px-18 md:px-10'>
                        <div className='relative bg-[#00594D] p-2 h-10 w-full xl:h-8 lg:h-6 lg:p-1 countdown-sm:h-4'>
                          <div className='bg-[#F7D6AA] h-full' style={{ width: `${percentage}%` }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='absolute top-[-150px] lg:top-[-80px] md:top-[-20px]'>
                  <img src='/img/coins.webp' alt='' id='coins' />
                </div>
                <img src='/img/computer.webp' alt='' className='mt-[50px] mb-4 xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-[calc(100%-64px)]' id='computer' />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
