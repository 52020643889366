import BigNumber from 'bignumber.js';

export const countDecimalPlaces = (num: string, tokenDecimals: number, decimals?: number): number => {
  const denominated = new BigNumber(num).shiftedBy(-tokenDecimals);
  const match = denominated.toFixed().match(/\.(\d*[1-9])?0*$/);

  if (!match) {
    return 0;
  }

  const matchLength = match[1]?.length ?? 0;

  return decimals ? Math.min(matchLength, decimals) : matchLength;
};
