import { TransactionToastDefaultProps } from '@multiversx/sdk-dapp/UI/TransactionsToastList/components/TransactionToast/transactionToast.type';
import { AVERAGE_TX_DURATION_MS, CROSS_SHARD_ROUNDS } from '@multiversx/sdk-dapp/constants';
import { useGetTransactionDisplayInfo } from '@multiversx/sdk-dapp/hooks';
import { useSelector } from '@multiversx/sdk-dapp/reduxStore/DappProviderContext';
import { shardSelector } from '@multiversx/sdk-dapp/reduxStore/selectors';
import { TransactionBatchStatusesEnum } from '@multiversx/sdk-dapp/types';
import { getAreTransactionsOnSameShard, getUnixTimestamp, getUnixTimestampWithAddedMilliseconds, getIsTransactionPending, getIsTransactionTimedOut } from '@multiversx/sdk-dapp/utils';
import { useEffect, useMemo } from 'react';
import { getToastDataStateByStatus } from './getToastDataStateByStatus';
export const useTransactionToast = ({ toastId, transactions, status, lifetimeAfterSuccess, startTimestamp, endTimeProgress, onDelete }: TransactionToastDefaultProps) => {
  const transactionDisplayInfo = useGetTransactionDisplayInfo(toastId);
  const accountShard = useSelector(shardSelector);

  const areSameShardTransactions = useMemo(() => getAreTransactionsOnSameShard(transactions, accountShard), [transactions, accountShard]);

  const shardAdjustedDuration = areSameShardTransactions ? AVERAGE_TX_DURATION_MS : CROSS_SHARD_ROUNDS * AVERAGE_TX_DURATION_MS;

  const transactionDuration = transactionDisplayInfo?.transactionDuration || shardAdjustedDuration;

  const [startTime, endTime] = useMemo(() => {
    const startTime = startTimestamp || getUnixTimestamp();
    const endTime = endTimeProgress || getUnixTimestampWithAddedMilliseconds(transactionDuration);

    return [startTime, endTime];
  }, []);

  const progress = {
    startTime,
    endTime
  };

  const isPending = getIsTransactionPending(status);
  const isTimedOut = getIsTransactionTimedOut(status);

  const toastDataState = getToastDataStateByStatus({
    status,
    toastId,
    transactionDisplayInfo,
    classes: {}
  });

  const handleDeleteToast = () => {
    onDelete?.(toastId);
  };

  useEffect(() => {
    if (status !== TransactionBatchStatusesEnum.success || !lifetimeAfterSuccess) {
      return;
    }

    const timeout = setTimeout(() => {
      handleDeleteToast();
    }, lifetimeAfterSuccess);

    return () => {
      clearTimeout(timeout);
    };
  }, [lifetimeAfterSuccess, status]);

  return {
    isCrossShard: !areSameShardTransactions,
    progress,
    isPending,
    isTimedOut,
    toastDataState,
    handleDeleteToast
  };
};
