import { useEffect, useState } from 'react';
import { Address, TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetSuccessfulTransactions } from '@multiversx/sdk-dapp/hooks';
import { getChainID } from '@multiversx/sdk-dapp/utils';
import { airdropSmartContract } from 'abi/airdropSmartContract';
import { fetchLotteryResult, LotteryResult, sendAndSignTransactions } from 'helpers';
import { useGetAccount, useLocalStorage } from 'hooks';
import { removeSignedTransaction } from '@multiversx/sdk-dapp/services/transactions/clearTransactions';

function useClaimAirdrop() {
  const { address } = useGetAccount();
  const completedTransactions = useGetSuccessfulTransactions();
  const [sessionId, setSessionId] = useLocalStorage<string | null>(`claimLottery_ticket_${address}`, null);
  const [lotteryResult, setLotteryResult] = useState<LotteryResult | undefined>(undefined);

  useEffect(() => {
    const fetch = async (hash: string) => {
      const lotteryResultFetched = await fetchLotteryResult(hash);
      setLotteryResult(lotteryResultFetched);
    };
    if (sessionId == null) return;
    const successfulTransactions = completedTransactions.successfulTransactions[sessionId]?.transactions;
    if (successfulTransactions === undefined || successfulTransactions.length === 0) {
      return;
    }
    const hash = successfulTransactions[successfulTransactions.length - 1]?.hash as string | undefined;

    if (hash) {
      fetch(hash);
    }
  }, [completedTransactions]);

  const removeSignedTransction = () => {
    if (!sessionId) {
      return;
    }

    removeSignedTransaction(sessionId);
  };

  const baseGasLimit = 10_000_000;

  const displayInfo = {
    processingMessage: 'Processing claim airdrop transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Claim airdrop successful'
  };

  const claimAirdrop = async (tokenIdentifier: string, nonce: number) => {
    try {
      const interaction = airdropSmartContract.methods.claimLottery([1]);
      interaction
        .withGasLimit(baseGasLimit)
        .withChainID(getChainID())
        .withSender(new Address(address))
        .withSingleESDTNFTTransfer(TokenTransfer.semiFungible(tokenIdentifier, nonce, 1));

      const transaction = interaction.buildTransaction();
      const { sessionId } = await sendAndSignTransactions([transaction], displayInfo);
      setSessionId(sessionId);
    } catch (err) {
      console.error('Unable to call claim airdrop', err);
    }
  };

  return { claimAirdrop, lotteryResult, removeSignedTransction };
}

export { useClaimAirdrop };
