import { useGetAccount, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useQuery } from 'react-query';
import { CreateLaunchpadDTO } from 'types';

const fetchCreatedLaunchpads = async (nativeAuth?: string) => {
  try {
    const { data } = await axios.get(
      `/v1/launchpads/create`,
      {
        baseURL: API_URL,
        headers: {
          Authorization: `Bearer ${nativeAuth}`
        }
      }
    );
    return data as CreateLaunchpadDTO[];
  } catch (error) {
    throw error;
  }
};

export const useGetCreatedLaunchpads = (): {
  data?: CreateLaunchpadDTO[];
  isLoading?: boolean;
  error?: any;
} => {
  const { address } = useGetAccount();
  const loginInfo = useGetLoginInfo();

  const { data, isLoading, error } = useQuery({
    queryKey: ['created-launchpads', address, loginInfo.tokenLogin?.nativeAuthToken],
    queryFn: () => fetchCreatedLaunchpads(loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    enabled: loginInfo.tokenLogin?.nativeAuthToken !== undefined
  });

  return { data, isLoading, error };
};
