import { useGetCreatedLaunchpads } from './hooks';
import { Button, Card, ErrorState, FormatAmount, LaunchpadIcon, LoadingState } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { RouteNamesEnum } from 'localConstants';
import { DeleteDraftLaunchpadModal, DraftLaunchpadStatus } from './components';
import { CreateLaunchpadDTO, LaunchpadState } from 'types';
import { useState } from 'react';
import { countDecimalPlaces } from 'utils/countDecimalPlaces';
import { AuthRedirectWrapper } from 'wrappers';
import { KarmaQuestsModal } from 'pages/Launchpad/components/KarmaQuestsModal';

export const CreatedLaunchpads = () => {
  const [toBeDeletedLaunchpadId, setToBeDeletedLaunchpadId] = useState<string | null>(null);
  const { data: launchpads, error, isLoading } = useGetCreatedLaunchpads();

  if (isLoading) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <LoadingState title="Loading launchpads" />
      </div>
    );
  }

  if (error || !launchpads) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <ErrorState />
      </div>
    );
  }

  const CreateNewLaunchpadCard = () => {
    return (
      <Link to={RouteNamesEnum.createLaunchpad.replace(':id', 'new')} className='col-span-1 lg:order-first'>
        <div className='flex flex-col items-center justify-center h-64 gap-3 border border-gray-300 border-dashed lg:flex-row lg:h-fit lg:py-5 lg:px-6'>
          <div className="text-md text-green-300 bg-green-100 w-12 h-12 shadow-sm shadow-green-300 flex items-center justify-center">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <p className='text-xl'>Create new Launchpad</p>
        </div>
      </Link>
    );
  };

  const renderLaunchpad = (launchpad: CreateLaunchpadDTO) => {
    return (
      <Card className="flex flex-col gap-6" key={launchpad.id}>
        <div className="flex justify-between items-start">
          <div className="w-32">
            <span className="border border-gray-200 px-4 py-2 h-11 inline-block">Fair Launch</span>
          </div>
          <div className="w-32 flex justify-end">
            <DraftLaunchpadStatus status={launchpad.currentStep} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-[-1.5rem]">
          <LaunchpadIcon icon={launchpad.tokenBranding?.pngUrl ?? launchpad.tokenBranding?.svgUrl} />
          <div className="text-center">
            <h5 className="text-primary-200 text-2xl leading-9">{launchpad.token.name}</h5>
            <p className="text-gray-900 text-xl leading-6">[<FormatAmount value={launchpad.token.supply} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.token.supply, launchpad.token.decimals)} />]</p>
          </div>
        </div>
        {launchpad.currentStep === LaunchpadState.PUBLISHED ? (
          <div className='flex flex-col gap-2'>
            <Link to={RouteNamesEnum.launchpadDetails.replace(':slug', launchpad.slug)}>
              <Button className="w-full" size="sm">View</Button>
            </Link>
          </div>
        ) : (
          <div className='flex gap-2'>
            <Button variant='outline' size="sm" className='w-full border-status-danger text-status-danger hover:bg-[#e35d6a]' onClick={() => setToBeDeletedLaunchpadId(launchpad.id)}>Delete</Button>
            <Link to={RouteNamesEnum.createLaunchpad.replace(':id', launchpad.id)} className='w-full'>
              <Button className="w-full" size="sm">Edit</Button>
            </Link>
          </div>
        )}
      </Card>
    );
  };

  return (
    <AuthRedirectWrapper requireAuth={true}>
      <div className='container mx-auto flex flex-col gap-12 py-12 sm:px-4'>
        <h1 className='text-center text-2xl text-primary-200'>
          My Launchpads
        </h1>
        <div className='grid grid-cols-3 gap-6 xl:grid-cols-2 lg:grid-cols-1 lg:max-w-xl lg:w-full lg:mx-auto'>
          {launchpads.map((launchpad) => renderLaunchpad(launchpad))}
          <CreateNewLaunchpadCard />
        </div>
        {toBeDeletedLaunchpadId && (
          <DeleteDraftLaunchpadModal
            id={toBeDeletedLaunchpadId}
            show={!!toBeDeletedLaunchpadId}
            onHide={() => setToBeDeletedLaunchpadId(null)}
          />
        )}
      </div>
    </AuthRedirectWrapper>
  );
};
