import { LaunchpadSaleProps } from "./LaunchpadSale";
import BigNumber from "bignumber.js";
import { useGetEgldPrice } from "@multiversx/sdk-dapp/hooks";
import { countDecimalPlaces } from "utils/countDecimalPlaces";
import { useMemo } from "react";
import { FormatAmount } from "@multiversx/sdk-dapp/UI";

export const PriceInfo = ({ launchpad }: LaunchpadSaleProps) => {
  const { price: egldPrice } = useGetEgldPrice();

  const tokenPriceInUsd = useMemo(() => {
    if (!egldPrice) {
      return undefined;
    }

    return new BigNumber(launchpad.tokenPrice).multipliedBy(egldPrice).toFixed();
  }, [egldPrice]);

  const exchangeRate = new BigNumber(1).shiftedBy(18).dividedBy(launchpad.tokenPrice).toFixed(4);

  return (
    <div className="flex flex-col bg-gray-200 gap-3 p-3">
      <div className="flex flex-col items-center gap-1">
        <span className="text-xl text-gray-900">Price</span>
        <span className="text-2xl text-primary-100"><FormatAmount value={launchpad.tokenPrice} digits={countDecimalPlaces(launchpad.tokenPrice, launchpad.token.decimals)} /></span>
        {tokenPriceInUsd && (<span>~ $<FormatAmount value={tokenPriceInUsd} decimals={18} digits={0} showLastNonZeroDecimal={true} showLabel={false} /></span>)}
      </div>
      <span className="border-[1px] border-gray-300"></span>
      <div className="flex justify-between">
        <span>Reference rates:</span>
        <div className="text-gray-900 leading-4 text-end">
          <p>1 {launchpad.token.ticker} = <FormatAmount value={launchpad.tokenPrice} digits={countDecimalPlaces(exchangeRate, launchpad.token.decimals)} /></p>
          <p>1 EGLD = {exchangeRate} {launchpad.token.ticker}</p>
        </div>
      </div>
    </div>
  )
};