import { TransactionBatchStatusesEnum, TransactionsDefaultTitles, TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import IcTxError from '../../assets/img/ic_tx_error.svg?react';
import IcTxPending from '../../assets/img/ic_tx_pending.svg?react';
import IcTxSuccess from '../../assets/img/ic_tx_success.svg?react';

export interface ToastDataState {
  id: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  hasCloseButton: boolean;
  title: string;
  iconClassName: string;
  expires?: number | boolean;
}

interface GetToastsOptionsDataPropsType {
  status?: TransactionBatchStatusesEnum;
  toastId: string;
  classes?: Record<'success' | 'warning' | 'danger' | string, 'success' | 'warning' | 'danger' | string>;
  transactionDisplayInfo: TransactionsDisplayInfoType;
}

export const getToastDataStateByStatus = ({
  status,
  toastId,
  classes = {
    success: 'success',
    danger: 'danger',
    warning: 'warning'
  },
  transactionDisplayInfo
}: GetToastsOptionsDataPropsType) => {
  const successToastData: ToastDataState = {
    id: toastId,
    icon: IcTxSuccess,
    expires: 30000,
    hasCloseButton: true,
    title: 'Transaction complete!',
    iconClassName: classes.success
  };

  const pendingToastData: ToastDataState = {
    id: toastId,
    expires: false,
    icon: IcTxPending,
    hasCloseButton: false,
    title: 'Transaction processing...',
    iconClassName: classes.warning
  };

  const failToastData: ToastDataState = {
    id: toastId,
    icon: IcTxError,
    title: 'Transaction failed!',
    hasCloseButton: true,
    iconClassName: classes.danger
  };

  const invalidToastData: ToastDataState = {
    id: toastId,
    icon: IcTxError,
    title: 'Transaction invalid',
    hasCloseButton: true,
    iconClassName: classes?.warning
  };

  const timedOutToastData = {
    id: toastId,
    icon: IcTxError,
    title: 'Transaction timed out!',
    hasCloseButton: true,
    iconClassName: classes.warning
  };

  switch (status) {
    case TransactionBatchStatusesEnum.signed:
    case TransactionBatchStatusesEnum.sent:
      return pendingToastData;
    case TransactionBatchStatusesEnum.success:
      return successToastData;
    case TransactionBatchStatusesEnum.cancelled:
    case TransactionBatchStatusesEnum.fail:
      return failToastData;
    case TransactionBatchStatusesEnum.timedOut:
      return timedOutToastData;
    case TransactionBatchStatusesEnum.invalid:
      return invalidToastData;
    default:
      return pendingToastData;
  }
};
