import goldTrophy from 'assets/img/karma-trophy-gold.png';
import silverTrophy from 'assets/img/karma-trophy-silver.png';
import bronzeTrophy from 'assets/img/karma-trophy-bronze.png';
import karmaSymbol from 'assets/img/karma-symbol.png';
import { truncateText } from './../../../helpers/truncateText';
import { useGetUsersLeaderboard } from '../hooks';
import { LeaderboardUserDTO } from 'types/karma.types';
import { LoadingState } from 'components';

export const KarmaLeaderboard = () => {
  const { data: leaderboardUsers, isLoading, error } = useGetUsersLeaderboard();
  return (
    <div className='flex flex-col w-fit justify-start items-center gap-9'>
      <p className='karma-leaderboard-label'>Leaderboard</p>
      {/* podium */}
      <div className='flex flex-row justify-center items-end md:items-center gap-7 md:gap-3'>
        {/* 2nd */}
        <div className='flex flex-col justify-center items-center rotate-[-4deg]'>
          <div className='relative mb-2 before:w-[100%] before:h-[130%] before:content-[""] before:block before:absolute before:bg-gradient-radial before:top-[-10%] before:from-[#A1B8C9] before:to-70%'>
            <img src={silverTrophy} className='relative w-24 z-10' />
          </div>
          <div className='flex flex-col bg-[#A1B8C9] border border-b-2 border-[#455663] p-2 justify-center items-center '>
            <p className='karma-leaderboard-second-place mb-1'>2nd</p>
            <p className='bg-[#455663] p-1 text-xs leading-4 text-white'>{leaderboardUsers?.[1] ? truncateText(leaderboardUsers[1].address, 6, 3) : ''}</p>
          </div>
          <div className='flex flex-row justify-center items-center mt-2'>
            <img src={karmaSymbol} className='w-4 h-4 mr-1' />
            <p className='text-purple-600 text-sm leading-5'>{leaderboardUsers?.[1] ? leaderboardUsers[1].karma : 0}</p>
          </div>
        </div>
        {/* 1st */}
        <div className='flex flex-col justify-center items-center mb-1 md:mb-14'>
          <div className='relative mb-2 before:w-[100%] before:h-[130%] before:content-[""] before:block before:absolute before:bg-gradient-radial before:top-[-10%] before:from-[#E1B435] before:to-70%'>
            <img src={goldTrophy} className='relative w-32 z-10' />
          </div>
          <div className='flex flex-col bg-[#E1B435] border border-b-2 border-[#824C0B] p-2 justify-center items-center '>
            <p className='karma-leaderboard-first-place mb-1'>1st</p>
            <p className='bg-[#824C0B] p-2 text-sm leading-4 text-white'>{leaderboardUsers?.[0] ? truncateText(leaderboardUsers[0].address, 6, 3) : ''}</p>
          </div>
          <div className='flex flex-row justify-center items-center mt-2'>
            <img src={karmaSymbol} className='w-4 h-4 mr-1' />
            <p className='text-purple-600 text-sm leading-5'>{leaderboardUsers?.[0] ? leaderboardUsers[0].karma : 0}</p>
          </div>
        </div>
        {/* 3rd */}
        <div className='flex flex-col justify-center items-center rotate-[4deg]'>
          <div className='relative mb-2 before:w-[100%] before:h-[130%] before:content-[""] before:block before:absolute before:bg-gradient-radial before:top-[-10%] before:from-[#EB7939] before:to-70%'>
            <img src={bronzeTrophy} className='relative w-24  z-10' />
          </div>
          <div className='flex flex-col bg-[#EB7939] border border-b-2 border-[#874021] p-2 justify-center items-center '>
            <p className='karma-leaderboard-third-place mb-1'>3rd</p>
            <p className='bg-[#874021] p-1 text-xs leading-4 text-white'>{leaderboardUsers?.[2] ? truncateText(leaderboardUsers[2].address, 6, 3) : ''}</p>
          </div>
          <div className='flex flex-row justify-center items-center mt-2'>
            <img src={karmaSymbol} className='w-4 h-4 mr-1' />
            <p className='text-purple-600 text-sm leading-5'>{leaderboardUsers?.[2] ? leaderboardUsers[2].karma : 0}</p>
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingState title='Loading' className='mx-auto' />
      ) : (
        (leaderboardUsers?.length ?? 0) > 3 && (
          <div className='flex flex-col divide-y-[1px] divide-[#E9ECEF] border-[2px] border-[#CED4DA] px-6 py-3 w-full'>
            {leaderboardUsers?.slice(3).map((user: LeaderboardUserDTO, index) => (
              <div className='flex flex-row items-center justify-between text-sm py-3' key={user.address}>
                <div className='flex flex-row gap-4'>
                  <p className='text-purple-600 w-4 text-right'>#{index + 4}</p>
                  <p>{truncateText(user.address, 6, 3)}</p>
                </div>

                <div className='flex flex-row gap-1 items-center'>
                  <img src={karmaSymbol} className='h-4 w-4' />
                  <p className='text-purple-600'>{user.karma}</p>
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};
