import { useGetCreateLaunchpad } from './hooks';
import { useParams } from 'react-router-dom';
import { ErrorState, LoadingState } from 'components';
import { CreateLaunchpadSteps } from './components';
import { LaunchpadState, LaunchpadStepStatus } from 'types';
import { useEffect, useState } from 'react';
import { CreateTokenStep, PublishStep, SaleInfoStep, TokenBrandingStep, WelcomeStep } from './steps';

export const CreateLaunchpad = () => {
  const { id } = useParams();
  const { data: launchpad, error, isLoading, refetch } = useGetCreateLaunchpad(id);

  const [activeStep, setActiveStep] = useState(LaunchpadState.WAITING_FOR_TUTORIAL);
  const [lastCompletedStep, setLastCompletedStep] = useState(LaunchpadState.WAITING_FOR_TUTORIAL);
  const handleSetActiveStep = (state: LaunchpadState) => {
    // TODO: Implement setActiveStep boundaries
    setActiveStep(state);
  };

  useEffect(() => {
    if (launchpad) {
      if (launchpad.currentStepData?.status === LaunchpadStepStatus.PENDING_VERIFICATION) {
        setActiveStep(LaunchpadState.WAITING_FOR_TOKEN_BRANDING);
      } else {
        setActiveStep(launchpad.currentStep);
      }
      setLastCompletedStep(launchpad.currentStep);
    }
  }, [launchpad]);

  if (isLoading) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <LoadingState />
      </div>
    );
  }

  if (error || !id) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <ErrorState title='Launchpad couldn’t be loaded' />
      </div>
    );
  }

  const renderCurrentStep = () => {
    switch (activeStep) {
      case LaunchpadState.WAITING_FOR_TUTORIAL:
        return (
          <WelcomeStep onContinue={() => {
            setActiveStep(LaunchpadState.WAITING_FOR_CREATE);
            if (lastCompletedStep === LaunchpadState.WAITING_FOR_TUTORIAL) {
              setLastCompletedStep(LaunchpadState.WAITING_FOR_CREATE);
            }
          }}
          />
        );
      case LaunchpadState.WAITING_FOR_CREATE:
        return (
          <CreateTokenStep
            launchpad={launchpad}
            onContinue={() => {
              setActiveStep(LaunchpadState.WAITING_FOR_TOKEN_BRANDING);
              refetch?.();
            }}
          />
        );
      case LaunchpadState.WAITING_FOR_TOKEN_BRANDING:
        return (
          <TokenBrandingStep
            launchpad={launchpad!}
            onContinue={() => {
              if (launchpad?.tokenBranding) {
                setActiveStep(LaunchpadState.WAITING_FOR_SALE_INFO);
              }
              refetch?.();
            }}
          />
        );
      case LaunchpadState.WAITING_FOR_SALE_INFO:
        return (
          <SaleInfoStep
            launchpad={launchpad!}
            onContinue={() => {
              setActiveStep(LaunchpadState.WAITING_FOR_PUBLISH);
              refetch?.();
            }}
          />
        );
      case LaunchpadState.WAITING_FOR_PUBLISH:
        return (
          <PublishStep
            launchpad={launchpad!}
          />
        );
    }
  };

  return (
    <div className='container mx-auto grid grid-cols-4 gap-6 xl:flex xl:flex-col'>
      <CreateLaunchpadSteps
        currentStep={activeStep}
        lastCompletedStep={lastCompletedStep}
        setStep={handleSetActiveStep}
      />
      <div className='col-span-3'>
        {renderCurrentStep()}
      </div>
    </div>
  );
};
