import { LoadingState } from 'components';
import { useGetLaunchpadQuests } from '../hooks';
import { KarmaQuestsPanel } from './KarmaQuestsPanel';

interface LaunchpadQuestsProps {
  launchpadId: string;
  swapsEnabled: boolean;
}

export const LaunchpadQuests = ({ launchpadId, swapsEnabled }: LaunchpadQuestsProps) => {
  const { data: launchpadQuests, isLoading: launchpadQuestsLoading, error, refetch: launchpadQuestsRefetch } = useGetLaunchpadQuests(launchpadId);

  if (launchpadQuestsLoading) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <LoadingState />
      </div>
    );
  }

  if (error || launchpadQuests?.length === 0) {
    return null;
  }

  return <KarmaQuestsPanel inModal={false} quests={launchpadQuests} refetch={launchpadQuestsRefetch} swapsEnabled={swapsEnabled} />;
};
