import * as React from "react"

import { cn } from "utils/cn"
import { Info } from "./Info";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  info?: string;
  errors?: string[];
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, info, errors, ...props }, ref) => {
    const isInvalidInput = errors && errors.length > 0;

    return (
      <div className="flex flex-col gap-2">
        {label && (
          <label className="text-gray-600 flex flex-row  gap-2">
            {label}
            {info && <Info>{info}</Info>}
          </label>
        )}
        <input
          type={type}
          className={cn(
            `flex h-10 w-full border border-input bg-white px-5 py-2 ring-offset-background file:border-0 file:bg-transparent file:underline file:text-primary-100 placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none ${isInvalidInput ? 'border-status-danger' : ''}`,
            className
          )}
          ref={ref}
          {...props}
        />
        {isInvalidInput && <>
          {errors.map(err => (
            <p className="text-xs text-status-danger" key={err}>{err}</p>
          ))}
        </>}
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
