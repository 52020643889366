import React, { ReactNode, useState } from 'react';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import ConnectModal from './ConnectModal';

interface ActionOrConnectProps {
  defaultComponent: ReactNode;
  connectedComponent: ReactNode;
}

const ActionOrConnect = ({ defaultComponent, connectedComponent }: ActionOrConnectProps) => {
  const { address } = useGetAccount();
  const isLoggedIn = Boolean(address);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event: React.MouseEvent<HTMLElement>) => {
    setShowModal(true);
    event.stopPropagation();
  };
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {isLoggedIn ? (
        <>{connectedComponent}</>
      ) : (
        <a onClick={(event) => handleShowModal(event)}>
          {defaultComponent}
        </a>
      )}

      <ConnectModal show={showModal} onHide={handleCloseModal} />
    </>
  );
};

export default ActionOrConnect;
