import React from 'react';
import { useMemo } from 'react';

const range = (start: number, end: number): number[] => {
 let length = end - start + 1;
 return Array.from({ length }, (_, idx) => idx + start);
};

type UsePaginationProps = {
 totalCount: number;
 pageSize: number;
 siblingCount?: number;
 currentPage: number;
};

export const usePagination = ({
 totalCount,
 pageSize,
 siblingCount = 1,
 currentPage,
}: UsePaginationProps): (number)[] => {
 const paginationRange = useMemo(() => {
  const totalPageCount = Math.ceil(totalCount / pageSize);

  const totalPageNumbers = siblingCount + 5;

  if (totalPageNumbers >= totalPageCount) {
   return range(1, totalPageCount);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(
   currentPage + siblingCount,
   totalPageCount
  );

  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
   let leftItemCount = 3 + 2 * siblingCount;
   let leftRange = range(1, leftItemCount);

   return [...leftRange, -1, totalPageCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
   let rightItemCount = 3 + 2 * siblingCount;
   let rightRange = range(
    totalPageCount - rightItemCount + 1,
    totalPageCount
   );
   return [firstPageIndex, -1, ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
   let middleRange = range(leftSiblingIndex, rightSiblingIndex);
   return [firstPageIndex, -1, ...middleRange, -1, lastPageIndex];
  }

  return [];
 }, [totalCount, pageSize, siblingCount, currentPage]);

 return paginationRange;
};
