import { Transaction } from '@multiversx/sdk-core/out';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { sendAndSignTransactions } from 'helpers';
import { useEffect, useState } from 'react';
export interface DisplayInfo {
  processingMessage: string;
  errorMessage: string;
  successMessage: string;
}

export const useSendAndSignTransaction = (customDisplayInfo?: DisplayInfo) => {
  const [loadingTransaction, setLoadingTransaction] = useState<boolean>(false);
  const [successTransaction, setSuccessTransaction] = useState<boolean>(false);
  const [failedTransaction, setFailedTransaction] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>('');

  const trackTransactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: () => {
      console.log('success');
    }
  });

  const displayInfo = {
    processingMessage: 'Processing transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Transaction successful'
  };

  const displayInfoToUse = customDisplayInfo ? customDisplayInfo : displayInfo;

  const sendAndSignTransaction = async (transaction: Transaction) => {
    const { sessionId: returnedSessionId } = await sendAndSignTransactions([transaction], displayInfoToUse);
    setSessionId(returnedSessionId);
  };

  useEffect(() => {
    setLoadingTransaction(trackTransactionStatus.isPending ?? false);
    setSuccessTransaction(trackTransactionStatus.isSuccessful ?? false);
    setFailedTransaction(trackTransactionStatus.isFailed ?? false);
  }, [trackTransactionStatus.isPending, trackTransactionStatus.isSuccessful, trackTransactionStatus.isFailed]);

  return {
    sendAndSignTransaction,
    loadingTransaction,
    successTransaction,
    failedTransaction
  };
};

export default useSendAndSignTransaction;
