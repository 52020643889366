import { TransactionToastDefaultProps, TransactionToastCustomizationProps } from '@multiversx/sdk-dapp/UI/TransactionsToastList/components/TransactionToast/transactionToast.type';
import { WithClassnameType } from '@multiversx/sdk-dapp/UI/types';
import React, { ReactNode } from 'react';
import { TransactionToastWrapperComponent } from './TransactionToastWrapper';
import { Progress } from './Progress';
import { TransactionToastContent } from './TransactionToastContent';
import { useTransactionToast } from './useTransactionToast';

export interface TransactionToastPropsType extends TransactionToastDefaultProps, WithClassnameType {
  title?: string | ReactNode;
  children?: ReactNode;
  customization?: TransactionToastCustomizationProps;
}

export const TransactionToastComponent = ({
  toastId,
  title = '',
  className = 'dapp-transaction-toast',
  onDelete,
  startTimestamp,
  endTimeProgress,
  lifetimeAfterSuccess,
  status,
  transactions
}: TransactionToastPropsType) => {
  const { isCrossShard, progress, isPending, isTimedOut, toastDataState, handleDeleteToast } = useTransactionToast({
    toastId,
    transactions,
    status,
    lifetimeAfterSuccess,
    startTimestamp,
    endTimeProgress,
    onDelete
  });

  const ProgressComponent = Progress;
  const TransactionToastContentComponent = TransactionToastContent;

  return (
    <TransactionToastWrapperComponent className={`${className} fixed bottom-4 right-4 bg-gray-100 border border-gray-400 shadow p-4 z-50 min-w-[355px]`} id={`toast-${toastId}`}>
      <ProgressComponent key={toastId} id={toastId} progress={progress} expiresIn={lifetimeAfterSuccess} done={!isPending || isTimedOut} isCrossShard={isCrossShard}>
        <TransactionToastContentComponent
          style={{}}
          toastDataState={toastDataState}
          transactions={transactions ?? []}
          toastTitle={title}
          isTimedOut={isTimedOut}
          showCloseButton={!isPending}
          onDeleteToast={handleDeleteToast}
        />
      </ProgressComponent>
    </TransactionToastWrapperComponent>
  );
};
