import React from 'react';

import { useGetModalLoginMethods } from '@multiversx/sdk-dapp/hooks';

import { WalletConnectLoginButtonPropsType } from '@multiversx/sdk-dapp/UI/walletConnect/WalletConnectLoginButton/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { WalletConnectLoginContainer } from './WalletConnectLoginContainer';

export const WalletConnectLoginButton = ({
  callbackRoute,
  customSpinnerComponent,
  disabled,
  hideButtonWhenModalOpens = false,
  innerWalletConnectComponentsClasses,
  lead = 'Scan the QR code using the xPortal App',
  loginButtonText = 'xPortal App',
  logoutRoute,
  modalClassName,
  onLoginRedirect,
  onContentHide,
  onContentShow,
  onModalCloses,
  onModalOpens,
  showScamPhishingAlert,
  title = 'Login with the xPortal App',
  token,
  wrapContentInsideModal = true,
}: WalletConnectLoginButtonPropsType) => {
  const {
    disabledConnectButton,
    handleCloseModal,
    handleOpenModal,
    shouldRenderButton,
    showContent,
    showModal
  } = useGetModalLoginMethods({
    hideButtonWhenModalOpens,
    onContentHide,
    onContentShow,
    onModalCloses,
    onModalOpens,
    token,
    wrapContentInsideModal
  });

  return (
    <>
      {shouldRenderButton && (
        <button
          disabled={disabled || disabledConnectButton}
          onClick={handleOpenModal}
          className='flex pb-2 pr-2 justify-between items-center'
        >
          <div className='flex gap-3 items-center'>
            <div className='w-7 h-7 bg-black flex items-center justify-center'>
              <object data="/img/connect/xportal.svg" type="image/svg+xml" className='h-4 w-auto' />
            </div>
            <span className="text-md text-gray-900">xPortal App</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} className='text-[#888888] text-md' />
        </button>
      )}

      <WalletConnectLoginContainer
        callbackRoute={callbackRoute}
        className={modalClassName}
        customSpinnerComponent={customSpinnerComponent}
        innerWalletConnectComponentsClasses={
          innerWalletConnectComponentsClasses
        }
        isWalletConnectV2={true}
        lead={lead}
        loginButtonText={loginButtonText}
        logoutRoute={logoutRoute}
        nativeAuth={true}
        onClose={handleCloseModal}
        onLoginRedirect={onLoginRedirect}
        showLoginContent={showContent}
        showLoginModal={showModal}
        showScamPhishingAlert={showScamPhishingAlert}
        title={title}
        token={token}
        wrapContentInsideModal={wrapContentInsideModal}
      />
    </>
  );
};