import { faCheck, faCoins, faFlag, faRocket, faTag, faWandSparkles, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LaunchpadState } from "types";

interface CreateLaunchpadStepsProps {
  currentStep: LaunchpadState;
  lastCompletedStep: LaunchpadState;
  setStep: (step: LaunchpadState) => void;
}

export const CreateLaunchpadSteps = ({ currentStep, lastCompletedStep, setStep }: CreateLaunchpadStepsProps) => {
  const stepOrder = {
    [LaunchpadState.WAITING_FOR_TUTORIAL]: 1,
    [LaunchpadState.WAITING_FOR_CREATE]: 2,
    [LaunchpadState.WAITING_FOR_TOKEN_BRANDING]: 3,
    [LaunchpadState.WAITING_FOR_SALE_INFO]: 4,
    [LaunchpadState.WAITING_FOR_PUBLISH]: 5,
    [LaunchpadState.PUBLISHED]: 5
  };

  const stepMedia = {
    [LaunchpadState.WAITING_FOR_TUTORIAL]: {
      title: 'Welcome',
      icon: faFlag,
      description: 'Easily launch your meme token - fair, fun, and hassle-free! Let the memes begin!'
    },
    [LaunchpadState.WAITING_FOR_CREATE]: {
      title: 'Token Details',
      icon: faCoins,
      description: `Craft your meme token's essence. Unleash creativity with a unique name, ticker, and supply!`
    },
    [LaunchpadState.WAITING_FOR_TOKEN_BRANDING]: {
      title: 'Token Branding',
      icon: faWandSparkles,
      description: `Define your meme token's vibe. Description, social media links - build the hype!`
    },
    [LaunchpadState.WAITING_FOR_SALE_INFO]: {
      title: 'Sale Details',
      icon: faTag,
      description: `Craft a foolproof sale. Set prices, limits and set up the token distribution!`
    },
    [LaunchpadState.WAITING_FOR_PUBLISH]: {
      title: 'Launch Token',
      icon: faRocket,
      description: ``
    },
    [LaunchpadState.PUBLISHED]: {
      title: 'Published',
      icon: faRocket,
      description: ``
    },
  };

  const renderStep = (step: LaunchpadState) => {
    const { icon, title, description } = stepMedia[step];

    if (stepOrder[currentStep] === stepOrder[step]) {
      return (
        <div className='flex gap-4'>
          <div className='text-center'>
            <div className='flex text-white bg-primary-100 w-8 h-8 flex-shrink-0 items-center justify-center'>
              <FontAwesomeIcon icon={icon} />
            </div>
            {stepOrder[step] < 5 && (<span className='inline-block h-[calc(100%+30px)] w-[1px] bg-primary-100'></span>)}
          </div>
          <div className='flex flex-col gap-2'>
            <h4 className='text-lg text-gray-900 leading-8'>{title}</h4>
            <p className='text-gray-600 leading-4'>{description}</p>
          </div>
        </div>
      );
    }

    if (stepOrder[lastCompletedStep] === stepOrder[step]) {
      return (
        <div className='flex gap-4'>
          <div className='text-center cursor-pointer' onClick={() => setStep(step)}>
            <div className='flex text-white bg-primary-100 w-8 h-8 flex-shrink-0 items-center justify-center'>
              <FontAwesomeIcon icon={icon} />
            </div>
            {stepOrder[step] < 5 && (<span className='inline-block h-[calc(100%+30px)] w-[1px] bg-primary-100'></span>)}
          </div>
          <div className='flex flex-col gap-2'>
            <h4 className='text-lg text-gray-900 leading-8 cursor-pointer' onClick={() => setStep(step)}>{title}</h4>
          </div>
        </div >
      );
    }

    if (stepOrder[lastCompletedStep] > stepOrder[step]) {
      return (
        <div className='flex gap-4'>
          <div className='text-center cursor-pointer' onClick={() => setStep(step)}>
            <div className='flex text-white bg-primary-100 w-8 h-8 flex-shrink-0 items-center justify-center'>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            {stepOrder[step] < 5 && (<span className='inline-block h-[calc(100%+30px)] w-[1px] bg-primary-100'></span>)}
          </div>
          <div className='flex flex-col gap-2'>
            <h4 className='text-lg text-gray-900 leading-8 cursor-pointer' onClick={() => setStep(step)}>{title}</h4>
          </div>
        </div>
      );
    }

    return (
      <div className='flex gap-4'>
        <div className='text-center'>
          <div className='flex text-primary-100 bg-[#D9E7F2] w-8 h-8 flex-shrink-0 items-center justify-center'>
            <FontAwesomeIcon icon={icon} />
          </div>
          {stepOrder[step] < 5 && (<span className='inline-block h-[calc(100%+20px)] w-[1px] bg-[#D9E7F2]'></span>)}
        </div>
        <div className='flex flex-col gap-2'>
          <h4 className='text-lg text-gray-900 leading-8'>{title}</h4>
        </div>
      </div>
    );
  };

  const renderMobileStep = (step: LaunchpadState) => {
    const { icon } = stepMedia[step];

    if (stepOrder[currentStep] === stepOrder[step]) {
      return (
        <div className='text-center relative'>
          <div className='flex text-white bg-primary-100 w-8 h-8 flex-shrink-0 items-center justify-center'>
            <FontAwesomeIcon icon={icon} />
          </div>
          {stepOrder[step] < 5 && (<span className='inline-block bg-primary-100 h-[1px] w-[100%] absolute top-[1rem] left-8'></span>)}
        </div>
      );
    }

    if (stepOrder[lastCompletedStep] === stepOrder[step]) {
      return (
        <div className='text-center relative cursor-pointer' onClick={() => setStep(step)}>
          <div className='flex text-white bg-primary-100 w-8 h-8 flex-shrink-0 items-center justify-center'>
            <FontAwesomeIcon icon={icon} />
          </div>
          {stepOrder[step] < 5 && (<span className='inline-block bg-primary-100 h-[1px] w-[100%] absolute top-[1rem] left-8'></span>)}
        </div>
      );
    }

    if (stepOrder[lastCompletedStep] > stepOrder[step]) {
      return (
        <div className='text-center relative cursor-pointer' onClick={() => setStep(step)}>
          <div className='flex text-white bg-primary-100 w-8 h-8 flex-shrink-0 items-center justify-center'>
            <FontAwesomeIcon icon={faCheck} />
          </div>
          {stepOrder[step] < 5 && (<span className='inline-block bg-primary-100 h-[1px] w-[100%] absolute top-[1rem] left-8'></span>)}
        </div>
      );
    }

    return (
      <div className='text-center relative'>
        <div className='flex text-primary-100 bg-[#D9E7F2] w-8 h-8 flex-shrink-0 items-center justify-center'>
          <FontAwesomeIcon icon={icon} />
        </div>
        {stepOrder[step] < 5 && (<span className='inline-block bg-[#D9E7F2] h-[1px] w-[100%] absolute top-[1rem] left-8'></span>)}
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col py-12 pr-6 gap-8 xl:hidden'>
        {renderStep(LaunchpadState.WAITING_FOR_TUTORIAL)}
        {renderStep(LaunchpadState.WAITING_FOR_CREATE)}
        {renderStep(LaunchpadState.WAITING_FOR_TOKEN_BRANDING)}
        {renderStep(LaunchpadState.WAITING_FOR_SALE_INFO)}
        {renderStep(LaunchpadState.WAITING_FOR_PUBLISH)}
      </div>
      <div className="hidden container pt-12 max-w-4xl xl:flex flex-col gap-6 justify-center items-center">
        <div className="flex flex-row justify-center gap-8">
          {renderMobileStep(LaunchpadState.WAITING_FOR_TUTORIAL)}
          {renderMobileStep(LaunchpadState.WAITING_FOR_CREATE)}
          {renderMobileStep(LaunchpadState.WAITING_FOR_TOKEN_BRANDING)}
          {renderMobileStep(LaunchpadState.WAITING_FOR_SALE_INFO)}
          {renderMobileStep(LaunchpadState.WAITING_FOR_PUBLISH)}
        </div>
        <div className='flex flex-col gap-2 text-center max-w-sm'>
          <h4 className='text-xl text-gray-900 leading-8'>{stepMedia[currentStep].title}</h4>
          <p className='text-gray-600 leading-4'>{stepMedia[currentStep].description}</p>
        </div>
      </div>
    </>
  );
};
