import { faPowerOff, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccount, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import IcKarma from 'assets/img/ic_karma_symbol.svg?react';
import { ActionOrConnect, Button } from 'components';
import { logout } from 'helpers';
import { RouteNamesEnum } from 'localConstants';
import { useMemo } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { UserStatusDTO } from 'types/karma.types';

interface HeaderProps {
  onlyKarma?: boolean;
  userStatusData: UserStatusDTO | undefined;
  isLoading: boolean | undefined
}

export const Header = ({ onlyKarma, userStatusData, isLoading }: HeaderProps) => {
  const isLoggedIn = useGetIsLoggedIn();
  const { address, username: herotag } = useGetAccount();
  const location = useLocation();
  const activePath = `${location.pathname}${location.hash}`;

  const username = useMemo(() => {
    if (!herotag) return address.slice(0, 6) + '...' + address.slice(-6);
    return herotag.replace('.elrond', '');
  }, [address, herotag]);

  const handleLogout = () => {
    sessionStorage.clear();
    logout(window.location.origin, undefined, false);
  };
  const renderNav = (className?: string) => {
    return (
      <>
        <NavLink to='/#home'>
          <Button className={className} variant={activePath === RouteNamesEnum.home || activePath.includes('#home') ? 'flat' : 'ghost'}>
            Home
          </Button>
        </NavLink>
        {!onlyKarma && (
          <>
            <ActionOrConnect
              defaultComponent={<Button variant={activePath === RouteNamesEnum.createdLaunchpads ? 'flat' : 'ghost'}>Create</Button>}
              connectedComponent={
                <NavLink to={RouteNamesEnum.createdLaunchpads}>
                  <Button className={className} variant={activePath === RouteNamesEnum.createdLaunchpads ? 'flat' : 'ghost'}>
                    Create
                  </Button>
                </NavLink>
              }
            />
            <NavLink to={RouteNamesEnum.explore}>
              <Button className={className} variant={activePath === RouteNamesEnum.explore ? 'flat' : 'ghost'}>
                Explore
              </Button>
            </NavLink>
            <NavLink to={RouteNamesEnum.faq}>
              <Button className={className} variant={activePath === RouteNamesEnum.faq ? 'flat' : 'ghost'}>
                FAQ
              </Button>
            </NavLink>
          </>
        )}

        <NavLink to={RouteNamesEnum.karma}>
          <Button className={className} variant={activePath === RouteNamesEnum.karma || activePath.includes('#karma') ? 'flat' : 'ghost'}>
            Karma
          </Button>
        </NavLink>
      </>
    );
  };

  return (
    <>
      <header className='bg-gray-100 border-b fixed top-0 w-full z-50'>
        <div className='container mx-auto flex lg:grid lg:grid-flow-col items-center py-6 lg:grid-cols-2 sm:grid-cols-1 sm:p-4'>
          <div className='flex items-center gap-4 min-w-[300px] lg:min-w-0'>
            <Link to={RouteNamesEnum.home}>
              <img src="/img/logo.svg" alt="" className='h-8 w-auto' />
            </Link>
          </div>

          <div className='flex gap-3 mx-auto lg:hidden'>{renderNav()}</div>

          <div className='flex justify-end min-w-[300px] lg:min-w-0'>
            {isLoggedIn && (
              <div className='flex border border-gray-300 items-center p-2 gap-2'>
                <>
                  <div className='flex items-center text-purple-400'>
                    <IcKarma className='mr-1' />
                    {isLoading || !userStatusData ? <FontAwesomeIcon icon={faSpinner} className='animate-spin duration-1000' /> : userStatusData?.karma}
                  </div>
                  <span className='h-3 w-[2px] bg-gray-300 xs:hidden'></span>
                  <p className=''>{username}</p>
                </>
              </div>
            )}
            <ActionOrConnect
              defaultComponent={<Button variant='outline'>Connect</Button>}
              connectedComponent={
                <Button variant='outline' size='icon' onClick={handleLogout}>
                  <FontAwesomeIcon icon={faPowerOff} />
                </Button>
              }
            />
          </div>
        </div>
      </header>
      <div className='hidden lg:flex fixed bottom-0 container mx-auto bg-gray-100 border justify-center py-2 px-5 sm:px-2 z-50'>
        <div className='flex justify-between sm:gap-1 gap-3 sm:w-full overflow-x-auto scrollbar-hide'>{renderNav('sm:px-1')}</div>
      </div>
    </>
  );
};
