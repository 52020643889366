import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InfoProps {
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
  toLeft?: boolean;
}

const Info = ({ children, className, containerClassName, toLeft }: InfoProps) => {
  return (
    <span className='group relative w-min'>
      <FontAwesomeIcon icon={faInfoCircle} className={`cursor-pointer hover:text-gray-900 ${className}`} />

      <div className={`${toLeft ? 'z-50 absolute top-[10px] left-[24px] scale-0 group-hover:scale-100 flex' : 'absolute right-1.5 top-4 scale-0 group-hover:scale-100 flex flex-col items-end z-10'}`}>
        <div className={`${toLeft ? 'w-0 h-0 border-r-[16px] border-r-gray-900 border-b-[18px] border-b-transparent' : 'block w-0 h-0 border-b-[16px] border-b-gray-900 border-l-[18px] border-l-transparent'}`}></div>
        <div className={`p-2 ${toLeft ? 'min-w-[170px]' : 'min-w-[300px]'} shadow-sm bg-gray-900 text-white ${containerClassName}`}>{children}</div>
      </div>
    </span>
  );
};

export { Info };
