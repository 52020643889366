import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { Button, LoadingState } from 'components';
import { retrieveAndDeleteFromLocalStorage } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useGetTwitterOauth } from './hooks';
import { useEffect, useState } from 'react';
import { RouteNamesEnum } from 'localConstants';

export const TwitterConfirmation = () => {
  const url = new URL(location.href);
  const code = url.searchParams.get('code');
  const state = url.searchParams.get('state');

  const { address } = useGetAccount();
  const [lastUsersRoute, setLastUsersRoute] = useState<string | undefined>(undefined)
  const navigate = useNavigate();

  const lastRoute = retrieveAndDeleteFromLocalStorage(`${address}-twitter-validation`);

  useEffect(() => {
    if (lastRoute) {
      setLastUsersRoute(lastRoute);
    }
  }, [lastRoute])

  const onSuccess = () => {
    if (lastUsersRoute) {
      // navigate(lastUsersRoute);
      navigate(RouteNamesEnum.home);
    }
  };

  const { isLoading: isTwitterOauthLoading, error: twitterOauthError } = useGetTwitterOauth(onSuccess, state, code);

  if (isTwitterOauthLoading) {
    return (
      <div className='container mx-auto flex justify-center py-20 bg-white'>
        <LoadingState title='Loading' />
      </div>
    );
  }

  if (twitterOauthError?.response?.status === 409) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
          <div className='text-md text-orange-500 bg-orange-300 w-12 h-12 shadow-sm shadow-orange-500 flex items-center justify-center'>
            <FontAwesomeIcon icon={faFrown} />
          </div>
          <div className='text-center'>
            <p className='text-gray-600'>{twitterOauthError?.response?.data?.message}</p>
          </div>
          {lastUsersRoute && (
            <Button
              variant='default'
              className='min-w-[140px]'
              onClick={() => {
                if (lastUsersRoute) {
                  // navigate(lastUsersRoute);
                  navigate(RouteNamesEnum.home);
                }
              }}
            >
              Go back
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (twitterOauthError) {
    return (
      <div className='container mx-auto flex justify-center py-20 bg-white'>
        <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
          <div className='text-md text-gray-800 bg-gray-400 w-12 h-12 shadow-sm shadow-gray-800 flex items-center justify-center'>
            <FontAwesomeIcon icon={faFrown} />
          </div>
          <div className='text-center'>
            <p className='text-gray-900 text-lg'>Error</p>
            <p className='text-gray-600'>Something went wrong, please try again later.</p>
          </div>
          {lastRoute && (
            <Button
              variant='default'
              className='min-w-[140px]'
              onClick={() => {
                if (lastRoute) {
                  // navigate(lastRoute);
                  navigate(RouteNamesEnum.home);
                }
              }}
            >
              Go back
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='container mx-auto'>
      <div className='bg-dark-200 rounded-xl p-6 text-dark-600 text-center'>
        <div className='flex justify-center my-10'>X confirmation.</div>
      </div>
    </div>
  );
};
