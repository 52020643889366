import { useGetAccount, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { retrieveFromLocalStorage } from 'helpers';
import { useQuery } from 'react-query';
import { UserStatusDTO } from 'types/karma.types';

const fetchUserStatus = async (nativeAuthToken?: string) => {
  try {
    const referralCode = retrieveFromLocalStorage('referralCode');

    const { data } = await axios.get(`/v1/user/status`, { baseURL: API_URL, headers: { Authorization: `Bearer ${nativeAuthToken}`, 'x-referral-code': `${referralCode}` } });
    return data as UserStatusDTO;
  } catch (error) {
    throw error;
  }
};

export const useGetUserStatus = (): {
  data?: UserStatusDTO;
  isLoading?: boolean;
  error?: any;
  refetch?: () => Promise<any>;
} => {
  const { address } = useGetAccount();
  const loginInfo = useGetLoginInfo();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['user-status', address, loginInfo.tokenLogin?.nativeAuthToken],
    queryFn: () => fetchUserStatus(loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    enabled: loginInfo.tokenLogin?.nativeAuthToken !== undefined
  });


  return { data, isLoading, error, refetch };
};
