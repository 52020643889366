import BigNumber from "bignumber.js";

export const computeListingPrice = (launchpadAmountInEgld: BigNumber, launchpadPercentage: number, tokenSupply: string): string => {
  const amountIn = launchpadAmountInEgld.multipliedBy(0.01);
  const reserveIn = launchpadAmountInEgld.multipliedBy(0.99);
  const reserveOut = new BigNumber(100 - launchpadPercentage).dividedBy(100).multipliedBy(tokenSupply);

  console.log("VALUES AFTER INITIAL LIQUIDITY", amountIn.shiftedBy(-18).toFixed(), reserveIn.shiftedBy(-18).toFixed(), reserveOut.shiftedBy(-18).toFixed())

  const getAmountOut = (amountIn: BigNumber, reserveIn: BigNumber, reserveOut: BigNumber) => {
    const amountInWithFee = amountIn.multipliedBy(0.997);
    const numerator = amountInWithFee.multipliedBy(reserveOut);
    const denominator = reserveIn.plus(amountInWithFee);

    const amountOut = numerator.dividedBy(denominator);
    return amountOut;
  }

  const swappedAmount = getAmountOut(amountIn, reserveIn, reserveOut);
  const tokenReserve = reserveOut.plus(swappedAmount);
  const egldReserve = reserveIn.plus(amountIn.multipliedBy(0.997));

  console.log("VALUES AFTER SWAP", swappedAmount.shiftedBy(-18).toFixed(), tokenReserve.shiftedBy(-18).toFixed(), egldReserve.shiftedBy(-18).toFixed())

  const listingTokenPriceRaw = getAmountOut(new BigNumber(1).shiftedBy(18), egldReserve, tokenReserve).shiftedBy(-18);
  const listingTokenPrice = listingTokenPriceRaw.isLessThan(100) ? listingTokenPriceRaw.toFixed(6) : listingTokenPriceRaw.integerValue().toFixed();

  return listingTokenPrice;
}
