import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';
import { API_URL } from 'config';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';

const deleteDraftLaunchpad = async (id: string, nativeAuthToken?: string): Promise<void> => {
  try {
    await axios.delete(`/v1/launchpads/create/${id}`, {
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${nativeAuthToken}`
      }
    });
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const useDeleteDraftLaunchpad = (options: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();
  const loginInfo = useGetLoginInfo();

  const mutation = useMutation<void, any, string>(
    (id) => deleteDraftLaunchpad(id, loginInfo.tokenLogin?.nativeAuthToken),
    {
      onSuccess: () => {
        options.onSuccess();
        queryClient.invalidateQueries();
      },
    }
  );

  const deleteLaunchpad = (id: string) => {
    mutation.mutate(id);
  };

  return { deleteLaunchpad, ...mutation };
};