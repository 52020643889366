import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useMutation } from 'react-query';
import { UploadTokenBrandingDto } from 'types';

const acceptTermsAndConditions = async (nativeAuthToken?: string): Promise<UploadTokenBrandingDto> => {

 try {
  const { data } = await axios.put(`/v1/user/terms-and-conditions`, {}, {
   baseURL: API_URL, headers: {
    Authorization: `Bearer ${nativeAuthToken}`
   }
  });
  return data;
 } catch (error) {
  console.error('Error:', error);
  throw error;
 }
};

export const useAcceptTermsAndConditions = (handleOnAcceptSuccess: (() => Promise<any>) | (() => void) | undefined) => {
 const loginInfo = useGetLoginInfo();

 const mutation = useMutation<any, any>(
  () => acceptTermsAndConditions(loginInfo.tokenLogin?.nativeAuthToken),
  {
   onSuccess: () => {
    handleOnAcceptSuccess?.();
   },
  }
 );

 const startAcceptTermsAndConditions = () => {
  mutation.mutate();
 };

 return { startAcceptTermsAndConditions, ...mutation };
};
