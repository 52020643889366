import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'utils/cn';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-gray-300 disabled:text-gray-500 disabled:border disabled:border-gray-500 uppercase',
  {
    variants: {
      variant: {
        default: 'border border-b-2 border-primary-200 bg-primary-100 text-white hover:bg-primary-200',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-b-2 border-primary-200 bg-transparent text-primary-100 hover:bg-primary-200 hover:text-white',
        outlineBorder: 'border border-primary-200 bg-transparent text-primary-100 hover:bg-primary-200 hover:text-white',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'text-gray-600 hover:bg-primary-100 hover:text-white',
        flat: 'bg-primary-100 text-white',
        link: 'text-primary underline-offset-4 hover:underline'
      },
      size: {
        default: 'h-12 px-4 py-2',
        sm: 'h-10 px-3',
        lg: 'h-11 px-8',
        icon: 'h-12 w-12',
        'icon-sm': 'h-9 w-9'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
