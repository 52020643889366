
export const PageNotFound = () => {
  return (
    <div className='container mx-auto'>
      <div className='bg-dark-200 rounded-xl p-6 text-dark-600 text-center'>
        <div className='flex justify-center my-10'>
          Page not found
        </div>
      </div>
    </div>
  );
};
