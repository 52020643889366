import { useGetAccount, useGetIsLoggedIn, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

const fetchTwitterVerification = async (nativeAuthToken?: string) => {
  try {
    const { data } = await axios.get(`/v1/twitter/verification`, { baseURL: API_URL, headers: { Authorization: `Bearer ${nativeAuthToken}` } });
    return data;
  } catch (error) {
    throw error;
  }
};

export const useGetTwitterVerification = (): {
  data?: any;
  isLoading?: boolean;
  error?: any;
  refetch?: () => Promise<any>;
} => {

  const { address } = useGetAccount();
  const loginInfo = useGetLoginInfo();
  const isLoggedIn = useGetIsLoggedIn();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['get-twitter-url', address, loginInfo.tokenLogin?.nativeAuthToken],
    queryFn: () => fetchTwitterVerification(loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    enabled: isLoggedIn && loginInfo.tokenLogin?.nativeAuthToken !== undefined
  });

  useEffect(() => {
    if (isLoggedIn === true) {
      refetch();
    }
  }, [isLoggedIn]);


  return { data, isLoading, error, refetch };
};
