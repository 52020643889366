import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components';
import { useGetAccount } from 'hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ExtensionLoginButton } from './ExtensionLoginButton';
import { WebWalletLoginButton } from './WebWalletLoginButton';
import { WalletConnectLoginButton } from './WalletConnectLoginButton';
import { LedgerLoginButton } from './LedgerLoginButton';
import { MetamaskProxyLoginButton } from './MetamaskProxyButton';

interface ConnectModalProps {
  show: boolean;
  onHide: () => void;
}

const ConnectModal = ({ show, onHide }: ConnectModalProps) => {
  const location = useLocation();
  const { address } = useGetAccount();
  const isLoggedIn = Boolean(address);

  useEffect(() => {
    if (isLoggedIn) {
      onHide();
    }
  }, [isLoggedIn]);

  const commonProps = {
    className: '!bg-dark-300 !font-bold !text-white !border-none !rounded-xl !cursor-pointer !px-6 !py-3 !text-md !focus:outline-none !focus:shadow-none !m-0',
    nativeAuth: true,
  };

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent className=''>
        <DialogHeader>
          <DialogTitle>Connect Wallet</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col gap-2'>
          <WalletConnectLoginButton />
          <ExtensionLoginButton />
          <WebWalletLoginButton />
          <LedgerLoginButton />
          <MetamaskProxyLoginButton onClick={onHide} />
        </div>
        <DialogFooter className='flex flex-row gap-2'>
          <div className='flex-1 text-sm text-gray-600'>
            <p>Scam/Phishing verification:</p>
            <p className='text-gray-900'><span className='text-primary-100'>https://</span>{window.location.host}</p>
            <p>Please confirm that you are indeed connecting to <span className='text-gray-900'><span className='text-primary-100'>https://</span>{window.location.host}</span></p> for <span className='text-primary-100'>24 hours</span> and that you trust this site. You might be sharing sensitive data.
            <p><a href="https://multiversx.com/faq" target='_blank' className='text-primary-100 underline'>Learn more</a></p>
          </div>
          <div className='w-5 h-5'>
            <FontAwesomeIcon icon={faLock} className='text-sm text-primary-100' />
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog >
  )
};

export default ConnectModal;
