import axios from 'axios';
import { API_URL } from 'config';
import { useQuery } from 'react-query';
import { Launchpad } from 'types';

const fetchUpcomingLaunchpads = async () => {
  try {
    const { data } = await axios.get(
      `/v1/launchpads/upcoming`,
      { baseURL: API_URL }
    );
    return data as Launchpad[];
  } catch (error) {
    throw error;
  }
};

export const useGetUpcomingLaunchpads = (): { data?: Launchpad[]; isLoading?: boolean; error?: any; refetch?: () => void } => {


  const { data, isLoading, error, refetch } = useQuery({
    queryKey: `upcoming-launchpads`,
    queryFn: () => fetchUpcomingLaunchpads(),
    refetchOnWindowFocus: false,
    retry: false
  });

  return { data, isLoading, error, refetch };
};
