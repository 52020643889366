export enum LaunchpadType {
  FAIR_LAUNCH = 'fairlaunch'
}

export enum LaunchpadStepStatus {
  NO_DATA = 'no_data',
  PENDING_VERIFICATION = 'pending',
  ERROR = 'error'
}

export enum LaunchpadState {
  WAITING_FOR_TUTORIAL = 'waiting_for_tutorial',
  WAITING_FOR_CREATE = 'waiting_for_create',
  WAITING_FOR_TOKEN_BRANDING = 'waiting_for_token_branding',
  WAITING_FOR_SALE_INFO = 'waiting_for_sale_info',
  WAITING_FOR_PUBLISH = 'waiting_for_publish',
  PUBLISHED = 'published'
}

export interface EsdtToken {
  identifier: string;
  name: string;
  ticker: string;
  decimals: number;
  assets?: {
    pngUrl?: string;
    svgUrl?: string;
  };
}

export enum SaleStatus {
  SCHEDULED = 'Scheduled',
  ACTIVE = 'Active',
  SOLD_OUT = 'SoldOut',
  CLAIMING_PERIOD = 'ClaimingPeriod',
  ENDED = 'Ended',
}

export interface Socials {
  website?: string,
  telegram: string,
  twitter?: string,
  github?: string,
  medium?: string,
  facebook?: string,
  instagram?: string,
  tiktok?: string,
  reddit?: string,
  discord?: string,
  email?: string,
  blog?: string,
  whitepaper?: string
}

export interface Launchpad {
  id: string;
  slug: string;
  type: LaunchpadType;
  status: SaleStatus;
  token: EsdtToken;
  startTimestamp: number;
  endTimestamp: number;
  tokenPrice: string;
  tokenSupply: string;
  currentTokensBought: string;
  currentRaisedAmount: string;
  maxTokensToSell: string;
  maxAmountToRaise: string;
  isHot?: boolean;
  swapsEnabled?: boolean;
}

export interface LaunchpadsFetchingDTO {
  launchpads: Launchpad[];
  count: number;
}

export interface LaunchpadDetails extends Launchpad {
  maxTokensPerUser: string;
  tokensBoughtByUser: string;
  launchpadPercentage: number;
  scAddress: string;
  lpAddress: string;
  tokenOwner: string;
  description: string;
  urls: Socials;
  canEnableSwaps: boolean;
  swapsEnabled: boolean;
}

export interface TokenBrandingDTO {
  description: string;
  svgUrl: string;
  pngUrl: string;
  urls: Socials;
  pngFile?: File | null;
  svgFile?: File | null;
}

export interface ImagesUploading {
  svgBucketUrl: string;
  pngBucketUrl: string;
  svgFile?: File | null;
  pngFile?: File | null;
}

export interface SaleInfoDTO {
  tokenPrice: string;
  maxTokensPerUser: string;
  saleStartTimestamp: number;
  saleEndTimestamp: number;
  launchpadPercentage: number;
}

export interface CreateTokenDTO {
  name: string;
  ticker: string;
  decimals: number;
  supply: string;
}

export interface CreateLaunchpadDTO {
  id: string;
  slug: string;
  token: CreateTokenDTO;
  tokenBranding?: TokenBrandingDTO;
  saleInfo?: SaleInfoDTO;
  currentStep: LaunchpadState;
  currentStepData: {
    status: LaunchpadStepStatus;
    data: {};
    errors: [string];
  };
}

export interface UploadTokenBrandingDto {
  presignedPngUrl: string;
  presignedSvgUrl: string;
}
