import { useGetAccount, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useQuery } from 'react-query';

const fetchTwitterOauth = async (onSuccess: () => void, state: string | null, code: string | null, nativeAuthToken?: string) => {
  try {
    if (!state || !code) {
      return undefined;
    }
    const { data } = await axios.get(`/v1/twitter/oauth`, { params: { state: state, code: code }, baseURL: API_URL, headers: { Authorization: `Bearer ${nativeAuthToken}` } });
    onSuccess();
    return data;
  } catch (error) {
    throw error;
  }
};

export const useGetTwitterOauth = (onSuccess: () => void, state: string | null, code: string | null): {
  data?: any;
  isLoading?: boolean;
  error?: any;
  refetch?: () => Promise<any>;
} => {
  const { address } = useGetAccount();

  const loginInfo = useGetLoginInfo();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['twitter-oauth-confirmation', address, state, code],
    queryFn: () => fetchTwitterOauth(onSuccess, state, code, loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: state !== null && code !== null && loginInfo.tokenLogin?.nativeAuthToken !== undefined
  });

  return { data, isLoading, error, refetch };
};
