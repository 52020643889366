import { useEffect, useRef, useState } from 'react';
import { WalletConnectLoginModalPropsType } from '@multiversx/sdk-dapp/UI/walletConnect/WalletConnectLoginContainer/types';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, Loader, PageState } from 'components';
import { useWalletConnectV2Login } from '@multiversx/sdk-dapp/hooks';
import { isMobileEnvironment } from '@multiversx/sdk-dapp/utils';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode';
import { PairingTypes } from '@multiversx/sdk-dapp/utils/walletconnect/__sdkWalletconnectProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface PairingListPropsType {
  connectExisting: (pairing: PairingTypes.Struct) => Promise<void>;
  removeExistingPairing: (topic: string) => Promise<void>;
  activePairings: PairingTypes.Struct[];
  className: string;
}

const Pairinglist = ({
  connectExisting,
  removeExistingPairing,
  activePairings,
}: PairingListPropsType) => {
  const [topicLoading, setTopicLoading] = useState<string>('');

  useEffect(() => {
    setTopicLoading('');
  }, [activePairings]);

  return (
    <>
      <p className="text-center text-gray-600">or choose an existing pairing:</p>
      {activePairings.map((pairing) => (
        <button
          type='button'
          key={pairing.topic}
          onClick={() => {
            connectExisting(pairing);
            setTopicLoading(pairing.topic);
          }}
          className='flex pb-2 pr-2 justify-between items-center'
        >
          {pairing.peerMetadata && (
            <>
              <div className='flex gap-3 items-center'>
                {topicLoading == pairing.topic ? (
                  <Loader className='text-md text-orange-600' />
                ) : (
                  <>
                    {pairing.peerMetadata?.icons?.[0] && (
                      <img src={pairing.peerMetadata.icons[0]} alt="" className='w-7 h-7' />
                    )}
                  </>
                )}
                <span className="text-md text-gray-900">
                  {topicLoading === pairing.topic
                    ? `Confirm on ${pairing.peerMetadata.name}`
                    : pairing.peerMetadata.name}
                  {" "}
                  <span className='text-gray-600 text-sm'>{pairing.peerMetadata.url}</span>
                </span>
              </div>

              <FontAwesomeIcon
                icon={faTimes}
                className='text-[#888888] text-md'
                onClick={(event) => {
                  event.stopPropagation();
                  removeExistingPairing(pairing.topic);
                }}
              />
            </>
          )}
        </button>
      ))}
    </>
  );
};


export const WalletConnectLoginContainer = (
  props: WalletConnectLoginModalPropsType
) => {
  const {
    onClose,
    showLoginContent,
    showLoginModal,
    canLoginRef: parentCanLoginRef
  } = props;

  const canLoginRef = parentCanLoginRef ?? useRef<boolean>(true);

  const onCloseModal = async () => {
    onClose?.();
  };

  const [
    initLoginWithWalletConnectV2,
    { error: walletConnectErrorV2 },
    {
      connectExisting,
      removeExistingPairing,
      uriDeepLink: walletConnectDeepLinkV2,
      walletConnectUri: walletConnectUriV2,
      wcPairings
    }
  ] = useWalletConnectV2Login({
    callbackRoute: props.callbackRoute,
    token: props.token,
    nativeAuth: props.nativeAuth,
    onLoginRedirect: props.onLoginRedirect,
    logoutRoute: props.logoutRoute,
    canLoginRef,
  });

  const [qrCodeSvg, setQrCodeSvg] = useState<string>('');

  const isMobileDevice = isMobileEnvironment();
  const activePairings =
    wcPairings?.filter((pairing) => {
      const hasLaterEntry = wcPairings.some(
        (pairing2) =>
          pairing.peerMetadata?.name === pairing2?.peerMetadata?.name &&
          pairing.peerMetadata?.url === pairing2?.peerMetadata?.url &&
          pairing.expiry < pairing2.expiry
      );

      return Boolean(pairing.active) && pairing.peerMetadata && !hasLaterEntry;
    }) ?? [];

  const generateQRCode = async () => {
    if (!walletConnectUriV2) {
      return;
    }

    const svg = await QRCode.toString(walletConnectUriV2, {
      type: 'svg'
    });

    if (svg) {
      setQrCodeSvg(svg);
    }
  };

  useEffect(() => {
    generateQRCode();
  }, [walletConnectUriV2]);

  useEffect(() => {
    if (canLoginRef?.current === false) {
      return;
    }

    initLoginWithWalletConnectV2();
  }, [canLoginRef?.current]);


  if (showLoginContent === false) {
    return null;
  }

  return (
    <Dialog open={showLoginModal} onOpenChange={onCloseModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect xPortal</DialogTitle>
          <p className='text-gray-600'>Scan the QR code using the xPortal App</p>
        </DialogHeader>
        <div className='flex flex-col gap-2'>
          {/* <WalletConnectLoginContent {...props} canLoginRef={canLoginRef} /> */}
          {walletConnectErrorV2 && (
            <p className='text-status-danger text-center'>{walletConnectErrorV2}</p>
          )}
          {qrCodeSvg ? (
            <div dangerouslySetInnerHTML={{ __html: qrCodeSvg }} className='w-80 h-80 mx-auto' />
          ) : (
            <div className='h-80 flex items-center justify-center'>
              <Loader className='text-orange-600' />
            </div>
          )}

          {activePairings.length > 0 && (
            <Pairinglist
              activePairings={activePairings}
              connectExisting={connectExisting}
              removeExistingPairing={removeExistingPairing}
              className={''}
            />
          )}
        </div>
        <DialogFooter className='flex flex-col gap-4'>
          {isMobileDevice && (
            <a
              id='accessWalletBtn'
              href={walletConnectDeepLinkV2}
              rel='noopener noreferrer nofollow'
              target='_blank'
            >
              <Button className='w-full'>xPortal App</Button>
            </a>
          )}
          <Button variant='outline' className="w-full" onClick={onCloseModal}>Choose another method</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
