import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import { useGetAccountInfo, useGetModalLoginMethods, useLedgerLogin } from '@multiversx/sdk-dapp/hooks';

import { OnProviderLoginType } from '@multiversx/sdk-dapp/types';
import { WithClassnameType } from '../../types';
import { InnerLedgerComponentsClassesType } from '@multiversx/sdk-dapp/UI/ledger/LedgerLoginContainer/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog, DialogContent, DialogHeader, DialogTitle, FormatAmount, Loader } from 'components';
import { getIsNativeAuthSingingForbidden } from '@multiversx/sdk-dapp/services/nativeAuth/helpers';
import { SelectedAddress } from '@multiversx/sdk-dapp/hooks/login/useLedgerLogin';
import { LedgerAccountType } from '@multiversx/sdk-dapp/reduxStore/slices';
import { getAccountBalance, getEgldLabel } from '@multiversx/sdk-dapp/utils';
import { useSelector } from '@multiversx/sdk-dapp/reduxStore/DappProviderContext';
import { tokenLoginSelector } from '@multiversx/sdk-dapp/reduxStore/selectors';
import { getAuthTokenText } from '@multiversx/sdk-dapp/UI/ledger/LedgerLoginContainer/ConfirmAddress/helpers';

export interface ConfirmAddressPropsType extends WithClassnameType {
  token?: string;
  onClose?: () => void;
}

const ConfirmAddress = ({
  token,
  onClose,
}: ConfirmAddressPropsType) => {
  const { ledgerAccount } = useGetAccountInfo();

  const tokenLogin = useSelector(tokenLoginSelector);
  const loginToken = tokenLogin?.loginToken ?? token;

  const authTokenText = getAuthTokenText({
    loginToken,
    version: ledgerAccount?.version
  });

  return (
    <div className='flex flex-col gap-6'>
      <div className='py-4'>
        <object data="/img/connect/ledger-connect.svg" type="image/svg+xml" className='h-auto w-full max-w-xs mx-auto' />
      </div>
      <div className='flex flex-col gap-3 text-center'>
        <p className='text-gray-600'>{authTokenText?.confirmAddressText}</p>
        <p className='text-primary-200 break-words  py-2 px-4 border'>{ledgerAccount?.address ?? ''}</p>
      </div>
      <div className='flex flex-col gap-3 text-center'>
        <p className='text-gray-600'>{authTokenText?.authText}</p>
        <p className='text-primary-200 break-words  py-2 px-4 border'>{authTokenText?.data}</p>
      </div>
      <div className='flex flex-col gap-3 text-center px-4'>
        <p className='text-gray-600'>Select <span className='text-gray-900'>Approve</span> on your device to confirm.</p>
        <p className='text-gray-600'>If it does not match, close this page and <a href='https://help.multiversx.com/en/' target='_blank' rel='noreferrer' className='text-primary-200'>Contact Support</a>.</p>
      </div>
      <Button variant='outline' className="w-full" onClick={onClose}>Cancel</Button>
    </div>
  );
};

const ADDRESSES_PER_PAGE = 10;

const LedgerLoading = ({ onClose }: { onClose?: () => void }) => {
  return (
    <>
      <div className='flex flex-col gap-4 justify-center text-orange-600 text-center py-10'>
        <Loader className='text-2xl' />
        <div className='flex flex-col gap-1 leading-4'>
          <p>Waiting for device...</p>
          <p>It may take a few seconds...</p>
        </div>
      </div>
      <div className='flex flex-col gap-4'>
        <Button variant='outline' className="w-full" onClick={onClose}>Choose another method</Button>
        <a
          href='https://support.ledger.com/hc/en-us/articles/115005165269-Connection-issues-with-Windows-or-Linux'
          target='_blank'
          rel='noopener noreferrer'
          className='text-primary-200 text-center underline'
        >
          Having connection issues?
        </a>
      </div>
    </>
  )
}

export interface AddressRowPropsType extends WithClassnameType {
  selectedAddress?: string;
  index: number;
  address: string;
  balance: string;
  onSelectAddress: (address: { address: string; index: number } | null) => void;
  ledgerModalTableSelectedItemClassName?: string;
  disabled: boolean;
}

const AddressRow = ({
  address,
  index,
  balance,
  selectedAddress,
  onSelectAddress,
  disabled = false,
}: AddressRowPropsType) => {
  const handleChange = (event: SyntheticEvent) => {
    const { checked } = event.target as HTMLInputElement;

    if (checked) {
      onSelectAddress({ address, index });
    }
  };

  return (
    <div className={`flex justify-between p-2 border-b-[1px] gap-2 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-[#E9ECEF66]'}`}>
      <div className='flex gap-2 items-center w-full'>
        <input
          type='radio'
          id={`check_${address}`}
          disabled={disabled}
          onChange={handleChange}
          role='button'
          checked={selectedAddress === address}
          className='w-8'
        />

        <label htmlFor={`check_${index}`} role='button' >
          {address.slice(0, 8) + ' ... ' + address.slice(-6)}
        </label>
      </div>

      <FormatAmount value={balance} egldLabel={getEgldLabel()} className='w-48' />

      <div className='text-gray-600'>{index}</div>
    </div>
  );
};


export interface AddressTablePropsType extends WithClassnameType {
  accounts: string[];
  disabledIndexes?: number[];
  addressTableClassNames?: {
    ledgerModalTitleClassName?: string;
    ledgerModalSubtitleClassName?: string;
    ledgerModalTableHeadClassName?: string;
    ledgerModalTableItemClassName?: string;
    ledgerModalButtonClassName?: string;
    ledgerModalTableNavigationButtonClassName?: string;
    ledgerModalTableSelectedItemClassName?: string;
    ledgerModalTableNavigationButtonDisabledClassName?: string;
  };
  customContentComponent?: ReactNode;
  dataTestId?: string;
  loading: boolean;
  onConfirmSelectedAddress: () => void;
  onGoToNextPage: () => void;
  onGoToPrevPage: () => void;
  onSelectAddress: (address: { address: string; index: number } | null) => void;
  selectedAddress?: string;
  startIndex: number;
  onClose?: () => void;
}

const AddressTable = ({
  accounts,
  addressTableClassNames,
  className = 'dapp-ledger-address-table',
  customContentComponent,
  loading,
  onConfirmSelectedAddress,
  onGoToNextPage,
  onGoToPrevPage,
  onSelectAddress,
  selectedAddress,
  startIndex,
  disabledIndexes = [],
  onClose,
}: AddressTablePropsType) => {
  const [accountsWithBalance, setAccountsWithBalance] = useState<
    Array<{ address: string; balance: string }>
  >([]);

  const getFirstUnusedIndex = () => {
    let indexToCheck = 0;
    while (disabledIndexes.includes(indexToCheck)) {
      indexToCheck++;
    }
    return indexToCheck;
  };

  useEffect(() => {
    const isAccountsLoaded = accounts.length > 0 && !loading;

    const isFirstPageAndNoAddressSelected =
      !selectedAddress && startIndex === 0;

    const shouldSelectFirstAddress =
      isAccountsLoaded && isFirstPageAndNoAddressSelected;
    if (shouldSelectFirstAddress) {
      const index = getFirstUnusedIndex();
      const address = accounts[index];
      onSelectAddress({ address, index });
    }
  }, [accounts, selectedAddress, loading, startIndex]);

  const fetchBalance = async (address: string) => {
    try {
      const balance = await getAccountBalance(address);
      return { address, balance };
    } catch (err) {
      console.error('error fetching balance', err);
      throw accountsWithBalance;
    }
  };

  useEffect(() => {
    const balancePromises = accounts.map((account) => fetchBalance(account));
    setAccountsWithBalance(
      accounts.map((account) => ({ address: account, balance: '...' }))
    );
    Promise.all(balancePromises).then((balances) => {
      setAccountsWithBalance(balances);
    });
  }, [accounts]);

  if (loading) {
    return <LedgerLoading />;
  }

  const onConfirm = () => {
    if (!selectedAddress) {
      return;
    }

    onConfirmSelectedAddress();
  };

  return (
    <div className='flex flex-col gap-4' >
      <div >
        <div className='bg-gray-200 text-gray-600 flex justify-between p-2 gap-2'>
          <div className='pl-11 w-full'>Address</div>
          <div className='w-48'>Balance</div>
          <div>#</div>
        </div>

        <div className='border border-b-0'>
          {accountsWithBalance &&
            accountsWithBalance.map(({ address, balance }, index) => (
              <AddressRow
                address={address}
                balance={balance}
                disabled={disabledIndexes.includes(index)}
                key={index + startIndex * ADDRESSES_PER_PAGE}
                index={index + startIndex * ADDRESSES_PER_PAGE}
                selectedAddress={selectedAddress}
                onSelectAddress={onSelectAddress}
              />
            ))}
        </div>
      </div>

      <div className='flex justify-between underline text-primary-200'>
        <button type='button' onClick={onGoToPrevPage} >
          Prev
        </button>
        <button type='button' onClick={onGoToNextPage}>
          Next
        </button>
      </div>
      <div className='flex flex-col gap-2'>
        <Button variant='default' className="w-full" onClick={onConfirm} disabled={!selectedAddress}>Access Wallet</Button>
        <Button variant='outline' className="w-full" onClick={onClose}>Choose another method</Button>
        <a
          href='https://support.ledger.com/hc/en-us/articles/115005165269-Connection-issues-with-Windows-or-Linux'
          target='_blank'
          rel='noopener noreferrer'
          className='text-primary-200 text-center underline'
        >
          Having connection issues?
        </a>
      </div>
    </div>
  );
};

export interface LedgerConnectPropsType extends WithClassnameType {
  onClick: () => void;
  disabled?: boolean;
  error: string;
  connectPageContent?: ReactNode;
  customContentComponent?: ReactNode;
  onClose?: () => void;
}

const LedgerConnect = ({
  onClick,
  error,
  disabled,
  onClose
}: LedgerConnectPropsType) => {
  return (
    <div className='flex flex-col gap-6'>
      {error && (<p className='text-status-danger text-sm text-center'>{error}</p>)}
      <div>
        <object data="/img/connect/ledger-connect.svg" type="image/svg+xml" className='h-auto w-full max-w-xs mx-auto' />
      </div>
      <div className='flex flex-col gap-2'>
        <Button variant='default' className="w-full" onClick={onClick} disabled={disabled}>Connect Device</Button>
        <Button variant='outline' className="w-full" onClick={onClose}>Choose another method</Button>
        <a
          href='https://support.ledger.com/hc/en-us/articles/115005165269-Connection-issues-with-Windows-or-Linux'
          target='_blank'
          rel='noopener noreferrer'
          className='text-primary-200 text-center underline'
        >
          Having connection issues?
        </a>
      </div>
    </div>
  );
};

export interface LedgerLoginContentBodyProps {
  accounts: string[];
  customContentComponent: ReactNode;
  customSpinnerComponent: ReactNode;
  disabledConnectButton?: boolean;
  error: string;
  isLoading: boolean;
  ledgerAccount: LedgerAccountType | null; // Please define the appropriate type
  onConfirmSelectedAddress: () => void;
  onGoToNextPage: () => void;
  onGoToPrevPage: () => void;
  onSelectAddress: (address: SelectedAddress | null) => void;
  onStartLogin: () => void;
  selectedAddress: SelectedAddress | null;
  showAddressList: boolean;
  startIndex: number;
  token?: string;
  onClose?: () => void;
}

export const LedgerLoginContentBody = ({
  isLoading,
  customContentComponent,
  ledgerAccount,
  error,
  token,
  showAddressList,
  accounts,
  onConfirmSelectedAddress,
  onGoToNextPage,
  onGoToPrevPage,
  onSelectAddress,
  selectedAddress,
  startIndex,
  disabledConnectButton,
  onStartLogin,
  onClose
}: LedgerLoginContentBodyProps) => {
  if (isLoading) {
    return (
      <LedgerLoading />
    );
  }

  if (ledgerAccount != null && !error) {
    return (
      <ConfirmAddress
        customContentComponent={customContentComponent}
        token={token}
        onClose={onClose}
      />
    );
  }

  if (showAddressList && !error) {
    return (
      <AddressTable
        accounts={accounts}
        customContentComponent={customContentComponent}
        loading={isLoading}
        onConfirmSelectedAddress={onConfirmSelectedAddress}
        onGoToNextPage={onGoToNextPage}
        onGoToPrevPage={onGoToPrevPage}
        onSelectAddress={onSelectAddress}
        selectedAddress={selectedAddress?.address}
        startIndex={startIndex}
        onClose={onClose}
      />
    );
  }

  return (
    <LedgerConnect
      customContentComponent={customContentComponent}
      disabled={disabledConnectButton}
      error={error}
      onClick={onStartLogin}
      onClose={onClose}
    />
  );
};

export const LedgerLoginContent = ({
  callbackRoute,
  customSpinnerComponent,
  customContentComponent,
  nativeAuth,
  onLoginRedirect,
  token,
  onClose,
}: Partial<LedgerLoginContainerPropsType>) => {
  const { ledgerAccount } = useGetAccountInfo();
  const [
    onStartLogin,
    { error, isLoading },
    {
      accounts,
      onConfirmSelectedAddress,
      onGoToNextPage,
      onGoToPrevPage,
      onSelectAddress,
      selectedAddress,
      showAddressList,
      startIndex
    }
  ] = useLedgerLogin({ callbackRoute, token, onLoginRedirect, nativeAuth });

  const disabledConnectButton = getIsNativeAuthSingingForbidden(token);

  const ledgerLoginContentBodyProps: LedgerLoginContentBodyProps = {
    accounts,
    customContentComponent,
    customSpinnerComponent,
    disabledConnectButton,
    error,
    isLoading,
    ledgerAccount,
    onConfirmSelectedAddress,
    onGoToNextPage,
    onGoToPrevPage,
    onSelectAddress,
    onStartLogin,
    selectedAddress,
    showAddressList,
    startIndex,
    token,
    onClose,
  };

  return (
    <LedgerLoginContentBody {...ledgerLoginContentBodyProps} />
  );
};

export interface LedgerLoginContainerPropsType
  extends OnProviderLoginType,
  WithClassnameType {
  onClose?: () => void;
  customSpinnerComponent?: ReactNode;
  customContentComponent?: ReactNode;
  innerLedgerComponentsClasses?: InnerLedgerComponentsClassesType;
  showLoginContent?: boolean;
  showLoginModal?: boolean;
  showProgressBar?: boolean;
  showScamPhishingAlert?: boolean;
  wrapContentInsideModal?: boolean;
}

const LedgerLoginContainer = (
  props: LedgerLoginContainerPropsType
) => {
  const {
    onClose,
    showLoginContent,
  } = props;

  if (showLoginContent === false) {
    return null;
  }

  return (
    <Dialog open={props.showLoginModal} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect Ledger</DialogTitle>
          <p className='text-gray-600'>Unlock your device & open the MultiversX App</p>
        </DialogHeader>
        <LedgerLoginContent {...props} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};


export interface LedgerLoginButtonPropsType
  extends WithClassnameType,
  OnProviderLoginType {
  buttonClassName?: string;
  children?: ReactNode;
  customSpinnerComponent?: ReactNode;
  disabled?: boolean;
  hideButtonWhenModalOpens?: boolean;
  innerLedgerComponentsClasses?: InnerLedgerComponentsClassesType;
  loginButtonText?: string;
  modalClassName?: string;
  onContentHide?: (props?: any) => void;
  onContentShow?: (props?: any) => void;
  onModalCloses?: (props?: any) => void;
  onModalOpens?: (props?: any) => void;
  showProgressBar?: boolean;
  showScamPhishingAlert?: boolean;
  wrapContentInsideModal?: boolean;
}

export const LedgerLoginButton: (
  props: LedgerLoginButtonPropsType
) => JSX.Element = ({
  callbackRoute,
  customSpinnerComponent,
  disabled,
  hideButtonWhenModalOpens = false,
  innerLedgerComponentsClasses,
  modalClassName,
  onLoginRedirect,
  onContentHide,
  onContentShow,
  onModalCloses,
  onModalOpens,
  showProgressBar = true,
  showScamPhishingAlert = true,
  token,
  wrapContentInsideModal = true
}) => {
    const {
      disabledConnectButton,
      handleCloseModal,
      handleOpenModal,
      shouldRenderButton,
      showContent,
      showModal
    } = useGetModalLoginMethods({
      hideButtonWhenModalOpens,
      onContentHide,
      onContentShow,
      onModalCloses,
      onModalOpens,
      token,
      wrapContentInsideModal
    });

    return (
      <>
        {shouldRenderButton && (
          <button
            disabled={disabled || disabledConnectButton}
            onClick={handleOpenModal}
            className='flex pb-2 pr-2 justify-between items-center'
          >
            <div className='flex gap-3 items-center'>
              <div className='w-7 h-7 bg-black flex items-center justify-center'>
                <object data="/img/connect/ledger.svg" type="image/svg+xml" className='h-4 w-auto' />
              </div>
              <span className="text-md text-gray-900">Ledger</span>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className='text-[#888888] text-md' />
          </button>
        )}

        <LedgerLoginContainer
          callbackRoute={callbackRoute}
          className={modalClassName}
          customSpinnerComponent={customSpinnerComponent}
          innerLedgerComponentsClasses={innerLedgerComponentsClasses}
          nativeAuth={true}
          onClose={handleCloseModal}
          onLoginRedirect={onLoginRedirect}
          showLoginContent={showContent}
          showLoginModal={showModal}
          showProgressBar={showProgressBar}
          showScamPhishingAlert={showScamPhishingAlert}
          token={token}
          wrapContentInsideModal={wrapContentInsideModal}
        />
      </>
    );
  };