import { useSearchParams } from 'react-router-dom';

const useSearchParam = <T extends string>({
 key,
 defaultValue,
}: {
 key: string;
 defaultValue?: string | undefined;
}): [string, (value: string | undefined) => void] => {
 const [searchParams, setSearchParams] = useSearchParams();

 const setter = (value: string | undefined) => {
  const newParams = new URLSearchParams(searchParams.toString());
  newParams.set(key, value as string);
  setSearchParams(newParams);
 };

 return [(searchParams.get(key) || defaultValue || '') as string, setter];
};

export default useSearchParam;