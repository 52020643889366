import { withPageTitle } from 'hooks';
import { RouteNamesEnum } from 'localConstants';
import { About, CreatedLaunchpads, CreateLaunchpad, Home, Launchpad } from 'pages';
import { KarmaPage } from 'pages/Karma';
import { TwitterConfirmation } from 'pages/TwitterConfirmation';
import { RouteType } from 'types';

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: RouteNamesEnum.home,
    title: 'Home',
    component: withPageTitle('Welcome to MemeversX!', Home)
  },
  // {
  //   path: RouteNamesEnum.about,
  //   title: 'About',
  //   component: About
  // },
  // {
  //   path: RouteNamesEnum.launchpads,
  //   title: 'Launchpads',
  //   component: Home
  // },
  {
    path: RouteNamesEnum.launchpadDetails,
    title: 'Launchpad',
    component: Launchpad
  },
  {
    path: RouteNamesEnum.createLaunchpad,
    title: 'Create New Launchpad',
    component: withPageTitle('Create new Launchpad • MemeversX', CreateLaunchpad),
    authenticatedRoute: true
  },
  {
    path: RouteNamesEnum.createdLaunchpads,
    title: 'Create Launchpad',
    component: withPageTitle('My Launchpads • MemeversX', CreatedLaunchpads),
    authenticatedRoute: true
  },
  {
    path: RouteNamesEnum.karma,
    title: 'Karma',
    component: withPageTitle('Karma • MemeversX', KarmaPage),
    authenticatedRoute: true
  },
  {
    path: RouteNamesEnum.twitterConfirmation,
    title: 'X Confirmation',
    component: withPageTitle('X Confirmation • MemeversX', TwitterConfirmation),
    authenticatedRoute: true
  },
];
