import IcKarmaHeader from 'assets/img/ic_karma_page_header.svg?react';
import { Button } from 'components';
import { KarmaInfo, KarmaLeaderboard, KarmaQuestsModal, KarmaUserStats } from './Components';
import pixelationBorder from 'assets/img/pixelation-border-white.webp';
import { useState } from 'react';
import { useGetUserStatus } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface KarmaPageProps {
  launchCountdown?: boolean;
}

export const KarmaPage = ({ launchCountdown }: KarmaPageProps) => {
  const [showKarmaQuestsPanel, setShowKarmaQuestsPanel] = useState<boolean>(false);
  const { data, isLoading, refetch } = useGetUserStatus();

  return (
    <div>
      <div className='flex flex-col justify-center items-center bg-karma'>
        {launchCountdown && (
          <div className='absolute top-0 w-full z-[40] mt-[-1px]'>
            <img src={pixelationBorder} className='w-full' />
          </div>
        )}
        <div className='flex flex-col z-10 relative m-auto items-center'>
          <IcKarmaHeader />
          <span className='karma-points'>{isLoading ? <FontAwesomeIcon icon={faSpinner} className='text-purple-400 animate-spin duration-1000' /> : !data ? '0' : data?.karma}</span>
          <span className='karma-points-label'>My Karma</span>
          <Button className='mt-8 px-6 text-[18px] bg-purple-400 hover:bg-purple-600 border-purple-600 normal-case' size='sm' onClick={() => setShowKarmaQuestsPanel(true)}>
            Complete Quests & Earn Karma
          </Button>
        </div>
      </div>
      <div className='flex flex-row flex-wrap justify-center gap-20 mx-auto p-2 mb-20'>
        <KarmaLeaderboard />
        <KarmaUserStats userStatus={data} />
      </div>
      <div className='container flex flex-wrap justify-center gap-6 mx-auto mb-20 p-2'>
        <KarmaInfo title='What is Karma?' subtitle='Karma is the MemeversX points system. You earn points for your activities. The more points you have, the better airdrops you will receive.' />
        <KarmaInfo title='How can I <br/> get Karma?' subtitle='Earn Karma points by participating in both on-chain and off-chain activities. This includes engaging on social media platforms, swapping tokens, or adding liquidity.' />
        <KarmaInfo title='What is <br/> Karma for?' subtitle='Karma points are rewards you get for being active. You earn them by doing things like posting on social media or trading. More points mean better rewards!' />
        <KarmaInfo title='How do I check <br/> my Karma?' subtitle='To see your Karma points, check the top right corner of the dApp.' />
        <KarmaInfo title='Can I exchange <br/> Karma for <br/> tokens?' subtitle='Rewards are distributed directly to your wallet, depending on your Karma points or current campaigns. Karma points are connected to your wallet and cannot be exchanged.' />
      </div>
      <KarmaQuestsModal show={showKarmaQuestsPanel} onHide={() => setShowKarmaQuestsPanel(false)} refetchUserStatus={refetch} />
    </div>
  );
};
