import { faCheck, faHandHoldingDollar, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatAmount } from "@multiversx/sdk-dapp/UI";
import { LaunchpadDetails } from "types";
import BigNumber from 'bignumber.js';
import { countDecimalPlaces } from "utils/countDecimalPlaces";
import { computeListingPrice } from "utils";

export const HowItWorks = ({ launchpad }: { launchpad: LaunchpadDetails; }) => {
  const listingTokenPrice = computeListingPrice(new BigNumber(launchpad.maxAmountToRaise), launchpad.launchpadPercentage, launchpad.tokenSupply);

  return (
    <div className='flex flex-col gap-6 text-gray-600 lg:mt-12'>
      <h3 className="text-xl text-gray-900">How does it work?</h3>
      <div className="flex flex-col">
        <div className="flex px-0 py-5 gap-6 md:flex-col">
          <div className="basis-1/3 flex gap-6 border-r-[1px] border-gray-300 items-center md:border-0">
            <div className="text-md text-blue-500 bg-blue-300 w-10 h-10 shadow-sm shadow-blue-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faRocket} />
            </div>
            <h4 className="text-md text-gray-900">During Launchpad</h4>
          </div>
          <div className="flex items-center">
            <ul className="list-square ml-6">
              <li>You get {launchpad.token.ticker} at a fixed price of <FormatAmount value={launchpad.tokenPrice} digits={countDecimalPlaces(launchpad.tokenPrice, launchpad.token.decimals)} /> per token.</li>
              <li>Each address can purchase a maximum of <FormatAmount value={launchpad.maxTokensPerUser} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.maxTokensPerUser, launchpad.token.decimals)} /> tokens.</li>
            </ul>
          </div>
        </div>
        <div className="flex px-0 py-5 gap-6 md:flex-col">
          <div className="basis-1/3 flex gap-6 border-r-[1px] border-gray-300 items-center md:border-0">
            <div className="text-md text-teal-500 bg-teal-300 w-10 h-10 shadow-sm shadow-teal-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <h4 className="text-md text-gray-900">If All Tokens <br /> Sell Out</h4>
          </div>
          <div className="flex items-center">
            <ul className="list-square ml-6">
              <li>Token gets issued, branded and listed on xExchange.</li>
              <li>Listing price: 1 EGLD ≈ {listingTokenPrice} {launchpad.token.ticker}.</li>
              <li>Liquidity is burned for a fair, scam-free launch.</li>
              <li>Claim your tokens and join the memes!</li>
            </ul>
          </div>
        </div>
        <div className="flex px-0 py-5 gap-6 md:flex-col">
          <div className="basis-1/3 flex gap-6 border-r-[1px] border-gray-300 items-center md:border-0">
            <div className="text-md text-orange-500 bg-orange-300 w-10 h-10 shadow-sm shadow-orange-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faHandHoldingDollar} />
            </div>
            <h4 className="text-md text-gray-900">If Tokens <br /> Don’t Sell Out</h4>
          </div>
          <div className="flex items-center">
            <ul className="list-square ml-6">
              <li>Token is not issued.</li>
              <li>Redeem 100% of your spent EGLD.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
