import * as React from "react"

import { cn } from "utils/cn"

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loader = ({ className }: { className?: string }) => {
  return (
    <FontAwesomeIcon className={cn('text-xl animate-spin', className)} icon={faSpinner} />
  );
};

export { Loader }
