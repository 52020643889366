import { faRocket, faSquare, faTag, faWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LaunchpadState } from "types";

interface DraftLaunchpadStatusProps {
  status: LaunchpadState;
}

export const DraftLaunchpadStatus = ({ status }: DraftLaunchpadStatusProps) => {
  switch (status) {
    case LaunchpadState.WAITING_FOR_TOKEN_BRANDING:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-orange-400 bg-orange-100 border border-orange-400">
          <FontAwesomeIcon icon={faWandSparkles} className='text-orange-400' />
          <span className="uppercase">Branding</span>
        </div>
      );
    case LaunchpadState.WAITING_FOR_SALE_INFO:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-orange-400 bg-orange-100 border border-orange-400">
          <FontAwesomeIcon icon={faTag} className='text-orange-400' />
          <span className="uppercase">Sale</span>
        </div>
      );
    case LaunchpadState.WAITING_FOR_PUBLISH:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-orange-400 bg-orange-100 border border-orange-400">
          <FontAwesomeIcon icon={faRocket} className='text-orange-400' />
          <span className="uppercase">Launch</span>
        </div>
      )
    case LaunchpadState.PUBLISHED:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-green-600 bg-green-100 border border-green-400">
          <FontAwesomeIcon icon={faSquare} className='text-[8px] text-green-600' />
          <span className="uppercase">Published</span>
        </div>
      )
    default:
      return (
        <div className="h-30 px-4 py-2 flex gap-2 justify-center items-center text-gray-400 bg-gray-100 border border-gray-400">
          <span className="uppercase"></span>
        </div>
      );
  }
};