interface KarmaInfoProps {
  title: string;
  subtitle: string;
}

export const KarmaInfo = ({ title, subtitle }: KarmaInfoProps) => {
  return (
    <div className='min-w-[calc(33.333%-1rem)] flex justify-center'>
      <div className='flex flex-col items-center flex-1 max-w-[400px]'>
        <span className='karma-info' dangerouslySetInnerHTML={{ __html: title }} />
        <span className='text-gray-600 text-[18px] text-center mt-3'>{subtitle}</span>
      </div>
    </div>
  );
};
