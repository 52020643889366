import { Button, FormatAmount, Info, LaunchpadIcon } from "components";
import useSendAndSignTransaction from "hooks/useSendAndSignTransaction";
import { CreateLaunchpadDTO, LaunchpadState, LaunchpadStepStatus } from "types";
import { countDecimalPlaces } from "utils/countDecimalPlaces";
import SuccessPublishModal from "../components/SuccessPublishModal";
import { usePublishCreateLaunchpad } from "../hooks";
import { useEffect } from "react";
import { Transaction } from "@multiversx/sdk-core/out";
import { RouteNamesEnum } from "localConstants";
import { Link } from "react-router-dom";

interface PublishStepProps {
  launchpad: CreateLaunchpadDTO;
}

export const PublishStep = ({ launchpad }: PublishStepProps) => {
  const { isLoading, startCreateLaunchpad, isError, transactionPlain, setTransactionPlain } = usePublishCreateLaunchpad();
  const { sendAndSignTransaction, loadingTransaction, successTransaction, failedTransaction } = useSendAndSignTransaction({
    processingMessage: 'Processing publish transaction',
    errorMessage: 'An error has occurred while processing the publishing transaction',
    successMessage: 'Publish successful'
  });

  const loading = loadingTransaction || isLoading;
  const isPublished = launchpad.currentStep === LaunchpadState.PUBLISHED
  const disabled = isPublished || loadingTransaction || isLoading;
  const isPendingVerification = launchpad?.currentStepData?.status === LaunchpadStepStatus.PENDING_VERIFICATION;

  useEffect(() => {
    if (transactionPlain) {
      const transaction = Transaction.fromPlainObject(transactionPlain);
      sendAndSignTransaction(transaction);
      setTransactionPlain(undefined);
    }
  }, [transactionPlain]);

  return (
    <>
      {successTransaction && <SuccessPublishModal launchpad={launchpad} />}
      <div className='flex flex-col gap-12 py-12 text-gray-600'>
        <div className='flex flex-col gap-12 items-center'>
          <div className='flex flex-col items-center'>
            <div className='flex items-end mb-4'>
              <LaunchpadIcon icon={launchpad.tokenBranding?.pngUrl ?? launchpad.tokenBranding?.svgUrl} size='sm' className='outline outline-4 outline-gray-100 mr-[-12px] bg-gray-100' />
              <LaunchpadIcon icon={launchpad.tokenBranding?.pngUrl ?? launchpad.tokenBranding?.svgUrl} className='outline outline-4 outline-gray-100 bg-gray-100' />
              <LaunchpadIcon icon={launchpad.tokenBranding?.pngUrl ?? launchpad.tokenBranding?.svgUrl} size='sm' className='outline outline-4 outline-gray-100 ml-[-12px] bg-gray-100' />
            </div>
            <h1 className='text-2xl text-primary-200'>{launchpad.token.name}</h1>
            <p className='text-gray-900 text-lg'>
              [<FormatAmount value={launchpad.token.supply} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.token.supply, launchpad.token.decimals)} />]
            </p>
          </div>
          <p className='max-w-md text-center leading-4'>Ready to launch your meme token? Take the final step and deploy the sale smart contract. <br /> <br />Your meme journey begins now - let the memes thrive!</p>
        </div>
        <div className='flex flex-col items-center gap-2'>
          <div className='max-w-[200px] w-full mx-auto'>
            {successTransaction ? (
              <>
                <Link to={RouteNamesEnum.launchpadDetails.replace(':slug', launchpad.slug)}>
                  <Button className='w-full'>View launchpad</Button>
                </Link>
                <div className='text-primary-100 uppercase bg-gray-200 p-3 w-full text-center'>
                  Your launchpad is Live!
                </div>
              </>
            ) : (
              <>
                <Button
                  className='w-full'
                  onClick={() => startCreateLaunchpad(launchpad.id)}
                  disabled={disabled || loading || isPendingVerification || isPublished}
                >
                  {loading ? 'Launching...' : 'Launch token'}
                </Button>
                <div className='text-orange-600 uppercase bg-gray-200 py-3 px-4 w-full'>
                  <div className='flex justify-between'>
                    Protocol fee:
                    <span className="flex gap-1">
                      1 EGLD
                      <Info className="text-xs hover:text-orange-500" containerClassName='!min-w-[120px]'>
                        <p className="normal-case">NON-REFUNDABLE</p>
                      </Info>
                    </span>
                  </div>
                  <div className='flex justify-between'>
                    Platofrm fee:
                    <span className="flex gap-1">
                      1%
                      <Info className="text-xs hover:text-orange-500">
                        <p className="normal-case">1% of the raised amount will be used to <br /> <u>buy-back</u> the token and will be distributed based on Karma</p>
                      </Info>
                    </span>
                  </div>
                </div>
              </>)}
          </div>
          {/* // TODO: fail tx if not in future */}
          {failedTransaction && (<p className='text-xs text-status-danger'>The transactions has failed</p>)}
          {isError && (<p className='text-xs text-status-danger'>An error has occurred</p>)}
          {isPendingVerification && (<p className='text-xs text-orange-600'>Token branding verification in progress.</p>)}
        </div>
      </div>
    </>
  );
};
