import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogContent } from 'components/__base__';
import { logout } from 'helpers';
import { useAcceptTermsAndConditions } from 'hooks';
import { useState } from 'react';
interface TermsAndConditionsModalProps {
  show: boolean;
  onHide: () => void;
  refetchUserStatus: (() => Promise<any>) | undefined;
}

export const TermsAndConditionsModal = ({ show, onHide, refetchUserStatus }: TermsAndConditionsModalProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleOnAcceptSuccess = () => {
    refetchUserStatus?.();
    onHide();
    setTermsAccepted(false);
  }
  const { startAcceptTermsAndConditions, isLoading } = useAcceptTermsAndConditions(handleOnAcceptSuccess);

  const handleCheckboxChange = () => {
    setTermsAccepted(prev => !prev);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    logout(window.location.origin, undefined, false);
    onHide();
  };

  const handleClickContinue = () => {
    startAcceptTermsAndConditions()
  }

  return (
    <Dialog open={show} onOpenChange={handleLogout}>
      <DialogContent
        className='border-0 max-h-screen w-fit max-w-screen no-scrollbar bg-gray-100 max-w-[550px] sm:min-w-fit sm:w-full p-6'
        hideCloseButton={true}
      >
        <div className='flex flex-row justify-between items-start'>
          <div className='flex flex-col gap-1'>
            <h2 className='text-lg'>Terms & Conditions</h2>
            <p className='text-gray-600 text-xs'>Last revised: 8<sup>th</sup> April 2024</p>
          </div>
          <FontAwesomeIcon icon={faClose} className='text-[#888888] h-4 w-4 cursor-pointer' onClick={handleLogout} />
        </div>
        <div className='bg-white border-gray-400 border-[1px] h-[420px] max-h-screen pl-4 pr-2 py-2 '>
          <div className='overflow-y-scroll thin-scrollbar h-full pr-2 text-gray-600 flex flex-col gap-2'>
            <h3 className='text-bold text-gray-900 text-md'>1. Welcome to MemeversX!</h3>
            <p>PLEASE READ THE FOLLOWING TERMS CAREFULLY AS THEY GOVERN YOUR USE OF THE MEMEVERSX PLATFORM</p>
            <p>These Terms of Use is entered into between you (hereinafter referred to as “you” or “your”), as a Founder and/or as User, and, on the other side, MemeversX (hereinafter referred to as “us” or “we”).</p>
            <p>By using (in any possible way) our Services, you fully agree that you have read, understood, and accepted all the terms and conditions stipulated in these Terms of Use (hereinafter referred to as ‘’<b>Terms</b>’’).</p>
            <p>When you decide to access, test or use specific features described or pre-available on the Platform, you may be subject to specific additional terms and conditions that will be available in the corresponding section (if applicable).</p>
            <p>Accessing and using in any way our Services available on the Platform you fully acknowledge to be bound by these Terms and shall form a legal agreement and create a binding contract between you and MemeversX.</p>

            <h3 className='text-bold text-gray-900 text-md'>2. Who we are and how to contact us</h3>
            <p>Introducing the MemeversX Platform, the premier launchpad platform dedicated exclusively to the world of 'meme' crypto projects, where the Founders and Users alike have access to a specially designed gateway to create and be involved in the latest meme projects on the MultiversX Blockchain.</p>
            <p>For founders, MemeversX provides a launchpad experience with the essential tools and support needed to bring their meme-based cryptocurrency visions to life. From the idea to token distribution, our Platform offers a streamlined and intuitive process, allowing Founders to launch their projects with ease and efficiency.</p>
            <p>Meanwhile, for Users to explore and participate in the latest meme projects, MemeversX offers a curated selection of meme tokens available for acquisition, as Users can discover and engage with the most exciting and innovative projects in the meme crypto space.</p>
            <p>Should you have any queries regarding the Platform or our Services, please do not hesitate to contact us at <a href="mailto:contact@buildly.com" className='text-primary-200'>contact@buildly.com</a>.</p>

            <h3 className='text-bold text-gray-900 text-md'>3. Definitions (a-z)</h3>
            <ul className="list-square ml-3">
              <li>“Applicable Law” any binding legal provision, including but not limited to laws, statutes, regulations, codes, ordinances, norms, court decisions, orders, decrees, or any other normative or administrative acts, decisions, requirements, recommendations, interpretations, or applications by a public authority in Romania, or any other public authority, regardless of whether it has a binding effect.</li>
              <li>”Authority” means any statutory governmental, judicial, or other authority or any of them or any of their authorized representative;</li>
              <li>”Blockchain” is a system of recording information, in a way that makes it difficult or impossible to change, hack, or cheat the registered data and the system, in general using cryptography and being duplicated and distributed across the entire network of computer connected on the blockchain;</li>
              <li>”Cases of force majeure” represents extraordinary events or circumstances which neither Party could have foreseen or prevented by reasonable means, including but not limited to natural disasters, war, revolution, uprising civil insurrection, acts of terrorism, expropriation, nationalization, nuclear explosion, radioactive or chemical contamination or ionizing radiation, except lack of funds, which will not be a Force Majeure Case;</li>
              <li>”Cryptocurrencies” means a digital currency in which transactions are verified and records maintained by a decentralized system using cryptography named blockchain, rather than by a centralized authority, including but not limited to the Token;</li>
              <li>“Ecosystem” refers to the broader environment and set of interactions surrounding MemeversX. This encompasses components and dynamics involved in the Platform, project's deployment, operation, usage on the Blockchain.</li>
              <li>“Electronic Wallet” means the technical solution that allows a user to store, send or receive Cryptocurrencies or other digital assets by using Blockchain infrastructure. Each Wallet has a Public Address to which is assigned a unique Private Key.</li>
              <li>“Founder” refers to an individual or entity who initiates and publishes a meme crypto project on the launchpad platform, Founder is responsible for creating and launching meme projects, including defining project parameters, tokenomics, and distribution strategies</li>
              <li>“Gas fee” refers to the fees charged by the Blockchain infrastructure for performing and validating a transaction; generally, fees are charged in the Cryptocurrency used by each Blockchain for performing transactions;</li>
              <li>”Platform” means the website <a href='https://memeversx.com' className='text-primary-200'>www.memeversx.com</a> and its subdomains.</li>
              <li>“Private Key” refers to a confidential alphanumeric cryptographic code, uniquely associated with an Electronic Wallet, enabling its holder to access and control the associated digital assets or perform secure transactions on a Blockchain or other digital platforms.</li>
              <li>“Project” encompasses a meme crypto project initiated and published on the Platform, embodying a unique concept, tokenomics, Token distribution, and parameters as established by the Founder.</li>
              <li>”Public Address” means the sequence of characters (letters and numbers) that identifies an Electronic Wallet;</li>
              <li>“Recovery Phrase” means the specific sequence of words generated by the Blockchain infrastructure, when initiating an Electronic Wallet allowing the user the possibility to re-access / re-configure their Electronic Wallet, with the observance of the conditions imposed by the Electronic Wallet service provider.</li>
              <li>”MemeversX” it is a reference to STAKEGOLD S.R.L, a Romanian limited liability company headquartered at Bacau, 137 Bucegi Street, Entrance A, Ap. 34, registered under no 45295203;</li>
              <li>“Services” refers to the intermediary services offered via the Platform to meet the distinct needs of both Founders and Users. For Founders, these services would include specialized tools and support aimed at Project inception, Token sale and distribution, etc. Meanwhile, for Users, services entail access to meme projects, transparent project information, and user-friendly interfaces for acquiring meme tokens and engaging with the platform;</li>
              <li>”Token” pertains to a digital asset or cryptographic token associated with a Project published on the Platform and available to be acquired by the Users.</li>
            </ul>

            <h3 className='text-bold text-gray-900 text-md'>4. General Provisions</h3>
            <p>We do not provide investment or consulting advice of any kind and is not responsible for the use or interpretation of information available on the Platform (regardless the uploader) or provided throughout other relevant means of communication.</p>
            <p>All Services are available only to persons who are at least 18 years of age or are otherwise considered as having full civil capacity of their rights according to the applicable national regulations.</p>
            <p>It is incumbent upon all Founders and Users to understand and assume the risks associated with the use of Cryptocurrencies, including Non-Fungible Tokens (NFTs). It is further recommended to exercise a high degree of caution and assume responsibility for any decisions made in relation to any Cryptocurrencies, bearing in mind that any actions taken shall be at your own risk.</p>
            <p>Please take into consideration that the value of the Tokens, as any other Cryptocurrency on the open market, may change by +/- 100% every second by reference to the acquisition price or by reference to any previous value.</p>
            <p>These Terms pertain solely to the general functionalities and Services provided by the Platform. It is important to note that certain features of individual Projects or the associated Tokens may be subject to specific terms and conditions as defined by the Founders. Users are hereby advised to carefully observe and adhere to the specific terms and conditions applicable to the Projects or Tokens they engage with on the Platform, as outlined by the relevant project documentation or guidelines provided therein.</p>

            <h3 className='text-bold text-gray-900 text-md'>5. Founders</h3>
            <p><span className='text-gray-900'>Project Publication.</span> Founders are authorized to publish meme crypto Projects on the Platform, subject to compliance with Platform rules, guidelines, Terms and the Applicable Laws.</p>
            <p><span className='text-gray-900'>Project Parameters.</span> Founders are responsible for accurately setting Project parameters and for publishing the relevant information regarding each Project for the Users to determine the main characteristics and details of the Project and the Related Token, including project name, ticker symbol, Token distribution mechanisms, Public Addresses, and provide the required liquidity as established on the Platform on the launch date.</p>
            <p><span className='text-gray-900'>Compliance.</span> Founders must ensure that their Projects adhere to all legal and regulatory requirements, including but not limited to anti-money laundering (AML) and know-your-customer (KYC) regulations, if applicable.</p>
            <p><span className='text-gray-900'>Fee Payment.</span> Founders are required to pay any applicable fees associated with Project publication and token distribution on the Platform, including fees charged by MemeversX, gas fees related to blockchain transactions, etc.</p>

            <h3 className='text-bold text-gray-900 text-md'>6. Users</h3>
            <p><span className='text-gray-900'>Information Observation.</span> Users are advised to carefully observe and review all information provided for each project listed on the Platform, including project details, tokenomics, and associated risks.</p>
            <p><span className='text-gray-900'>Independent Research.</span> Users are encouraged to conduct their own research and due diligence before participating in any Project listed on the Platform, including evaluating Project fundamentals, team expertise, and market potential.</p>
            <p><span className='text-gray-900'>Fee Payment.</span> Users are responsible for paying any fees associated with participation in Projects on the Platform, including price for Token purchases, gas fees for Blockchain transactions, and any other applicable charges.</p>
            <p><span className='text-gray-900'>Risk Acknowledgment.</span> Users acknowledge and accept the inherent risks associated with participating in meme crypto projects, including but not limited to price volatility, liquidity risks, and potential loss of investment.</p>

            <h3 className='text-bold text-gray-900 text-md'>7. Use of the Platform</h3>
            <p><span className='text-gray-900'>Investment Advice.</span> The information provided on the Platform, including Project details, tokenomics, and associated risks, is for informational purposes only. It should not be construed as investment advice, and Users are encouraged to conduct their own research and due diligence before participating in any Project listed on the Platform.</p>
            <p><span className='text-gray-900'>No Endorsement.</span> MemeversX does not endorse or guarantee the performance, success, or value of any Projects listed on the Platform. The inclusion of a project on the platform does not constitute an endorsement or recommendation by MemeversX, and Users participate in Projects at their own risk</p>
            <p><span className='text-gray-900'>Volatility Warning.</span> Users acknowledge and accept the inherent risks associated with participating in Projects related to Cryptocurrencies, including but not limited to price volatility, liquidity risks, and potential loss of investment. Prices of the Tokens may fluctuate significantly and may result in substantial losses.</p>
            <p><span className='text-gray-900'>Regulatory Compliance.</span> Users and Founders are responsible for ensuring compliance with all Applicable Laws, regulations, and guidelines, including but not limited to anti-money laundering (AML) and know-your-customer (KYC) requirements. MemeversX does not provide legal advice, and Users should seek independent legal counsel regarding regulatory compliance.</p>
            <p><span className='text-gray-900'>Platform Governance.</span> MemeversX reserves the right to amend, update, or remove Projects and associated content from the Platform at its discretion, without prior notice. MemeversX may also update these Terms and Users and Founders are encouraged to review them regularly.</p>

            <h3 className='text-bold text-gray-900 text-md'>8. Prohibition of Use and Commitments</h3>
            <p>Depending on your country of residence, incorporation, or registered office, you may not be able to access or use any of the Services. It is your responsibility to follow the rules and laws in your country of residence and/or country from which you are interacting with our Services and/or the Token.</p>
            <p>As a Founder or a User, you represent and warrant that you have not been included (directly/indirectly) and have no relationship (directly/indirectly) with any person whose citizen or resident of a country which is/was included in any trade embargoes or economic sanctions list (such as the United Nations Security Council sanctions list), the list of specially designated nationals maintained by OFAC (The Office of Foreign Assets Control of the U.S. Department of the Treasury), the Financial Action Task Force (FATF), International Traffic In Arms Regulations (ITAR) or the denied persons or entity list of the U.S. Department of Commerce. The restrictions herein target the inclusion as a natural/legal person on the above-mentioned lists as well as the inclusion of the Founder or the User citizenship or residency state. </p>
            <p>We have the right to choose the markets and jurisdictions wherever it finds appropriate to conduct the business, and may restrict or refuse, in its discretion, the provision of Services in certain countries or regions.</p>

            <h3 className='text-bold text-gray-900 text-md'>9. Technical Partners and Providers and Third Parties</h3>
            <p>Services may occasionally be accessed through separate third-party websites or platforms, in which case those Platform's own policies, limitations, and terms & conditions will fully apply to those services.</p>
            <p>We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that we are not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
            <p>Henceforth, under no circumstances should we be considered liable or a party to any business relationship/Agreement concluded between any person and the third-party providing services in connection with the Tokens, even those services promoted or displayed on the Platform. </p>

            <h3 className='text-bold text-gray-900 text-md'>10. Campaigns</h3>
            <p>We may propose or accept to organize different Campaigns for different purposes, such as marketing Campaigns (Giveaways, Airdrops, Bounty) or economic Campaigns for supporting the integrity of the ecosystem’s economy (Locking Token Campaigns).</p>
            <p>Campaigns may be directly organized through the Platform, where those Terms and other specific Policies will be applicable, or through third party’s services, where the Campaigns will be fully governed by their terms & conditions.</p>
            <p>We are not responsible to any advertising campaigns which is not directly and fully conducted by us, even if those advertising campaigns are displayed on the Platform or can be accessed through external links/hyperlinks available in the Platform.</p>

            <h3 className='text-bold text-gray-900 text-md'>11. Identification</h3>
            <p>While using the Platform and any dedicated Services therein, in order to comply with the mandatory law provisions, we may require the Founders and the Users to prove their identity by providing the personal information or documents such as, but not limited to national ID, passport, driving license, live video, proof of funds, proof of residence (utility bill) and so on.</p>
            <p>When applicable, all personal data will be collected, used, and shared in accordance with the Privacy Policy displayed in the corresponding section of the Platform.</p>
            <p>Pursuant to the provisions of our terms of service, we reserve the right to deny access to the Services, in the event of non-compliance with our identification verifications and source of funds verification protocols. Furthermore, in the event that such non-compliance is discovered during the course of our due diligence procedures, we reserve the right to temporarily suspend access to the Services, until such time as the requisite information is provided and has been deemed sufficient to satisfy our know-your-customer and anti-money laundering compliance requirements.</p>
            <p>If there are any grounds for believing that any of the information you provided is incorrect, false, outdated, or incomplete, we will send you a notice to demand correction, directly delete the relevant information, and, as the case may be, terminate all or part of the Services we provide for you, including the services provided within the Platform. If we are unable to reach you with the contact information you provided, you shall be fully liable for any loss or expense caused to us during your use of its services.</p>

            <h3 className='text-bold text-gray-900 text-md'>12. Security</h3>
            <p>It is explicitly stated that we shall not be held liable for any actions or inaction in relation to your Electronic Wallets during the use of the Platform, which may include, but are not limited to, the transfer, reversal of transfer, or recovery of access to said wallets.</p>
            <p>Any action in connection with the Electronic Wallet/s and the Tokens shall be deemed to have been made by you.</p>
            <p>We shall not be held liable for any loss or consequences caused by authorized or unauthorized use of Electronic Wallets, Tokens, including but not limited to access caused by information disclosure, hacking, information release, phishing, phishing, and so on.</p>
            <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to our site, the server on which our site is stored, or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the regulation which governs the Platform’s activity. We will report any such breach to the relevant law enforcement authorities, and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>

            <h3 className='text-bold text-gray-900 text-md'>13. Restrictions</h3>
            <p>During the use of our Services, you firmly assert that at any point you:</p>
            <ul className="list-none ml-3">
              <li>- fully comply with the requirements of Applicable Laws and regulations, as well as these Terms;</li>
              <li>- do not violate other person’s rights, public interests, public morals, or the legitimate interests of others, including any actions that would interfere with, disrupt, negatively affect, or prohibit other person from using the Services;</li>
              <li>- refrain from for any kind of market manipulation in connection with the Token (such as but not limited to pump and dump schemes, self-trading, wash trading, quote stuffing, front running, and spoofing or layering, regardless of whether prohibited by law);</li>
              <li>- not try to identify any vulnerability of the Platform/Services (including the third party integrated), or violate any security or authentication measures. </li>
            </ul>
            <p>You expressly empowered us to perform any action to identify and investigate any violation of these Terms and/or of the Platform’s Terms & Conditions, unilaterally determine whether you have violated any provisions and take actions (without your prior consent or notice) such as, but not limited to:</p>
            <ul className="list-none ml-3">
              <li>- blocking and closing your ongoing activities;</li>
              <li>- freezing your activity and Tokens;</li>
              <li>- reporting the incident to the competent authorities;</li>
              <li>- publishing the alleged violations and actions that have been taken;</li>
              <li>- deleting any information, you published that are found to be violations.</li>
            </ul>
            <p>It is strictly forbidden to conduct any action and/or activity which:</p>
            <ul className="list-none ml-3">
              <li>- is prohibited by our Terms and Conditions, or may direct, or indirect violate its provisions;</li>
              <li>- is related to illegal activities or with the purpose of causing harm to another person, regardless the way in which this purpose is done or achieved.</li>
            </ul>

            <h3 className='text-bold text-gray-900 text-md'>14. Indemnification</h3>
            <p>You agree to indemnify and hold harmless us, our affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to (i) your use of, or conduct in connection with the Tokens, (ii) your breach of these Terms, or (iii) your violation of any applicable laws, regulation, or rights of any third party during your use of the Services. </p>
            <p>In such cases, we will have the right, in our sole discretion, to control any actions or proceedings and to determine whether we wish to settle, and if so, on what terms.</p>

            <h3 className='text-bold text-gray-900 text-md'>15. Warranty and Disclaimers</h3>
            <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE, ALONG WITH OUR PARENT COMPANIES, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, PARTNERS, LICENSORS AND DISTRIBUTORS DO NOT MAKE ANY REPRESENTATIONS, PROMISES, OR WARRANTIES, EXPRESS OR IMPLIED, ABOUT THE SERVICES. WE PROVIDE THE SERVICES “AS-IS,” “WITH ALL FAULTS,” AND “AS AVAILABLE”. YOUR USE OF THE SERVICES, INCLUDING OUR CONTENT WITHIN THE SERVICES (and excluding the content posted by users), IS AT YOUR OWN RISK AND WE DO NOT REPRESENT, PROMISE, OR WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. YOU UNDERSTAND AND AGREE THAT NO DATA TRANSMISSION OVER THE INTERNET OR INFORMATION STORAGE TECHNOLOGY CAN BE GUARANTEED TO BE SECURE, AND WE EXPRESSLY DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, TO THAT EFFECT. WE MAKE NO COMMITMENTS, PROMISES OR WARRANTIES ABOUT THE TOKENS OR CONTENT LINKED FROM THE SERVICES, THE SUPPORT WE PROVIDE FOR THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, THE SECURITY OF THE SERVICES, OR THE SERVICES’ RELIABILITY, QUALITY, ACCURACY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS, PROVIDE CERTAIN OUTPUTS OR ACHIEVE CERTAIN RESULTS.</p>
            <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE, ALONG WITH OUR PARENT COMPANIES, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, PARTNERS, LICENSORS AND DISTRIBUTORS DO NOT MAKE ANY REPRESENTATIONS, PROMISES, OR WARRANTIES, EXPRESS OR IMPLIED, ABOUT THE TOKENS (INCLUDING THEIR VALUE), THE SERVICES, OR ANY OTHER AVAILABLE FEATURE OR REGARDING THEIR PROFITABILITY, ACTUALLY, ACCURACY, USABILITY ETC. </p>
            <p>YOU UNDERSTAND AND ASSUME THAT ANY DECISION FOLLOWING OR IN CONNECTION WITH THE TOKENS, THE SERVICES, THE CONTENT AVAILABLE ON THE PLATFORM OR ANY OTHER AVAILABLE FEATURES IS BASED ON YOUR OWN RESEARCH AND RESPONSIBILITY. </p>
            <p>SOME JURISDICTIONS PROVIDE FOR CERTAIN IMPLIED WARRANTIES, SUCH AS THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ANY AND ALL IMPLIED OR EXPRESS PROMISES OR WARRANTIES ABOUT THE SERVICES.</p>

            <h3 className='text-bold text-gray-900 text-md'>16. Liability</h3>
            <p>To the fullest extent permitted by Applicable Law, you agree and understand that we will not be liable for: any indirect, special, incidental, consequential, treble or other multiples of damages, exemplary or punitive damages arising from or in connection with these Terms or your use of the Services, the Projects or the Tokens. </p>
            <p>We and our affiliated entities shall not be held liable for any loss of profits, revenues, business opportunities, diminution in value or any other losses (collectively “<b>Losses</b>”) arising from or in connection with these Terms or your use of or access to the Services/Tokens, including, but not limited to: </p>
            <ul className="list-none ml-3">
              <li>(i) the deletion of, alteration of, mis-delivery of or failure of the Tokens to other Electronic Wallets while using the Services;</li>
              <li>(ii) downloading or sharing of information, including personal information, during the use of our Services;</li>
              <li>(iii) the unauthorized access to your Electronic Wallet/s;</li>
              <li>(iv) services provided by third parties in connection with the Tokens;</li>
              <li>(v) your dealings with or participation in promotional campaigns of third - parties found while using our Services.</li>
            </ul>
            <p>Both the Founders and the Users recognize that MemeversX operates solely as an intermediary facilitating the interaction between Founders and Users for the publication and participation in the Projects. MemeversX does not create, endorse, or control the content of Projects listed on the Platform, nor does it provide investment advice or guarantee Project performance or Token value.</p>
            <p>MemeversX disclaims any responsibility and shall not be in any way liable for the actions, conduct, or performance of Founders or Users, as well as for the outcome of any transactions or engagements facilitated through the Platform.</p>
            <p>We will not be liable for damages caused by others, the wrongful or unlawful actions of third parties, or an act of God. The limitations and exclusions in these Terms will apply whether or not we have been advised of or should have been aware of the possibility of any losses arising.</p>
            <p>Without detracting from the general nature of the aforementioned, the Founders and Users acknowledge the existence of various risks associated with the Projects and their developments the Platform. </p>
            <p>To the fullest extent permitted by law and except as otherwise stated in these Terms, we are not liable in connection with any disputes that arise out of or relate to the Services, Projects and/or the Tokens.</p>

            <h3 className='text-bold text-gray-900 text-md'>17. We may make changes our Terms</h3>
            <p>We might change, add or remove parts of these Terms at any time and in our sole discretion. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
            <p>We will notify such changes by simply updating the terms on our Platform and modifying the [Last revised] date displayed on this page.</p>
            <p>All modifications or changes to these terms will become effective upon publication on the Platform or release to users.</p>
            <p>It is your responsibility to review the amended Terms. Your continued use of the Services following the changes to the Terms, you accept and agree to the changes, and that all subsequent activity performed by you will be subject to the amended Terms.</p>
            <p>If you have any question regarding or in connection of the information mentioned in these Terms, please do not hesitate to do directly contact us.</p>

            <h3 className='text-bold text-gray-900 text-md'>18. We may transfer this agreement to someone else</h3>
            <p>The transfer of rights and obligations arising from these Terms and / or the use of the Platform is subject to our prior written consent.</p>
            <p>You expressly agree that the we may unilaterally transfer all rights and obligations that may be stemming from these Terms without your consent.</p>

            <h3 className='text-bold text-gray-900 text-md'>19. Which country's laws apply to any disputes?</h3>
            <p>The relationship between the Founders and the Users and us and any disputes arising from the use of the Platform and/or its Services shall be governed by and construed in accordance with the Applicable Law, without giving effect to any principles of conflicts of law. Any legal action or proceeding related to this website shall be brought exclusively in the competent courts as per the Applicable Law.</p>
            <p>To the maximum extent permitted by the Applicable Law, any invalid or unenforceable term or provision of these Terms shall not affect the validity or enforceability of the remaining terms and provisions thereof or the validity or enforceability of the offending term or provision in any other situation. To the maximum extent permitted by the Applicable Law, you and us, acting in                 good-faith, shall attest the nullity or unenforceability of the respective term or provision and shall replace it by a valid or enforceable term or provision which most accurately reflects the economic and legal purpose of the invalid or unenforceable term or provision. </p>
          </div>
        </div>
        <div className='flex flex-row md:flex-col gap-y-3 justify-between items-center'>
          <div>
            <label htmlFor="termsCheckbox" className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="termsCheckbox"
                className="hidden"
                checked={termsAccepted}
                onChange={handleCheckboxChange}
              />
              <div className='border-[1px] border-primary-200 rounded-sm'>
                <div className={`w-5 h-5 border border-white rounded ${termsAccepted ? 'bg-primary-200' : ''}`}></div>
              </div>
              <span className="ml-2 text-sm text-gray-700">I accept the Terms & Conditions</span>
            </label>
          </div>
          <Button variant='flat' className='md:w-full' disabled={termsAccepted === false || isLoading} onClick={handleClickContinue}>
            {isLoading ? <FontAwesomeIcon icon={faSpinner} className='animate-spin duration-1000 px-7' /> : 'Continue to memeversx'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
