import { LaunchpadDetails as LaunchpadDetailsType, SaleStatus } from 'types';
import { LaunchpadSaleEnded } from './LaunchpadSaleEnded';
import { LaunchpadSaleClaiming } from './LaunchpadSaleClaiming';
import { LaunchpadSaleActive } from './LaunchpadSaleActive';
import { LaunchpadSaleScheduled } from './LaunchpadSaleScheduled';
import { LaunchpadSaleSoldOut } from './LaunchpadSaleSoldOut';

export interface LaunchpadSaleProps {
  launchpad: LaunchpadDetailsType;
}

export interface LaunchpadSaleWithRefetchProps extends LaunchpadSaleProps {
  refetchLaunchpad: (() => void) | undefined
}

export const LaunchpadSale = ({ launchpad, refetchLaunchpad }: LaunchpadSaleWithRefetchProps) => {
  switch (launchpad.status) {
    case SaleStatus.SCHEDULED:
      return <LaunchpadSaleScheduled launchpad={launchpad} refetchLaunchpad={refetchLaunchpad} />;
    case SaleStatus.ACTIVE:
      return <LaunchpadSaleActive launchpad={launchpad} refetchLaunchpad={refetchLaunchpad} />;
    case SaleStatus.SOLD_OUT:
      return <LaunchpadSaleSoldOut launchpad={launchpad} />;
    case SaleStatus.CLAIMING_PERIOD:
      return <LaunchpadSaleClaiming launchpad={launchpad} refetchLaunchpad={refetchLaunchpad} />;
    case SaleStatus.ENDED:
      return <LaunchpadSaleEnded launchpad={launchpad} />;
  }
};
