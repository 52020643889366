import { faArrowTrendUp, faCheckSquare, faHourglass, faSpinner, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SaleStatus } from 'types';

interface LaunchpadStatusProps {
  status: SaleStatus;
  swapsEnabled?: boolean;
}

export const LaunchpadStatus = ({ status, swapsEnabled }: LaunchpadStatusProps) => {

  const parseLaunchpadStatus = (status: SaleStatus) => {
    if (status === SaleStatus.CLAIMING_PERIOD && swapsEnabled === false) {
      return SaleStatus.SOLD_OUT;
    }

    return status;
  }

  switch (parseLaunchpadStatus(status)) {
    case SaleStatus.SCHEDULED:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-orange-400 bg-orange-100 border border-orange-400">
          <FontAwesomeIcon icon={faSpinner} className='text-orange-400' />
          <span className="uppercase">Upcoming</span>
        </div>
      );
    case SaleStatus.ACTIVE:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-white bg-green-400 border border-green-200">
          <FontAwesomeIcon icon={faSquare} className='text-[8px] text-white' />
          <span className="uppercase">Sale live</span>
        </div>
      );
    case SaleStatus.SOLD_OUT:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-green-600 bg-green-100 border border-green-400">
          <FontAwesomeIcon icon={faCheckSquare} className='text-green-600' />
          <span className="uppercase">Sold Out!</span>
        </div>
      );
    case SaleStatus.CLAIMING_PERIOD:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-white bg-green-400 border border-green-200">
          <FontAwesomeIcon icon={faArrowTrendUp} className='text-xs text-white' />
          <span className="uppercase  no-wrap">TRADING NOW</span>
        </div>
      );
    case SaleStatus.ENDED:
      return (
        <div className="px-4 py-2 flex gap-2 justify-center items-center text-gray-600 bg-gray-200 border border-gray-400">
          <FontAwesomeIcon icon={faHourglass} className='text-sm text-gray-600' />
          <span className="uppercase">EXPIRED</span>
        </div>
      );
    default:
      return (
        <div className="h-30 px-4 py-2 flex gap-2 justify-center items-center text-gray-400 bg-gray-100 border border-gray-400">
          <span className="uppercase"></span>
        </div>
      );
  }
};
