import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetEgldPrice, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import BigNumber from "bignumber.js";
import { Button, FormatAmount, Input, Slider } from "components";
import moment from "moment";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { CreateLaunchpadDTO, LaunchpadState, LaunchpadStepStatus, SaleInfoDTO } from "types";
import { SaleInfoDetails } from "../components/SaleInfoDetails";
import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { API_URL } from "config";

interface SaleInfoStepProps {
  launchpad: CreateLaunchpadDTO;
  onContinue: () => void;
}

const useUpdateLaunchpad = () => {
  const loginInfo = useGetLoginInfo();

  const patchLaunchpad = async ({ id, data, launchpad }: { id: string, data: SaleInfoDTO, launchpad: CreateLaunchpadDTO }): Promise<any> => {
    try {
      const payload = {
        step: LaunchpadState.WAITING_FOR_SALE_INFO,
        saleInfo: {
          ...data,
          tokenPrice: new BigNumber(data.tokenPrice).shiftedBy(18).toFixed(),
          maxTokensPerUser: new BigNumber(data.maxTokensPerUser).shiftedBy(launchpad.token.decimals).toFixed()
        }
      }
      const { data: response } = await axios.patch(`/v1/launchpads/create/${id}`, payload, {
        baseURL: API_URL, headers: {
          Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
        }
      });
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        throw axiosError.response.data;
      } else {
        throw { message: axiosError.message ?? 'An error occurred' };
      }
    }
  }

  const { data, error, isLoading, mutate } = useMutation<any, any, { id: string, data: SaleInfoDTO, launchpad: CreateLaunchpadDTO }>(patchLaunchpad);

  const updateLaunchpad = (id: string, data: SaleInfoDTO, launchpad: CreateLaunchpadDTO) => {
    mutate({ id, data, launchpad });
  }

  return { data, error, isLoading, updateLaunchpad };
}

export const SaleInfoStep = ({ launchpad, onContinue }: SaleInfoStepProps) => {
  const { price: egldPrice } = useGetEgldPrice();

  const [isEdited, setIsEdited] = useState(false);
  const [formValues, setFormValues] = useState<SaleInfoDTO>({
    tokenPrice: launchpad.saleInfo?.tokenPrice
      ? new BigNumber(launchpad.saleInfo.tokenPrice).shiftedBy(-18).toFixed()
      : '',
    maxTokensPerUser: launchpad.saleInfo?.maxTokensPerUser
      ? new BigNumber(launchpad.saleInfo.maxTokensPerUser).shiftedBy(-launchpad.token.decimals).toFixed()
      : '',
    saleStartTimestamp: launchpad.saleInfo?.saleStartTimestamp ?? moment().add(1, 'days').unix(),
    saleEndTimestamp: launchpad.saleInfo?.saleEndTimestamp ?? moment().add(8, 'days').unix(),
    launchpadPercentage: launchpad.saleInfo?.launchpadPercentage ?? 50
  });

  const { data, error, isLoading, updateLaunchpad } = useUpdateLaunchpad();
  const isPendingVerification = launchpad?.currentStepData?.status === LaunchpadStepStatus.PENDING_VERIFICATION;

  useEffect(() => {
    if (data) {
      onContinue();
    }
  }, [data]);

  const tokenPriceInUsd = useMemo(() => {
    if (!egldPrice) {
      return undefined;
    }

    return new BigNumber(formValues!.tokenPrice).multipliedBy(egldPrice).shiftedBy(18).toFixed();
  }, [egldPrice, formValues!.tokenPrice]);

  const tokensInEgldPerUser = useMemo(() => {
    if (!formValues.maxTokensPerUser || !formValues.tokenPrice) {
      return undefined;
    }

    return new BigNumber(formValues!.maxTokensPerUser).multipliedBy(formValues.tokenPrice).shiftedBy(18).toFixed();
  }, [formValues.maxTokensPerUser, formValues.tokenPrice]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);

    const { name, value } = e.target;

    let parsedValue: string | number = value;
    if (name === 'launchpadPercentage') {
      parsedValue = new BigNumber(value).toNumber();
    }
    if (name === 'saleStartTimestamp' || name === 'saleEndTimestamp') {
      parsedValue = moment(value).unix();
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: parsedValue
    }));
  }

  const handleIncrementMaxTokensPerUser = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      maxTokensPerUser: new BigNumber(prevValues.maxTokensPerUser === '' ? '0' : prevValues.maxTokensPerUser).plus(1).toFixed()
    }));
  };

  const handleDecrementMaxTokensPerUser = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      maxTokensPerUser: new BigNumber(prevValues.maxTokensPerUser).minus(1).toFixed()
    }));
  };

  const onSubmit = () => {
    if (!isEdited) {
      onContinue();
      return;
    }

    updateLaunchpad(launchpad.id, formValues, launchpad);
  };

  return (
    <div className='flex gap-12 py-12 text-gray-600 justify-between xl:max-w-4xl xl:mx-auto lg:flex-col'>
      <div className='flex flex-col gap-12'>
        <div className='flex flex-col gap-4 items-center'>
          <h1 className='text-2xl text-primary-200'>Token Sale Setup</h1>
          <p className='max-w-md text-center leading-4 text-md'>Optimize your token sale strategy! Set up the token price and the distribution of your token, both for the sale and for the liquidity.</p>
        </div>
        <div className='flex flex-col gap-4 mx-auto w-full max-w-md'>
          <div className="relative">
            <Input
              type='number'
              name='tokenPrice'
              label='Token price (in EGLD)'
              info='The listing price on MemeversX, fixed and unmodifiable.'
              placeholder='ex. 0.25'

              className={`h-fit pt-2 ${(tokenPriceInUsd && tokenPriceInUsd !== "NaN") ? 'pb-4' : 'pb-2'}`}
              value={formValues!.tokenPrice}
              onChange={handleInputChange}
              errors={error?.invalidProperties?.saleInfo?.tokenPrice}
              disabled={isLoading || isPendingVerification}
              min={0}
            />
            {tokenPriceInUsd && tokenPriceInUsd !== "NaN" && (
              <div className="absolute top-[63px] left-[22px] text-gray-400 text-sm">
                ~ $<FormatAmount value={tokenPriceInUsd} digits={4} egldLabel=" " showLastNonZeroDecimal={true} />
              </div>
            )}
          </div>
          <div className='flex gap-2'>
            <div className='flex-grow relative'>
              <Input
                type='text'
                name='maxTokensPerUser'
                label={`Max tokens per user (${launchpad.token.ticker})`}
                info='The maximum number of tokens a user can buy on MemeversX.'
                placeholder='ex. 1,000'
                className={`h-fit pt-2 ${tokensInEgldPerUser ? 'pb-4' : 'pb-2'}`}
                value={formValues!.maxTokensPerUser}
                onChange={handleInputChange}
                errors={error?.invalidProperties?.saleInfo?.maxTokensPerUser}
                disabled={isLoading || isPendingVerification}
                min={1}
              />
              <div>
                {tokensInEgldPerUser && (
                  <FormatAmount
                    className='absolute top-[63px] left-[22px] text-gray-400 text-sm'
                    value={parseFloat(tokensInEgldPerUser).toString()}
                    showLastNonZeroDecimal={true}
                    digits={0}
                  />
                )}
              </div>
            </div>
            <div className='flex gap-2 pt-8'>
              <Button
                variant='outline'
                className={`${tokensInEgldPerUser ? 'h-[52px] w-[52px]' : 'h-[40px] w-[40px]'}`}
                onClick={() => handleDecrementMaxTokensPerUser()}
                disabled={new BigNumber(formValues?.maxTokensPerUser ?? 0).isLessThanOrEqualTo(0) || isLoading || isPendingVerification || formValues?.maxTokensPerUser === ''}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
              <Button
                variant='outline'
                className={`${tokensInEgldPerUser ? 'h-[52px] w-[52px]' : 'h-[40px] w-[40px]'}`}
                onClick={() => handleIncrementMaxTokensPerUser()}
                disabled={isLoading || isPendingVerification}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
          <Slider
            name='launchpadPercentage'
            label='Launchpad Sale Allocation'
            placeholder='%'
            info='The percentage of the token supply that is available for sale on MemeversX. The rest will be used for liquidity.'
            value={formValues?.launchpadPercentage?.toString() ?? ''}
            onChange={handleInputChange}
            errors={error?.invalidProperties?.saleInfo?.launchpadPercentage}
            min={30}
            max={70}
            step={1}
            disabled={isLoading || isPendingVerification}
          />
          <div className='flex gap-2 sm:flex-col'>
            <div className='w-full'>
              <Input
                type='datetime-local'
                name='saleStartTimestamp'
                label='Sale Start Date'
                placeholder='Select date...'
                value={moment(formValues!.saleStartTimestamp * 1000).format('YYYY-MM-DDTHH:mm')}
                onChange={handleInputChange}
                errors={error?.invalidProperties?.saleInfo?.saleStartTimestamp}
                disabled={isLoading || isPendingVerification}
              />
            </div>
            <div className='w-full'>
              <Input
                type='datetime-local'
                name='saleEndTimestamp'
                label='Sale End Date'
                placeholder='Select date...'
                value={moment(formValues!.saleEndTimestamp * 1000).format('YYYY-MM-DDTHH:mm')}
                onChange={handleInputChange}
                errors={error?.invalidProperties?.saleInfo?.saleEndTimestamp}
                disabled={isLoading || isPendingVerification}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center gap-2'>
          <Button
            className='min-w-[170px]'
            disabled={isLoading || isPendingVerification}
            onClick={onSubmit}
          >
            {isLoading ? 'Loading..' : 'Save & Continue'}
          </Button>
          {error?.message && (<p className='text-xs text-status-danger'>{error.message}</p>)}
          {isPendingVerification && (<p className='text-xs text-orange-600'>Token branding verification in progress.</p>)}
        </div>
      </div>
      <div className='flex flex-col gap-12 max-w-md mx-auto'>
        <SaleInfoDetails
          launchpad={launchpad}
          launchpadPercentage={formValues?.launchpadPercentage ?? 0}
          tokenPrice={formValues?.tokenPrice ?? '0'}
          tokenPriceInUsd={tokenPriceInUsd}
        />
      </div>
    </div>
  );
}