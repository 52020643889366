import { Transaction } from '@multiversx/sdk-core/out';
import axios, { AxiosError } from 'axios';
import { API_URL } from 'config';
import { useGetLoginInfo } from 'hooks';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

const publishCreateLaunchpad = async (id: string, setAxiosError: (setAxiosError: any) => void, setTransactionPlain: (setTransactionPlain: any) => void, nativeAuthToken?: string): Promise<string> => {

  try {
    const { data } = await axios.post(`/v1/launchpads/create/${id}/publish`, {}, {
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${nativeAuthToken}`
      }
    });
    setTransactionPlain(data);
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.data) {

      setAxiosError(axiosError.response.data)
    }
    console.error('Error:', error);
    throw error;
  }
};

export const usePublishCreateLaunchpad = () => {
  const queryClient = useQueryClient();
  const [axiosError, setAxiosError] = useState<any | undefined>(undefined)
  const [transactionPlain, setTransactionPlain] = useState<any | undefined>(undefined)
  const loginInfo = useGetLoginInfo();

  const mutation = useMutation<string, any, string>(
    (id) => publishCreateLaunchpad(id, setAxiosError, setTransactionPlain, loginInfo.tokenLogin?.nativeAuthToken),
    {
      onSuccess: () => {
        setAxiosError(undefined);
        queryClient.invalidateQueries();
      },
    }
  );

  const startCreateLaunchpad = (id: string) => {
    mutation.mutate(id);
  };

  return { startCreateLaunchpad, axiosError, transactionPlain, setTransactionPlain, ...mutation };
};
