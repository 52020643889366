import moment from 'moment';
import React, { useState, useEffect } from 'react';

interface DailyQuestsCountdownProps {
 nextAvailabilityTimestamp: number;
 refetch: (() => Promise<any>) | (() => void) | undefined;
}

export enum DailyQuestsCountdownStatesEnum {
 AVAILABLE = 'AVAILABLE'
}

const DailyQuestsCountdown: React.FC<DailyQuestsCountdownProps> = ({ nextAvailabilityTimestamp, refetch }) => {
 const [countdown, setCountdown] = useState<string>('');

 useEffect(() => {
  const interval = setInterval(() => {
   const currentTime = new Date().getTime();
   const timeDifference = nextAvailabilityTimestamp - currentTime;

   if (timeDifference <= 0) {
    setCountdown(DailyQuestsCountdownStatesEnum.AVAILABLE);
    clearInterval(interval);
    refetch?.();
   } else {
    const duration = moment.duration(timeDifference);
    setCountdown(`Refreshes in ${duration.humanize()}`);
   }
  }, 1000);

  return () => clearInterval(interval);
 }, [nextAvailabilityTimestamp]);

 if (countdown === DailyQuestsCountdownStatesEnum.AVAILABLE) {
  return null
 }

 return <span className='text-sm text-gray-400 text-center'>{countdown}</span>;
};

export default DailyQuestsCountdown;
