import { faBoxOpen, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionOrConnect,
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  ErrorState,
  Input,
  LoadingState,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components';
import Autoplay from 'embla-carousel-autoplay';
import { RouteNamesEnum } from 'localConstants';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Launchpad, SaleStatus } from 'types';
import { useGetCommunityLaunchpads, useGetHotLaunchpads, useGetLaunchpads, useGetUpcomingLaunchpads } from '../hooks';
import { LaunchpadsListItem } from './LaunchpadsListItem';
import Pagination from './Pagination';
import useSearchParam from '../hooks/useSearchParam';

export const LaunchpadsCarousel = ({ title, emoji, launchpads }: { title: string; emoji: string; launchpads: Launchpad[] }) => {
  return (
    <div className='container mx-auto lg:max-w-3xl mb-24'>
      <Carousel
        opts={{
          align: 'start'
        }}
        plugins={[
          Autoplay({
            delay: 8000
          })
        ]}
      >
        <div className='flex justify-between items-center mb-10'>
          <h3 className='text-description justify-start lg:text-lg flex gap-4 md:justify-center md:w-full md:text-md'>
            {title}
            <span className='font-mono'>{emoji}</span>
          </h3>
          <div className='flex items-center gap-3 md:hidden'>
            <CarouselPrevious>Prev</CarouselPrevious>
            <CarouselNext>Next</CarouselNext>
          </div>
        </div>
        <CarouselContent>
          {launchpads.map((launchpad) => (
            <CarouselItem key={launchpad.id} className='basis-[calc(50%-0.375rem)] lg:basis-full mr-3 md:basis-[calc(100%-2rem)]'>
              <LaunchpadsListItem launchpad={launchpad} />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export const LaunchpadsList = () => {
  const [currentTab, setCurrentTab] = useState<'all' | 'my'>('all');
  const [searchBoxInput, setSearchBoxInput] = useState('');
  const [filterByName, setFilterByName] = useSearchParam({
    key: 'name',
    defaultValue: undefined,
  });
  const [currentPageFromParams, setCurrentPage] = useSearchParam<string>({
    key: 'page',
    defaultValue: '1',
  });
  const [filterByStatus, setFilterByStatus] = useSearchParam<string>({
    key: 'status',
    defaultValue: 'All',
  });
  const [sortBy, setSortBy] = useSearchParam<string>({
    key: 'sort',
    defaultValue: 'startDate',
  });

  const currentPage = parseFloat(currentPageFromParams);

  const {
    data,
    filteredData: launchpads,
    isLoading,
    error
  } = useGetLaunchpads({
    tab: currentTab,
    page: currentPage,
    filter: {
      name: filterByName,
      status: filterByStatus
    },
    sortBy: sortBy === '' ? 'endDate' : sortBy
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page.toString());
  };

  const { data: hotLaunchpads } = useGetHotLaunchpads();
  const { data: upcomingLaunchpads } = useGetUpcomingLaunchpads();
  const { data: communityLaunchpads } = useGetCommunityLaunchpads();

  const renderLaunchpads = () => {
    if (isLoading) {
      return (
        <div className='container mx-auto lg:max-w-3xl'>
          <div className='flex justify-center py-20'>
            <LoadingState />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className='container mx-auto lg:max-w-3xl'>
          <div className='flex justify-center py-20'>
            <ErrorState />
          </div>
        </div>
      );
    }

    if ((!launchpads || launchpads?.length === 0) && (filterByStatus === '' && filterByName === '')) {
      return (
        <div className='container mx-auto lg:max-w-3xl'>
          <div className='flex justify-center py-20'>
            <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
              <div className='text-md text-gray-800 bg-gray-400 w-12 h-12 shadow-sm shadow-gray-800 flex items-center justify-center'>
                <FontAwesomeIcon icon={faBoxOpen} />
              </div>
              <div className='text-center'>
                <p className='text-gray-900 text-lg'>No launchpads</p>
                <p className='text-gray-600'>There are no launchpads available at the moment.</p>
              </div>
              <ActionOrConnect
                defaultComponent={
                  <Button variant='default' className='min-w-[200px]'>
                    Create launchpad
                  </Button>
                }
                connectedComponent={
                  <Link to={RouteNamesEnum.createdLaunchpads}>
                    <Button variant='default' className='min-w-[200px]'>
                      Create launchpad
                    </Button>
                  </Link>
                }
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        {filterByName === '' && hotLaunchpads && hotLaunchpads.length > 0 && <LaunchpadsCarousel title='HOT RIGHT NOW' emoji='🔥' launchpads={hotLaunchpads?.filter(l => l.slug !== 'sms')} />}
        {filterByName === '' && communityLaunchpads && communityLaunchpads.length > 0 && <LaunchpadsCarousel title='COMMUNITY HIGHLIGHTS' emoji='🔥' launchpads={communityLaunchpads} />}
        {filterByName === '' && upcomingLaunchpads && upcomingLaunchpads.length > 0 && <LaunchpadsCarousel title='UPCOMING MEMES' emoji='⏰' launchpads={upcomingLaunchpads} />}
        <div className='container mx-auto lg:max-w-3xl'>
          <div className='flex flex-col gap-20'>
            <div className='flex flex-col gap-10'>
              <div className='flex flex-row md:flex-col gap-y-3 justify-between items-center'>
                <h3 className='text-description justify-start lg:text-lg flex gap-4 md:justify-center md:w-full md:text-md'>
                  ALL LAUNCHPADS<span className='font-mono'>🚀</span>
                </h3>
                <div className='flex flex-col min-w-[180px] md:w-full sm:min-w-fit'>
                  <Select value={filterByStatus} onValueChange={(value) => setFilterByStatus(value)}>
                    <SelectTrigger>
                      <SelectValue placeholder='Status' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='All'>All</SelectItem>
                      <SelectItem value='Scheduled'>Upcoming</SelectItem>
                      <SelectItem value='Active'>Sale Live</SelectItem>
                      <SelectItem value='SoldOut'>Sold Out</SelectItem>
                      <SelectItem value='ClaimingPeriod'>Trading Now</SelectItem>
                      <SelectItem value='Ended'>Expired</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className='grid gap-6 grid-cols-2 lg:grid-cols-1 pb-4'>
                {launchpads && launchpads.length === 0 && (filterByStatus !== '' || filterByName !== '') && (
                  <div className='container mx-auto lg:max-w-3xl col-span-2'>
                    <div className='flex justify-center py-20'>
                      <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
                        <div className='text-md text-gray-800 bg-gray-400 w-12 h-12 shadow-sm shadow-gray-800 flex items-center justify-center'>
                          <FontAwesomeIcon icon={faBoxOpen} />
                        </div>
                        <div className='text-center'>
                          <p className='text-gray-900 text-lg'>No launchpads</p>
                          <p className='text-gray-600'>There are no launchpads that match your search criteria.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {launchpads?.map((launchpad) => {
                  return <LaunchpadsListItem key={launchpad.id} launchpad={launchpad} />;
                })}
              </div>
              <div>
                <Pagination onPageChange={handlePageChange} totalCount={data?.count || 0} currentPage={currentPage} pageSize={10} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='bg-gradient-start mb-20 overflow-hidden'>
      <div className='container mx-auto lg:max-w-3xl'>
        <div className='py-12'>
          <h2 className='text-title text-center lg:text-[48px] sm:text-2xl'>Explore Launchpads</h2>
        </div>
        <div className='flex gap-3 mb-8 sm:flex-col-reverse items-center'>
          <div className='flex flex-col min-w-[90pxs] md:w-full md:min-w-fit'>
            <Select value={sortBy} onValueChange={(value) => setSortBy(value)}>
              <SelectTrigger>
                <SelectValue placeholder='Sort by' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='startDate'>Start time</SelectItem>
                <SelectItem value='endDate'>End Time</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className='flex flex-row grow items-center md:w-full md:min-w-fit'>
            <div className='grow flex flex-col justify-end '>
              <Input type='text' name='filterByName' value={searchBoxInput} placeholder='Search token name or ticker' onChange={(e) => setSearchBoxInput(e.target.value)} className='h-fit py-3 px-6' />
            </div>
            <Button variant='flat' size='icon' className='' onClick={() => setFilterByName(searchBoxInput)}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </div>

        </div>
      </div>
      {renderLaunchpads()}
    </div >
  );
};
