import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { LaunchpadSaleWithRefetchProps } from "./LaunchpadSale";
import Countdown from "react-countdown";
import { Button, Card, LaunchpadStatus } from "components";
import { countdownRenderer } from "./countdownRenderer";
import { PriceInfo } from "./PriceInfo";
import { LaunchpadDetails } from "types";
import { Link } from "react-router-dom";
import moment from "moment";
import { useGetAccount } from "@multiversx/sdk-dapp/hooks";

const buildGoogleCalendarEventUrl = (launchpad: LaunchpadDetails) => {
  const launchpadUrl = `${window.location.origin}/${launchpad.slug}`;
  const launchpadLaunchStartDate = moment(launchpad.startTimestamp * 1000);
  const launchpadLaunchEndDate = moment(launchpadLaunchStartDate).add(15, 'minutes');

  const url = new URL('http://www.google.com/calendar/event');
  url.searchParams.append('action', 'TEMPLATE');
  url.searchParams.append('dates', `${launchpadLaunchStartDate.format('YYYYMMDDTHHmmss')}/${launchpadLaunchEndDate.format('YYYYMMDDTHHmmss')}`);
  url.searchParams.append('text', `${launchpad.token.name} launch`);
  url.searchParams.append('location', launchpadUrl);
  url.searchParams.append('details', `Description`); // TODO: add description

  return url.toString();
}

export const LaunchpadSaleScheduled = ({ launchpad, refetchLaunchpad }: LaunchpadSaleWithRefetchProps) => {
  const queryClient = useQueryClient();
  const startTime = launchpad.startTimestamp * 1000 + 6000;

  const { address } = useGetAccount();

  const googleCalendarEventUrl = buildGoogleCalendarEventUrl(launchpad);

  useEffect(() => {
    const timeToRefresh = new Date(startTime).getTime() - Date.now();

    const interval = setInterval(() => {
      if (timeToRefresh < 0) {
        return;
      }

      refetchLaunchpad?.()
      queryClient.invalidateQueries();
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <div className="flex justify-between items-start">
        <div className="border border-gray-200 px-4 py-2">Fair Launch</div>
        <LaunchpadStatus status={launchpad.status} swapsEnabled={launchpad.swapsEnabled} />
      </div>
      <div className='flex flex-col text-center  items-center'>
        <p className="text-xl text-gray-900">Sale Starts In:</p>
        <Countdown date={new Date(startTime)} renderer={countdownRenderer} />
      </div>
      <PriceInfo launchpad={launchpad} />
      <Link to={googleCalendarEventUrl} target="_blank" rel="noopener noreferrer">
        <Button className="w-full">Set reminder</Button>
      </Link>
    </Card>
  );
};
