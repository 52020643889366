import React from 'react';
import { usePagination } from '../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components';

type PaginationProps = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
};

const Pagination: React.FC<PaginationProps> = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`flex items-center justify-center mt-4 gap-3 ${className}`}>
      <li>
        <Button variant='outline' size='icon' onClick={onPrevious} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === -1) {
          return (
            <li key={pageNumber} className='text-lg text-gray-500 tracking-wide'>
              ...
            </li>
          );
        }

        return (
          <li key={pageNumber}>
            <Button
              className={`bg-red ${pageNumber === currentPage ? 'bg-primary-200 text-white' : 'text-primary-200'}`}
              variant={pageNumber === currentPage ? 'outline' : 'flat'}
              size='icon'
              onClick={() => {
                onPageChange(pageNumber);
              }}
            >
              {pageNumber}
            </Button>
          </li>
        );
      })}
      <li>
        <Button variant='outline' size='icon' onClick={onNext} disabled={currentPage === lastPage}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </li>
    </ul>
  );
};

export default Pagination;
