import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { PieChart } from 'react-minimal-pie-chart';
import { CreateLaunchpadDTO } from 'types';
import { computeListingPrice } from 'utils';
import { countDecimalPlaces } from 'utils/countDecimalPlaces';

interface SaleInfoDetailsProps {
  launchpad: CreateLaunchpadDTO;
  launchpadPercentage: number;
  tokenPrice: string;
  tokenPriceInUsd?: string;
}

export const SaleInfoDetails = ({ launchpad, launchpadPercentage, tokenPrice, tokenPriceInUsd }: SaleInfoDetailsProps) => {
  const showTokenPrice = new BigNumber(tokenPrice).isGreaterThan(0);

  const launchpadTokens = new BigNumber(launchpad.token.supply).multipliedBy(launchpadPercentage).dividedBy(100).toFixed();
  const launchpadAmountInEgld = new BigNumber(launchpadTokens).multipliedBy(tokenPrice);

  const listingTokenPrice = computeListingPrice(launchpadAmountInEgld, launchpadPercentage, launchpad.token.supply);

  const exchangeRateRaw = new BigNumber(1).dividedBy(tokenPrice);
  const exchangeRate = exchangeRateRaw.isLessThan(100) ? exchangeRateRaw.toFixed(6) : exchangeRateRaw.integerValue().toFixed();

  return (
    <div className='flex flex-col gap-8'>
      <PieChart
        className='w-[300px]'
        data={[
          { title: 'Sale', value: launchpadPercentage, color: '#038175' },
          { title: 'Liquidity', value: 100 - launchpadPercentage, color: '#E9ECEF' },
        ]}
        label={({ x, y, dx, dy, dataEntry }) => (
          <text
            x={x}
            y={y}
            dx={dx}
            dy={dy}
            dominant-baseline="central"
            text-anchor="middle"
            style={{
              fontSize: '0.35rem',
            }}
          >
            {dataEntry.percentage.toFixed(2) + '%\n' + dataEntry.title}
          </text>
        )}
      />
      <div className='flex flex-col gap-1 w-full'>
        <p className='flex justify-between'>
          Total token supply:
          <span className='text-gray-900 underline'><FormatAmount value={launchpad.token.supply} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.token.supply, launchpad.token.decimals)} /></span>
        </p>
        <p className='flex justify-between'>
          Tokens for sale:
          <span className='text-gray-900 underline'><FormatAmount value={launchpadTokens} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpadTokens, launchpad.token.decimals)} /></span>
        </p>
        {showTokenPrice && (
          <>
            <p className='flex justify-between'>
              Sale price:
              <span className='text-gray-900 underline'>1 EGLD = {exchangeRate} {launchpad.token.ticker}</span>
            </p>
            {tokenPriceInUsd && (
              <p className='flex justify-between'>
                Token price:
                <span className='text-gray-900 underline'>~ $<FormatAmount value={tokenPriceInUsd} digits={4} egldLabel=" " showLastNonZeroDecimal={true} /></span>
              </p>
            )}
            <p className='flex justify-between'>
              Capital to raise:
              <span className='text-gray-900 underline'> <FormatAmount value={launchpadAmountInEgld.toFixed()} digits={countDecimalPlaces(launchpadAmountInEgld.toFixed(), launchpad.token.decimals)} /></span>
            </p>
            <p className='flex justify-between'>
              Listing price:
              <span className='text-gray-900 underline'>1 EGLD ≈ {listingTokenPrice} {launchpad.token.ticker}</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
