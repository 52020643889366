import { LaunchpadIcon, LaunchpadUrls } from 'components';
import { LaunchpadDetails as LaunchpadDetailsType } from 'types';

interface LaunchpadDetailsProps {
  launchpad: LaunchpadDetailsType;
}

export const LaunchpadDetails = ({ launchpad }: LaunchpadDetailsProps) => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex justify-between gap-4'>
        <div className='flex gap-4'>
          <LaunchpadIcon icon={launchpad.token.assets?.pngUrl ?? launchpad.token.assets?.svgUrl} />
          <div>
            <h1 className="text-primary-200 text-2xl leading-9">{launchpad.token.ticker}</h1>
            <h2 className="text-gray-900 text-xl leading-6">{launchpad.token.name}</h2>
          </div>
        </div>
        <LaunchpadUrls urls={launchpad.urls} />
      </div>
      <p className='text-gray-600 mb-4'>{launchpad.description}</p>
    </div>
  );
};
