import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, Info } from 'components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CreateLaunchpadDTO } from 'types';


interface SuccessPublishModalProps {
  launchpad: CreateLaunchpadDTO;
}

const SuccessPublishModal = ({ launchpad }: SuccessPublishModalProps) => {
  const launchpadUrl = `${window.location.origin}/${launchpad.slug}`;
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const onCopyLink = () => {
    navigator.clipboard.writeText(launchpadUrl);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 2000);
  }

  const navigateToLaunchpad = () => {
    window.location.href = launchpadUrl;
  }

  return (
    <Dialog open={true}>
      <DialogContent className='max-w-md' hideCloseButton={true}>
        <div className='flex flex-col gap-2 py-5 px-6 text-center items-center pb-0'>
          <div className="text-md text-teal-500 bg-teal-300 w-12 h-12 shadow-sm shadow-teal-500 flex items-center justify-center">
            <FontAwesomeIcon icon={faRocket} />
          </div>
          <h3 className='text-gray-900 text-lg'>Congratulations!</h3>
          <p className='text-gray-600'>Your token is published on the Launchpad and ready for public sale!</p>
          <p className='text-gray-600'>Share it with the world by using the following link:</p>
          <p className='text-primary-200 px-4 py-2 border w-full'>{launchpadUrl}</p>
        </div>
        <DialogFooter className='flex flex-col gap-4'>
          <div className="flex gap-2">
            <div className="group relative w-full">
              <Button variant='outline' className="w-full" onClick={onCopyLink}>Copy link</Button>
              <div className={`absolute scale-0  flex flex-col items-center left-[calc(50%-60px)] top-[35px] ${isLinkCopied ? 'scale-100' : ''}`}>
                <div className="w-0 h-0 border-[16px] border-b-gray-900 border-transparent">
                </div>
                <div className="p-2 min-w-[120px] shadow-sm bg-gray-900 text-white text-center">
                  Link copied!
                </div>
              </div>
            </div>
            <Link to={launchpadUrl} className="w-full">
              <Button variant='default' className="w-full" onClick={navigateToLaunchpad}>Go to launchpad</Button>
            </Link>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog >
  )
};

export default SuccessPublishModal;
