interface LaunchpadIconProps {
  icon: string | undefined;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
}

export const LaunchpadIcon = ({ icon, size, className }: LaunchpadIconProps) => {
  const classSize = {
    xs: 'h-4 w-4',
    sm: 'h-8 w-8',
    md: 'h-16 w-16',
    lg: 'h-24 w-24',
  };

  if (icon === 'EGLD') {
    icon = '/img/egld-logo.png';
  }

  return (
    <img src={icon ?? '/img/default-token.png'} alt="" className={`${classSize[size ?? 'md']} rounded-full ${className}`} />
  );
};
