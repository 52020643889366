export interface QuestDTO {
 id: string;
 type: string;
 title: string;
 karma: number;
 url: string;
 completed: boolean
 nextAvailabilityTimestamp?: number;
 twitterQuest: boolean;
}

export enum QuestTypeEnum {
 TELEGRAM = 'JOIN_TELEGRAM',
 DISCORD = 'JOIN_DISCORD',
 SWAP = 'SWAP',
 ADD_LIQUIDITY = 'ADD_LIQUIDITY',
 JOIN_BOBER_TELEGRAM = 'JOIN_BOBER_TELEGRAM',
 JOIN_MVX_TELEGRAM = 'JOIN_MVX_TELEGRAM',
 CREATE_LAUNCHPAD = 'CREATE_LAUNCHPAD'
}

export interface UserStatusDTO {
 karma: number;
 karmaFromReferrals: number;
 referredUsers: number;
 rank: number;
 referralCode: string;
 termsAndConditions: boolean;
}

export interface LeaderboardUserDTO {
 rank: number;
 address: string;
 karma: number;
}