"use client"

import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"

import { cn } from "utils/cn"
import { Input } from "./Input"
import { Info } from "./Info"

export interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  info?: string;
  errors?: string[];
}


const Slider = React.forwardRef<HTMLInputElement, SliderProps>(({ className, label, info, errors, ...props }, ref) => {
  const isInvalidInput = errors && errors.length > 0;

  const renderLines = () => {
    return (
      <div className="flex justify-between px-1">
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label className="text-gray-600 flex flex-row  gap-2">
          {label}
          {info && <Info>{info}</Info>}
        </label>
      )}
      <div className="flex gap-2">
        <Input
          type='number'
          {...props}
          className={`w-20 px-4 text-center h-full ${isInvalidInput ? 'border-status-danger' : ''}`}
        />
        <div className="flex-grow flex flex-col gap-2">
          {renderLines()}
          <Input
            type='range'
            {...props}
            className="swadow"
          />
          {renderLines()}
        </div>
      </div>
      {isInvalidInput && <>
        {errors.map(err => (
          <p className="text-xs text-status-danger" key={err}>{err}</p>
        ))}
      </>}
    </div>
  );
})
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
