import { FormatAmount, LaunchpadIcon } from "components";
import { LaunchpadSaleProps } from "./LaunchpadSale";
import { useGetAccount, useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";
import { countDecimalPlaces } from "utils/countDecimalPlaces";

export const LaunchpadBalanceInfo = ({ launchpad }: LaunchpadSaleProps) => {
  const isLoggedIn = useGetIsLoggedIn();
  const { balance } = useGetAccount();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className="flex flex-col bg-gray-200 gap-3 p-3">
      <div className="flex justify-between">
        <div>
          <span className="flex gap-1 items-center justify-start">
            <LaunchpadIcon icon='EGLD' size="xs" />
            My EGLD balance:
          </span>
        </div>
        <div className="flex flex-col items-end">
          <FormatAmount value={balance} className="text-gray-900" />
          {/* // TODO */}
          {/* <span className="text-sm">~ $1,253.54</span> */}
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          <span className="flex gap-1 items-center justify-start">
            <LaunchpadIcon icon={launchpad.token.assets?.pngUrl ?? launchpad.token.assets?.svgUrl} size="xs" />
            My {launchpad.token.ticker} allocation:
          </span>
        </div>
        <div className="flex flex-col items-end">
          <FormatAmount value={launchpad.tokensBoughtByUser} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.tokensBoughtByUser, launchpad.token.decimals)} className="text-gray-900" />
          {/* // TODO */}
          {/* <span className="text-sm">~ $1,253.54</span> */}
        </div>
      </div>
    </div>
  );
};
