import { useGetAccount, useGetActiveTransactionsStatus, useGetIsLoggedIn, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import { API_URL } from 'config';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { LaunchpadDetails } from 'types';

const fetchLaunchpad = async (slug: string, includeCacheControl: boolean, nativeAuthToken?: string) => {
  try {
    type AxiosHeaders = AxiosRequestConfig['headers'];

    const headers: AxiosHeaders = {
      'Cache-Control': includeCacheControl ? 'no-cache' : '-',
      ...(nativeAuthToken && { Authorization: `Bearer ${nativeAuthToken}` })
    };
    const { data } = await axios.get(
      `/v1/launchpads/${slug}`,
      { baseURL: API_URL, headers: headers }
    );
    return data as LaunchpadDetails;
  } catch (error) {
    throw error;
  }
};

export const useGetLaunchpad = (slug: string): { data?: LaunchpadDetails; isLoading?: boolean; error?: any; refetch?: () => void } => {
  const { address } = useGetAccount();

  const { success } = useGetActiveTransactionsStatus();
  const loginInfo = useGetLoginInfo();
  const isLoggedIn = useGetIsLoggedIn();


  const { data, isLoading, error, refetch } = useQuery({
    queryKey: `launchpad:${slug}:${address}:${loginInfo.tokenLogin?.nativeAuthToken}`,
    queryFn: () => fetchLaunchpad(slug, success, loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: 1000 * 18, // 18 seconds (3 blocks)
    enabled: loginInfo.tokenLogin?.nativeAuthToken !== undefined && isLoggedIn || !isLoggedIn
  });

  useEffect(() => {
    if (success && loginInfo?.tokenLogin?.nativeAuthToken) {
      refetch();
    }
  }, [success, loginInfo?.tokenLogin?.nativeAuthToken]);

  return { data, isLoading, error, refetch };
};
