import { useState, useEffect, useCallback } from 'react';
import { useGetAccount } from './index';
import { ONE_HOUR_MS } from '../localConstants/durations';
import { useCheckIfUserHasAirdrop } from '../pages/Home/hooks';

interface UseClaimAirdropModalReturn {
  showModal: boolean;
  eligible: boolean;
  onCloseModal: () => void;
  onShowModal: () => void;
}

const getLocalStorageKey = (address: string) => `lastAirdropAccess_${address}`;

export const useClaimAirdropModal = (): UseClaimAirdropModalReturn => {
  const { address } = useGetAccount();
  const { data: sft } = useCheckIfUserHasAirdrop(address);
  const eligible = sft != null;

  const [showModal, setShowModal] = useState(false);

  const shouldShowModal = () => {
    const lastAccess = localStorage.getItem(getLocalStorageKey(address));
    const now = Date.now();

    return !lastAccess || now - parseInt(lastAccess, 10) > ONE_HOUR_MS;
  };

  useEffect(() => {
    if (eligible && shouldShowModal()) {
      setShowModal(true);
      localStorage.setItem(getLocalStorageKey(address), Date.now().toString());
    }
  }, [eligible, address]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleShowModal = useCallback(() => setShowModal(true), []);

  return {
    showModal,
    eligible,
    onCloseModal: handleCloseModal,
    onShowModal: handleShowModal
  };
};
