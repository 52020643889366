import { SignedTransactionType } from '@multiversx/sdk-dapp/types';
import { isServerTransactionPending } from '@multiversx/sdk-dapp/utils';
import React, { useMemo, ReactNode } from 'react';
import { TransactionDetailsBodyPropsType, TransactionDetailsBodyComponent } from './TransactionDetailsBody';

export interface TransactionDetailsType {
  title?: ReactNode;
  isTimedOut?: boolean;
  transactions?: SignedTransactionType[];
  className?: string;
}

export const TransactionDetailsComponent = ({ title, transactions, isTimedOut = false, className = 'dapp-transaction-details' }: TransactionDetailsType) => {
  if (transactions == null) {
    return null;
  }

  return (
    <>
      {title && <div>{title}</div>}

      {transactions.map(({ hash, status }) => {
        const transactionDetailsBodyProps: TransactionDetailsBodyPropsType = {
          className,
          hash,
          status,
          isTimedOut
        };

        return <TransactionDetailsBodyComponent {...transactionDetailsBodyProps} key={hash} />;
      })}
    </>
  );
};
