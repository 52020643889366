import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faDiscord, faTelegram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import moment from "moment";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className='border-t-[2px] lg:mb-16'>
      <div className='container mx-auto flex justify-between items-center py-6 sm:flex-col sm:gap-6'>
        <img src="/img/logo.svg" alt="" className='h-8 w-auto' />
        <p className="text-gray-600 text-center sm:hidden">© Copyright MemeversX {moment().year()}. All rights reserved.</p>
        <div className="flex gap-3">
          <Link to="https://x.com/memevers_x" target="_blank">
            <Button size='icon-sm' variant='outline' className='text-[#212529] border-[#212529] hover:bg-[#212529]'>
              <FontAwesomeIcon icon={faXTwitter as IconProp} />
            </Button>
          </Link>
          <Link to="https://t.me/memeversx" target="_blank">
            <Button size='icon-sm' variant='outline' className='text-[#087990] border-[#087990] hover:bg-[#087990]'>
              <FontAwesomeIcon icon={faTelegram as IconProp} />
            </Button>
          </Link>
          {/* <Link to="https://discord.gg/T8zzdgd7sp" target="_blank">
            <Button size='icon-sm' variant='outline' className='text-[#520DC2] border-[#520DC2] hover:bg-[#520DC2]'>
              <FontAwesomeIcon icon={faDiscord} />
            </Button>
          </Link> */}
        </div>
        <p className="text-gray-600 text-center hidden sm:block">Copyright MemeversX {moment().year()}. <br /> All rights reserved.</p>
      </div>
    </footer>
  );
};
