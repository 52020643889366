import { useEffect, useState, type PropsWithChildren } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import { TermsAndConditionsModal } from 'components/TermsAndConditionsModal';
import { useGetUserStatus } from 'hooks';
export const Layout = ({ children }: PropsWithChildren) => {
  const { data, isLoading, refetch } = useGetUserStatus();
  const [showTAndCModal, setShowTAndCModal] = useState(false)

  useEffect(() => {
    if (data) {
      setShowTAndCModal(!data.termsAndConditions)
    }
  }, [data, data?.termsAndConditions])


  return (
    <div className='flex min-h-screen flex-col bg-dark-100'>
      <Header userStatusData={data} isLoading={isLoading} />
      <main className='flex-grow mt-20'>
        {/* // TODO: investigate empty main*/}
        {/* <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${RouteNamesEnum.home}${search}`}
        > */}
        {children}
        {/* </AuthenticatedRoutesWrapper> */}
      </main>
      <Footer />
      <TermsAndConditionsModal show={showTAndCModal} onHide={() => setShowTAndCModal(false)} refetchUserStatus={refetch} />
    </div>
  );
};
