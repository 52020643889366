import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import { useGetNetworkConfig } from 'hooks';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { LaunchpadDetails } from 'types';
import { countDecimalPlaces } from 'utils/countDecimalPlaces';

interface LaunchpadStatsProps {
  launchpad: LaunchpadDetails;
}

export const LaunchpadStats = ({ launchpad }: LaunchpadStatsProps) => {
  const { network } = useGetNetworkConfig();

  return (
    <div className='flex flex-col gap-6 p-6 border border-gray-400'>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Total supply:
        <span className='text-gray-900'>
          <FormatAmount value={launchpad.tokenSupply} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.maxTokensPerUser, launchpad.token.decimals)} />
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Max tokens / wallet:
        <span className='text-gray-900'>
          <FormatAmount value={launchpad.maxTokensPerUser} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.maxTokensPerUser, launchpad.token.decimals)} />
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Tokens to sell:
        <span className='text-gray-900'>
          <FormatAmount value={launchpad.maxTokensToSell} decimals={launchpad.token.decimals} egldLabel={launchpad.token.ticker} digits={countDecimalPlaces(launchpad.maxTokensToSell, launchpad.token.decimals)} />
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Amount to raise:
        <span className='text-gray-900'>
          <FormatAmount value={launchpad.maxAmountToRaise} digits={countDecimalPlaces(launchpad.maxAmountToRaise, launchpad.token.decimals)} />
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Launchpad sale allocation:
        <span className='text-gray-900'>
          {launchpad.launchpadPercentage}%
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Sale start time:
        <span className='text-gray-900'>
          {moment(launchpad.startTimestamp * 1000).utc().format('YYYY-MM-DD HH:mm')} UTC
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Sale end time:
        <span className='text-gray-900'>
          {moment(launchpad.endTimestamp * 1000).utc().format('YYYY-MM-DD HH:mm')} UTC
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Launchpad SC address:
        <span className='text-gray-900'>
          <Link to={`${network.explorerAddress}/accounts/${launchpad.scAddress}`} target='blank' className='text-primary-200 underline'>
            <span className='hidden md:inline'>{launchpad.scAddress.slice(0, 8) + '...' + launchpad.scAddress.slice(-6)}</span>
            <span className='md:hidden'>{launchpad.scAddress}</span>
          </Link>
        </span>
      </div>
      <div className='flex justify-between border-b-[1px] pb-2'>
        Token owner address:
        <span className='text-gray-900'>
          <Link to={`${network.explorerAddress}/accounts/${launchpad.tokenOwner}`} target='blank' className='text-primary-200 underline'>
            <span className='hidden md:inline'>{launchpad.tokenOwner.slice(0, 8) + '...' + launchpad.tokenOwner.slice(-6)}</span>
            <span className='md:hidden'>{launchpad.tokenOwner}</span>
          </Link>
        </span>
      </div>
    </div>
  );
};
