export const LaunchCountdownRenderer = ({ days, hours, minutes, seconds }: any) => {
  const padNumber = (val: number) => {
    return val < 10 ? `0${val}` : val.toString();
  }

  return (
    <div className='flex'>
      <div className='flex flex-col text-white/50'>
        <span className='text-[8rem]  leading-[7rem] text-white xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem] sm:text-[3rem] sm:leading-[2.5rem] countdown-sm:text-[2.5rem] countdown-sm:leading-[2rem]'>{padNumber(days)}</span>
        <p className='text-[3rem] leading-[2rem] text-center xl:text-[2rem] xl:leading-[1.5rem] lg:text-[1.5rem] lg:leading-[1.5rem] sm:text-[1.25rem] countdown-sm:text-[1rem]'>days</p>
      </div>
      <span className='text-[8rem]  leading-[7rem]  text-white/50 xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem] sm:text-[3rem] countdown-sm:text-[2rem] countdown-sm:leading-[2rem]'>:</span>
      <div className='flex flex-col  text-white/50'>
        <span className='text-[8rem]  leading-[7rem]  text-white xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem]  sm:text-[3rem] sm:leading-[2.5rem] countdown-sm:text-[2.5rem] countdown-sm:leading-[2rem]'>{padNumber(hours)}</span>
        <p className='text-[3rem] leading-[2rem] text-center xl:text-[2rem] xl:leading-[1.5rem]  lg:text-[1.5rem] lg:leading-[1.5rem] sm:text-[1.25rem] countdown-sm:text-[1rem]'>hrs</p>
      </div>
      <span className='text-[8rem]  leading-[7rem]  text-white/50 xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem] sm:text-[3rem] countdown-sm:text-[2rem] countdown-sm:leading-[2rem]'>:</span>
      <div className='flex flex-col  text-white/50'>
        <span className='text-[8rem]  leading-[7rem]   text-white xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem]  sm:text-[3rem] sm:leading-[2.5rem] countdown-sm:text-[2.5rem] countdown-sm:leading-[2rem]'>{padNumber(minutes)}</span>
        <p className='text-[3rem] leading-[2rem] text-center xl:text-[2rem] xl:leading-[1.5rem]  lg:text-[1.5rem] lg:leading-[1.5rem] sm:text-[1.25rem] countdown-sm:text-[1rem]'>min</p>
      </div>
      <span className='text-[8rem]  leading-[7rem]  text-white/50 xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem] sm:text-[3rem] countdown-sm:text-[2rem] countdown-sm:leading-[2rem]'>:</span>
      <div className='flex flex-col  text-white/50'>
        <span className='text-[8rem]  leading-[7rem]  text-white xl:text-[5rem] xl:leading-[4.5rem] lg:text-[4rem] lg:leading-[3rem]  sm:text-[3rem] sm:leading-[2.5rem] countdown-sm:text-[2.5rem] countdown-sm:leading-[2rem]'>{padNumber(seconds)}</span>
        <p className='text-[3rem] leading-[2rem] text-center xl:text-[2rem] xl:leading-[1.5rem] lg:text-[1.5rem] lg:leading-[1.5rem]  sm:text-[1.25rem] countdown-sm:text-[1rem]'>sec</p>
      </div>
    </div>
  );
};
