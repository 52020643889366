import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios, { AxiosError } from 'axios';
import { Button, Input, Loader, Textarea } from 'components';
import { API_URL } from 'config';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { CreateLaunchpadDTO, LaunchpadState, LaunchpadStepStatus, TokenBrandingDTO, UploadTokenBrandingDto } from 'types';

interface TokenBrandingStepProps {
  launchpad: CreateLaunchpadDTO;
  onContinue: () => void;
}

const useUpdateLaunchpad = () => {
  const loginInfo = useGetLoginInfo();

  const patchLaunchpad = async ({ id, data }: { id: string; data: TokenBrandingDTO }): Promise<any> => {
    try {
      const uploadData = await axios.post(`/v1/launchpads/create/${id}/token-branding/upload`, {}, {
        baseURL: API_URL, headers: {
          Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
        }
      }).then((response) => response.data as UploadTokenBrandingDto);

      if (data?.pngFile) {
        await axios.put(uploadData.presignedPngUrl, data.pngFile, {
          headers: {
            'Content-Type': 'image/png'
          },
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / (e.total ?? 1));
            console.log('png upload progress', percentCompleted);
          }
        });
        data.pngUrl = `https://storage.googleapis.com/memversx/token-brandings/${id}.png`;
      }

      if (data?.svgFile) {
        await axios.put(uploadData.presignedSvgUrl, data.svgFile, {
          headers: {
            'Content-Type': 'image/svg+xml'
          },
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / (e.total ?? 1));
            console.log('svg upload progress', percentCompleted);
          }
        });
        data.svgUrl = `https://storage.googleapis.com/memversx/token-brandings/${id}.svg`;
      }

      const payload = {
        step: LaunchpadState.WAITING_FOR_TOKEN_BRANDING,
        tokenBranding: {
          ...data
        }
      };
      const { data: response } = await axios.patch(`/v1/launchpads/create/${id}`, payload, {
        baseURL: API_URL, headers: {
          Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
        }
      });
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        throw axiosError.response.data;
      } else {
        throw { message: axiosError.message ?? 'An error occurred' };
      }
    }
  };

  const { data, error, isLoading, mutate } = useMutation<any, any, { id: string; data: TokenBrandingDTO }>(patchLaunchpad);

  const updateLaunchpad = (id: string, data: TokenBrandingDTO) => {
    mutate({ id, data });
  };

  return { data, error, isLoading, updateLaunchpad };
};

export const TokenBrandingStep = ({ launchpad, onContinue }: TokenBrandingStepProps) => {
  const [isEdited, setIsEdited] = useState(false);
  const [formValues, setFormValues] = useState<TokenBrandingDTO>({
    description: launchpad.tokenBranding?.description ?? '',
    pngUrl: launchpad.tokenBranding?.pngUrl ?? '',
    svgUrl: launchpad.tokenBranding?.svgUrl ?? '',
    urls: {
      website: launchpad.tokenBranding?.urls?.website ?? undefined,
      telegram: launchpad.tokenBranding?.urls?.telegram ?? '',
      twitter: launchpad.tokenBranding?.urls?.twitter ?? undefined,
      email: launchpad.tokenBranding?.urls?.email ?? undefined,
      blog: launchpad.tokenBranding?.urls?.blog ?? undefined,
      whitepaper: launchpad.tokenBranding?.urls?.whitepaper ?? undefined,
      github: launchpad.tokenBranding?.urls?.github ?? undefined,
      medium: launchpad.tokenBranding?.urls?.medium ?? undefined,
      facebook: launchpad.tokenBranding?.urls?.facebook ?? undefined,
      tiktok: launchpad.tokenBranding?.urls?.tiktok ?? undefined,
      reddit: launchpad.tokenBranding?.urls?.reddit ?? undefined,
      discord: launchpad.tokenBranding?.urls?.discord ?? undefined
    }
  });
  const { data, error, isLoading, updateLaunchpad } = useUpdateLaunchpad();

  useEffect(() => {
    if (data) {
      onContinue();
    }
  }, [data]);

  useEffect(() => {
    const isPending = launchpad.currentStepData?.status === LaunchpadStepStatus.PENDING_VERIFICATION;
    if (!isPending) {
      return;
    }

    const intervalId = setInterval(() => {
      onContinue();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [launchpad?.currentStepData?.status]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsEdited(true);

    const { name, value } = e.target;

    if (name.startsWith('urls.')) {
      const parsedValue = value === '' ? undefined : value;
      const urls = { ...formValues.urls, [name.split('.')[1]]: parsedValue };
      setFormValues((prevValues) => ({
        ...prevValues,
        urls
      }));
      return;
    }

    if (name === 'pngFile' || name === 'svgFile') {
      const files = (e.target as any).files;
      if (files && files[0]) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: files[0] || null
        }));
      }
      return;
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const onSubmit = () => {
    if (!isEdited) {
      onContinue();
      return;
    }

    updateLaunchpad(launchpad.id, formValues);
  };

  if (launchpad.currentStepData?.status === LaunchpadStepStatus.PENDING_VERIFICATION) {
    return (
      <div className='flex flex-col gap-28 py-12 text-gray-600'>
        <div className='flex flex-col gap-4 items-center'>
          <h1 className='text-2xl text-primary-200'>Brand your token</h1>
          <p className='max-w-md text-center leading-4 text-md'>
            Shape your token’s identity for meme stardom: Create a compelling description, share your social media links, define your visual presence with a logo.
          </p>
        </div>
        <div className='flex flex-col gap-4 mx-auto w-full max-w-md text-orange-600'>
          <Loader />
          <div className='text-center'>
            <p>Token branding verification in progress.</p>
            <p>It usually takes a few minutes...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-12 py-12 text-gray-600'>
      <div className='flex flex-col gap-4 items-center'>
        <h1 className='text-2xl text-primary-200'>Brand your token</h1>
        <p className='max-w-md text-center leading-4 text-md'>
          Shape your token’s identity for meme stardom: Create a compelling description, share your social media links, define your visual presence with a logo.
        </p>
      </div>
      <div className='flex flex-col gap-4 mx-auto w-full max-w-md'>
        {launchpad.currentStepData?.status === LaunchpadStepStatus.ERROR && (
          <div className='text-status-danger mb-4'>
            <p className='text-danger-300'>Your token could not be branded. Make sure you respect the requirements.</p>
            <ul className='list-square ml-6'>
              {launchpad.currentStepData?.errors?.map((item: string) => (
                <li key={item} className='text-danger-300'>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Input
          type='file'
          name='pngFile'
          label='PNG Token Logo (200x200, max 100kb)'
          placeholder='No file chosen'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.pngUrl}
          disabled={isLoading}
          accept='image/png'
        />
        <Input
          type='file'
          name='svgFile'
          label='SVG Token Logo (square format, max 1mb)'
          placeholder='No file chosen'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.svgUrl}
          disabled={isLoading}
          accept='image/svg+xml'
        />
        <Textarea
          name='description'
          label='Project Description'
          placeholder='Type here ...'
          value={formValues.description}
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.description}
          disabled={isLoading}
          rows={4}
        />
        <Input
          type='text'
          name='urls.telegram'
          label='Community channel link'
          value={formValues.urls.telegram ?? ''}
          placeholder='Telegram link'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.urls?.telegram}
          disabled={isLoading}
        />
        <Input
          type='text'
          name='urls.website'
          label='Website (optional)'
          value={formValues.urls.website ?? ''}
          placeholder='https://'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.urls?.website}
          disabled={isLoading}
        />
        <Input
          type='text'
          name='urls.twitter'
          label='X (optional)'
          value={formValues.urls.twitter ?? ''}
          placeholder='X link'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.urls?.twitter}
          disabled={isLoading}
        />
        <Input
          type='text'
          name='urls.discord'
          label='Discord (optional)'
          value={formValues.urls.discord ?? ''}
          placeholder='Discord link'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.tokenBranding?.urls?.discord}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col items-center gap-2'>
        <Button className='min-w-[170px]' disabled={isLoading} onClick={onSubmit}>
          {isLoading ? 'Loading..' : 'Save & Continue'}
        </Button>
        {error?.message && <p className='text-xs text-status-danger'>{error.message}</p>}
      </div>
    </div>
  );
};
