import { useGetAccount, useGetIsLoggedIn, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useQuery } from 'react-query';

const fetchTwitterUrl = async (nativeAuthToken?: string) => {
  try {
    const { data } = await axios.get(`/v1/twitter/url`, { baseURL: API_URL, headers: { Authorization: `Bearer ${nativeAuthToken}` } });
    return data;
  } catch (error) {
    throw error;
  }
};

export const useGetTwitterUrl = (twitterVerificationData?: any): {
  data?: any;
  isLoading?: boolean;
  error?: any;
  refetch?: () => Promise<any>;
} => {
  const { address } = useGetAccount();
  const loginInfo = useGetLoginInfo();
  const isLoggedIn = useGetIsLoggedIn();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['twitter-url-confirmation', address, loginInfo.tokenLogin?.nativeAuthToken],
    queryFn: () => fetchTwitterUrl(loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    enabled: isLoggedIn && twitterVerificationData?.hasTwitterAccount && twitterVerificationData?.hasTwitterAccount === true
  });

  return { data, isLoading, error, refetch };
};
