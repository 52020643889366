import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useQuery } from 'react-query';
import { LeaderboardUserDTO } from 'types/karma.types';

const fetchUsersLeaderboard = async () => {
  try {
    const { data } = await axios.get(`/v1/user/leaderboard`, { baseURL: API_URL });
    return data as LeaderboardUserDTO[];
  } catch (error) {
    throw error;
  }
};

export const useGetUsersLeaderboard = (): {
  data?: LeaderboardUserDTO[];
  isLoading?: boolean;
  error?: any;
  refetch?: () => Promise<any>;
} => {

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['users-leaderboard'],
    queryFn: () => fetchUsersLeaderboard(),
    refetchOnWindowFocus: false,
    retry: 2
  });


  return { data, isLoading, error, refetch };
};
