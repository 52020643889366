import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, ActionOrConnect, Button, ErrorState, LoadingState, NotFoundState } from 'components';
import completedQuestIcon from 'assets/img/karma-symbol.png';
import uncompletedQuestIcon from 'assets/img/karma-symbol-grey.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { QuestDTO, QuestTypeEnum } from 'types/karma.types';
import ActionOrLinkTwitter from 'components/ActionOrLinkTwitter/ActionOrLinkTwitter';
import { getClickableLink, saveToLocalStorage } from 'helpers';
import { useGetAccount, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteNamesEnum } from 'localConstants';
import { useGetTwitterVerification, useGetTwitterUrl, usePostQuestIdToComplete } from 'pages/Karma/hooks';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';
import DailyQuestsCountdown from './DailyQuestsCountdown';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

interface KarmaQuestsPaneProps {
  inModal: boolean;
  onClickHide?: () => void;
  error?: any;
  isLoading?: boolean;
  quests?: QuestDTO[];
  swapsEnabled?: boolean;
  refetch: (() => Promise<any>) | (() => void) | undefined;
}

export const KarmaQuestsPanel = ({ inModal, onClickHide, isLoading, error, quests, refetch, swapsEnabled }: KarmaQuestsPaneProps) => {
  const { address } = useGetAccount();
  const navigate = useNavigate();
  const loginInfo = useGetLoginInfo();

  const isOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const isOnXPortal = loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && isOnMobile;

  const { data: twitterVerificationData, isLoading: isTwitterVerificationLoading, error: twitterVerificationError } = useGetTwitterVerification();
  const { data: twitterUrlData, isLoading: isTwitterUrlLoading, error: twitterUrlError } = useGetTwitterUrl(twitterVerificationData);

  const { startPostQuestIdToComplete, isLoading: postQuestIdToCompleteLoading, completeLoading } = usePostQuestIdToComplete(refetch);

  const { totalValue, completedValue, completedQuests } = useMemo(() => {
    const totalValue = quests?.reduce((acc, quest) => acc + quest.karma, 0) ?? 0;
    const completedQuests = quests?.filter((quest) => quest.completed) ?? [];
    const completedValue = completedQuests.reduce((acc, quest) => acc + quest.karma, 0);
    return { totalValue, completedValue, completedQuests };
  }, [quests]);

  const completeQuestDisabled = (questType: string) => {
    if ((questType === QuestTypeEnum.SWAP || questType === QuestTypeEnum.ADD_LIQUIDITY) && swapsEnabled === false) {
      return true;
    }

    return false;
  };

  const openQuestUrl = (quest: QuestDTO) => {
    let url = getClickableLink(quest.url);

    const isXExchangeQuest = quest.type === QuestTypeEnum.SWAP || quest.type === QuestTypeEnum.ADD_LIQUIDITY;
    if (isXExchangeQuest && loginInfo.tokenLogin) {
      url += `&accessToken=${loginInfo.tokenLogin.nativeAuthToken}`;
    }

    if (quest.type === QuestTypeEnum.CREATE_LAUNCHPAD) {
      url = `${location.origin}${quest.url}`;
    }

    window.open(url, isOnXPortal ? '_top' : '_blank');
  };

  const renderQuest = (quest: QuestDTO) => {
    return (
      <div className={`flex md:flex-col md:items-start flex-row justify-between items-center py-4 md:gap-2 ${completeQuestDisabled(quest.type) ? 'opacity-50' : ''}`} key={quest.id}>
        <div className='flex flex-row justify-start items-center gap-2 text-[18px]'>
          <img src={quest.completed ? completedQuestIcon : uncompletedQuestIcon} className='w-5 h-5' />
          <p >{quest.title}</p>
          {quest.completed === false && <div className='bg-[#B177ED] border-[1px] border-[#452E5D] px-2 text-[18px]'>+{quest.karma}</div>}
        </div>
        <div className='flex flex-col items-end gap-y-1 w-fit md:w-full'>
          <div className='flex flex-row justify-end md:justify-start items-center gap-4 md:w-full'>
            {quest.completed ? (
              <div className='flex flex-row justify-end md:justify-start min-w-[34px]'>
                <FontAwesomeIcon className='h-3' icon={faCheck} />
              </div>
            ) : null}
            <ActionOrConnect
              defaultComponent={
                <Button size='icon-sm' variant='outline' className={`text-white w-fit h-7 text-sm bg-[#AA97FF] hover:bg-[#7563D9] border-[#452E5D] normal-case min-w-[75px] md:w-full`}>
                  Connect
                </Button>
              }
              connectedComponent={
                <ActionOrLinkTwitter
                  defaultComponent={
                    <Button
                      onClick={() => {
                        saveToLocalStorage(`${address}-twitter-validation`, location.pathname);
                        window.open(twitterUrlData, '_self');
                      }}
                      size='icon-sm'
                      variant='outline'
                      className={`text-white w-fit px-2 h-7 text-sm bg-[#AA97FF] hover:bg-[#7563D9] border-[#452E5D] normal-case min-w-[75px] md:w-full`}
                    >
                      Sign In with X
                    </Button>
                  }
                  connectedComponent={
                    <Button
                      size='icon-sm'
                      variant={`${quest.completed ? 'outlineBorder' : 'outline'}`}
                      onClick={() => {
                        if (quest.completed) {
                          return undefined;
                        }
                        startPostQuestIdToComplete(quest.id);
                        openQuestUrl(quest);
                      }}
                      disabled={postQuestIdToCompleteLoading || isLoading || isTwitterVerificationLoading || isTwitterUrlLoading || completeLoading === quest.id || completeQuestDisabled(quest.type)}
                      className={`text-white w-fit h-7 text-sm ${quest.completed ? 'bg-[#7563D9] hover:bg-[#7563D9] cursor-default' : 'bg-[#AA97FF] hover:bg-[#7563D9]'
                        } border-[#452E5D] normal-case min-w-[75px] md:w-full disabled:bg-[#7563D9] disabled:text-white disabled:border disabled:border-[#452E5D]`}
                    >
                      {quest.completed ? (
                        'Completed'
                      ) : postQuestIdToCompleteLoading || completeLoading === quest.id ? (
                        <FontAwesomeIcon icon={faSpinner} className='animate-spin duration-1000' />
                      ) : completeQuestDisabled(quest.type) ?
                        'Inactive'
                        : (
                          'Complete'
                        )}
                    </Button>
                  }
                  hasTwitterLinked={!quest.twitterQuest ? true : twitterVerificationData?.hasTwitterAccount}
                />
              }
            />
          </div>
          {quest.nextAvailabilityTimestamp && <DailyQuestsCountdown nextAvailabilityTimestamp={quest.nextAvailabilityTimestamp} refetch={refetch} />}
        </div>
      </div>
    );
  };

  if (!quests || error) {
    return (
      <div className='container mx-auto flex justify-center py-20 bg-white'>
        <ErrorState title={'Error'} />
      </div>
    );
  }

  const visibleQuestsList = quests.filter((quest) => !quest.completed).slice(0, 5);
  const completedQuestsList = quests.filter((quest) => quest.completed);

  return (
    <div className={`flex flex-col w-full shadow text-white border-[2px] border-[#452E5D] max-w-screen`}>
      <div className='bg-gradient-to-r flex flex-row justify-between from-[#B177ED] to-[#8977ED] w-full py-6 px-5 border-b-[2px] border-[#452E5D]'>
        <div className='flex md:flex-col flex-row justify-between w-full divide-x-[1px] divide-[#3E295433] md:divide-x-0'>
          <div className='flex flex-row justify-between'>
            <p className='title-quests md:mb-4 mr-4 text-wrap'>
              COMPLETE QUESTS & <br /> COLLECT KARMA!
            </p>
            {inModal && <FontAwesomeIcon onClick={onClickHide} icon={faXmark} className='hidden md:block w-7 drop-shadow-[0_2px_0px_rgba(107,65,151,1)] cursor-pointer' />}
          </div>
          <div className='flex flex-row  divide-x-[1px] divide-[#3E295433]'>
            <div className='flex flex-col md:mb-2 justify-start px-4 leading-4 flex-grow'>
              <p className='text-md'>
                {completedQuests.length} / {quests.length}
              </p>{' '}
              <p className='text-sm  w-max'>Quests completed</p>
            </div>
            <div className='flex flex-col justify-start px-4 leading-4'>
              <p className='text-md'>
                {completedValue} / {totalValue}
              </p>{' '}
              <p className='text-sm w-max'>Karma Claimed</p>
            </div>
          </div>
        </div>
        {inModal ? (
          <FontAwesomeIcon onClick={onClickHide} icon={faXmark} className='w-7 block md:hidden drop-shadow-[0_2px_0px_rgba(107,65,151,1)] cursor-pointer' />
        ) : (
          <Button size='icon-sm' variant='outline' className='w-10 shrink-0 text-white text-lg border-[#452E5D] hover:bg-[#452E5D] bg-[#AA97FF]' onClick={() => navigate(RouteNamesEnum.karma)}>
            ?
          </Button>
        )}
      </div>
      <div className='flex flex-col bg-[#7563D9] px-6 py-4 text-white divide-y-[1px] divide-[#3E295433] border-t-[4px] border-[#0000001A]'>
        {isLoading || isTwitterVerificationLoading || isTwitterUrlLoading ? (
          <LoadingState title='Loading' className='text-white mx-auto' />
        ) : (
          <>
            {twitterVerificationError || (twitterUrlError && <p className='text-center text-orange-600'>An error has occurred while verifying X sync status.</p>)}
            {visibleQuestsList.length === 0 && (
              <div className='basis-1/3 flex gap-4 items-center flex-col pt-2 pb-4'>
                <div className='text-md text-teal-500 bg-teal-300 w-10 h-10 shadow-sm shadow-teal-500 flex items-center justify-center'>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <h4 className='text-md text-white'>All quests completed!</h4>
              </div>
            )}
            {visibleQuestsList.map((quest) => renderQuest(quest))}
            {completedQuestsList.length > 0 && (
              <Accordion type='single' collapsible>
                <AccordionItem value='hidden-quests' className='border-0 pb-0 mb-0'>
                  <AccordionPrimitive.Header className='flex'>
                    <AccordionPrimitive.Trigger className='flex flex-1 items-center justify-between py-2 font-medium transition-all [&[data-state=open]>svg]:rotate-180 text-[18px] text-white text-left sm:text-lg'>
                      Hide Completed Quests
                      <ChevronDown className='h-4 w-4 shrink-0 transition-transform duration-200 text-white' />
                    </AccordionPrimitive.Trigger>
                  </AccordionPrimitive.Header>
                  <AccordionPrimitive.Content className='overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'>
                    {completedQuestsList.map((quest) => renderQuest(quest))}
                  </AccordionPrimitive.Content>
                </AccordionItem>
              </Accordion>
            )}
          </>
        )}
      </div>
    </div>
  );
};
