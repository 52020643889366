import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hero, LaunchpadsList } from './components';
import { faCoins, faHandHoldingDollar, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Info } from 'components';
import { useLocation } from 'react-router-dom';

export const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <Hero />
      <div id='explore' className='scroll-mt-16'>
        <LaunchpadsList />
      </div>
      <div id="faq" className='container mb-20 max-w-6xl scroll-mt-16'>
        <div className='py-12'>
          <h2 className='text-title text-center lg:text-[48px] sm:text-2xl'>
            How does it work?
          </h2>
        </div>
        <div className='grid grid-cols-3 leading-4 lg:grid-cols-1 lg:max-w-sm lg:mx-auto'>
          <div className='flex flex-col gap-3 py-5 px-6 items-center text-center'>
            <div className="text-md text-teal-500 bg-teal-300 w-12 h-12 shadow-sm shadow-teal-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faRocket} />
            </div>
            <h3 className='text-lg text-gray-900'>Easy Token Launch</h3>
            <p>If the token sale passes the threshold, the token will be automatically issued, branded and listed on xExchange.</p>
          </div>
          <div className='flex flex-col gap-3 py-5 px-6 items-center text-center'>
            <div className="text-md text-orange-500 bg-orange-300 w-12 h-12 shadow-sm shadow-orange-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faHandHoldingDollar} />
            </div>
            <h3 className='text-lg text-gray-900'>Guaranteed Refund</h3>
            <p>If the token sale doesn’t pass the threshold, participants will get 100% of their funds back.</p>
          </div>
          <div className='flex flex-col gap-3 py-5 px-6 items-center'>
            <div className="text-md text-blue-500 bg-blue-300 w-10 h-10 shadow-sm shadow-blue-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faCoins} />
            </div>
            <h3 className='text-lg text-gray-900'>Threshold & Fees</h3>
            <div className='flex flex-col gap-1 w-full'>
              <p className='flex justify-between'>Launchpad sale threshold: <span className='text-gray-900 underline'>25 EGLD</span></p>
              <span className='flex justify-between'>Protocol fee (paid at launch):
                <span className='text-gray-900 underline flex gap-1'>
                  1 EGLD
                  <Info className="text-xs" containerClassName='!min-w-[120px]'>
                    <p className="normal-case">NON-REFUNDABLE</p>
                  </Info>
                </span>
              </span>
              <div className='flex justify-between'>Platform fee (successful sale):
                <span className='text-gray-900 underline flex gap-1'>
                  1%
                  <Info className="text-xs">
                    <p className="normal-case">1% of the raised amount will be used to <br /> <u>buy-back</u> the token and will be distributed based on Karma</p>
                  </Info>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container mb-20 flex flex-col gap-6 max-w-6xl lg:max-w-3xl'>
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger>How does MemeversX ensure a fair and secure token launch?</AccordionTrigger>
            <AccordionContent>
              <p>MemeversX ensures a fair and secure launch by automating the token issuance, branding, and listing process through smart contracts. A key security measure is the burning of the liquidity token after a successful sale, which prevents the concentration of ownership and protects against scams, reinforcing the platform's commitment to fairness and trust.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>How do I start a token launch on MemeversX Launchpad?</AccordionTrigger>
            <AccordionContent className='flex flex-col gap-4'>
              <p>To start a launch, all you need to do is follow a simple process where you will be required to enter your token's details like name, supply, and sale conditions. After setting your token price and other sale terms, pay a 1 EGLD fee to deploy a launch contract. This marks your token's launch beginning.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>How is the initial listing price of a token determined on xExchange after a successful MemeversX sale?</AccordionTrigger>
            <AccordionContent>
              <p>The initial listing price of a token on xExchange is determined based on predefined mechanisms, taking into account the total funds raised during the sale and the percentage of tokens allocated for liquidity. This method ensures a transparent and fair valuation, reflecting the token's initial market value objectively.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>What if the Launchpad sale ends with unsold tokens?</AccordionTrigger>
            <AccordionContent>
              <p>If not all tokens sell by the end date, the sale is deemed unsuccessful. Buyers can then redeem their EGLD, and the token is not issued, ensuring participants can recover their investment.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>How is a token issued, branded, and listed after a successful sale?</AccordionTrigger>
            <AccordionContent>
              <p>After a successful sale, the token's issuance, branding, and listing on xExchange are automated. This process is managed by smart contracts, ensuring a seamless transition to the token's active trading phase.</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};
