import { faCoins, faHandHoldingDollar, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Info } from 'components';

interface WelcomeStepProps {
  onContinue?: () => void;
}

export const WelcomeStep = ({ onContinue }: WelcomeStepProps) => {
  return (
    <div className='flex flex-col gap-12 py-12 text-gray-600'>
      <div className='flex flex-col gap-4 items-center'>
        <h1 className='text-2xl text-primary-200 text-center'>Fair Launch - How does it work?</h1>
        <p className='max-w-md text-center leading-4'>Creating a fair launch is a breeze! Read the instructions below and follow the steps to kickstart your meme journey.</p>
      </div>
      <div className='grid grid-cols-3 leading-4 md:grid-cols-1 md:max-w-sm md:mx-auto'>
        <div className='flex flex-col gap-3 py-5 px-6 items-center text-center'>
          <div className="text-md text-teal-500 bg-teal-300 w-12 h-12 shadow-sm shadow-teal-500 flex items-center justify-center">
            <FontAwesomeIcon icon={faRocket} />
          </div>
          <h3 className='text-lg text-gray-900'>Easy Token Launch</h3>
          <p>If the token sale passes the threshold, the token will be automatically issued, branded and listed on xExchange.</p>
        </div>
        <div className='flex flex-col gap-3 py-5 px-6 items-center text-center'>
          <div className="text-md text-orange-500 bg-orange-300 w-12 h-12 shadow-sm shadow-orange-500 flex items-center justify-center">
            <FontAwesomeIcon icon={faHandHoldingDollar} />
          </div>
          <h3 className='text-lg text-gray-900'>Guaranteed Refund</h3>
          <p>If the token sale doesn’t pass the threshold, participants will get 100% of their funds back.</p>
        </div>
        <div className='flex flex-col gap-3 py-5 px-6 items-center'>
          <div className="text-md text-blue-500 bg-blue-300 w-10 h-10 shadow-sm shadow-blue-500 flex items-center justify-center">
            <FontAwesomeIcon icon={faCoins} />
          </div>
          <h3 className='text-lg text-gray-900'>Threshold & Fees</h3>
          <div className='flex flex-col gap-1 w-full'>
            <p className='flex justify-between'>Launchpad sale threshold: <span className='text-gray-900 underline'>25 EGLD</span></p>
            <span className='flex justify-between'>Protocol fee (paid at launch):
              <span className='text-gray-900 underline flex gap-1'>
                1 EGLD
                <Info className="text-xs" containerClassName='!min-w-[120px]'>
                  <p className="normal-case">NON-REFUNDABLE</p>
                </Info>
              </span>
            </span>
            <span className='flex justify-between'>Platform fee (successful sale):
              <span className='text-gray-900 underline flex gap-1'>
                1%
                <Info className="text-xs">
                  <p className="normal-case">1% of the raised amount will be used to <br /> <u>buy-back</u> the token and will be distributed based on Karma</p>
                </Info>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <Button onClick={onContinue}>I understand, Continue</Button>
      </div>
    </div>
  );
};
