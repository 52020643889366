import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ActionOrConnect, Button, Card, FormatAmount, LaunchpadStatus } from 'components';
import { API_URL } from 'config';
import useSendAndSignTransaction from 'hooks/useSendAndSignTransaction';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { LaunchpadBalanceInfo } from './LaunchpadBalanceInfo';
import { LaunchpadSaleProps } from './LaunchpadSale';
import { PriceInfo } from './PriceInfo';

export const useRefundTransaction = (sendAndSignTransaction: (transaction: any) => Promise<void>) => {
  const loginInfo = useGetLoginInfo();
  const mutation = useMutation<string, any, string>((id) => axios.post(`/v1/launchpads/${id}/refund`, {}, {
    baseURL: API_URL, headers: {
      Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
    }
  }).then((res) => res.data), {
    onSuccess: (data) => {
      sendAndSignTransaction(data as any);
    }
  });

  const getTransaction = (id: string) => {
    mutation.mutate(id);
  };

  return { getTransaction, ...mutation };
};

export const LaunchpadSaleEnded = ({ launchpad }: LaunchpadSaleProps) => {
  const queryClient = useQueryClient();
  const { address } = useGetAccountInfo();

  const refundAmount = new BigNumber(launchpad.tokensBoughtByUser).multipliedBy(launchpad.tokenPrice).shiftedBy(-18).toFixed();
  const canRefund = new BigNumber(launchpad.tokensBoughtByUser).isGreaterThan(0);

  const { sendAndSignTransaction, loadingTransaction, successTransaction } = useSendAndSignTransaction();
  const { getTransaction, isLoading, isError } = useRefundTransaction(sendAndSignTransaction);

  useEffect(() => {
    if (successTransaction) {
      queryClient.invalidateQueries();
    }
  }, [successTransaction]);

  return (
    <Card>
      <div className='flex justify-between items-start'>
        <div className='border border-gray-200 px-4 py-2'>Fair Launch</div>
        <LaunchpadStatus status={launchpad.status} swapsEnabled={launchpad.swapsEnabled} />
      </div>
      <div className='flex flex-col text-center items-center gap-2 py-4'>
        <p className='text-lg text-red-600'>
          Sale did not reach the <br /> launch threshold
        </p>
        <p>
          If you participated in this launchpad, <br /> you can claim back your EGLD.
        </p>
      </div>
      <PriceInfo launchpad={launchpad} />
      <ActionOrConnect
        defaultComponent={<Button className='w-full'>Connect Wallet</Button>}
        connectedComponent={
          canRefund ? (
            <Button onClick={() => getTransaction(launchpad.id)} disabled={isLoading || loadingTransaction}>
              {/* // TODO: test refund format */}
              {isLoading || loadingTransaction ? (
                'Loading...'
              ) : (
                <>
                  Claim back <FormatAmount value={refundAmount} showLastNonZeroDecimal={true} className='mx-1' />
                </>
              )}
            </Button>
          ) : (
            <Button disabled={true}>Nothing to claim</Button>
          )
        }
      />
      {isError && <p className='text-center text-sm text-status-danger'>An error has occurred while getting the refund transaction.</p>}
      <LaunchpadBalanceInfo launchpad={launchpad} />
    </Card>
  );
};
