export enum RouteNamesEnum {
  home = '/',
  explore = '/#explore',
  faq = '/#faq',
  // about = '/about',
  // launchpads = '/launchpads',
  launchpadDetails = '/:slug',
  createdLaunchpads = '/launchpads/create',
  createLaunchpad = '/launchpads/create/:id',
  karma = '/karma',
  twitterConfirmation = '/twitter/confirmation'
}
