import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ActionOrConnect, Button, Card, LaunchpadStatus } from 'components';
import { API_URL } from 'config';
import useSendAndSignTransaction from 'hooks/useSendAndSignTransaction';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { LaunchpadBalanceInfo } from './LaunchpadBalanceInfo';
import { LaunchpadPartnersLinks } from './LaunchpadPartnersLinks';
import { LaunchpadSaleWithRefetchProps } from './LaunchpadSale';
import { PriceInfo } from './PriceInfo';

export const useClaimTransaction = (sendAndSignTransaction: (transaction: any) => Promise<void>) => {
  const loginInfo = useGetLoginInfo();
  const mutation = useMutation<string, any, string>(
    (id) =>
      axios
        .post(
          `/v1/launchpads/${id}/claim`,
          {},
          {
            baseURL: API_URL,
            headers: {
              Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
            }
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        sendAndSignTransaction(data as any);
      }
    }
  );

  const getTransaction = (id: string) => {
    mutation.mutate(id);
  };

  return { getTransaction, ...mutation };
};

export const useEnableSwapsTransaction = (sendAndSignTransaction: (transaction: any) => Promise<void>) => {
  const loginInfo = useGetLoginInfo();
  const mutation = useMutation<string, any, string>(
    (id) =>
      axios
        .post(
          `/v1/launchpads/${id}/enable-swaps`,
          {},
          {
            baseURL: API_URL,
            headers: {
              Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
            }
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        sendAndSignTransaction(data as any);
      }
    }
  );

  const getTransaction = (id: string) => {
    mutation.mutate(id);
  };

  return { getTransaction, ...mutation };
};

export const LaunchpadSaleClaiming = ({ launchpad, refetchLaunchpad }: LaunchpadSaleWithRefetchProps) => {
  const queryClient = useQueryClient();
  const { address } = useGetAccountInfo();

  const canClaim = new BigNumber(launchpad.tokensBoughtByUser).isGreaterThan(0);

  const { sendAndSignTransaction, loadingTransaction, successTransaction } = useSendAndSignTransaction();
  const { getTransaction, isLoading, isError } = useClaimTransaction(sendAndSignTransaction);
  const { getTransaction: enableSwaps, isLoading: enableSwapsLoading, isError: enableSwapsError } = useEnableSwapsTransaction(sendAndSignTransaction);

  useEffect(() => {
    if (successTransaction) {
      refetchLaunchpad?.();
      queryClient.invalidateQueries();
    }
  }, [successTransaction]);

  return (
    <Card>
      <div className='flex justify-between items-start'>
        <div className='border border-gray-200 px-4 py-2'>Fair Launch</div>
        <LaunchpadStatus status={launchpad.status} swapsEnabled={launchpad.swapsEnabled} />
      </div>
      <div className='flex flex-col text-center items-center gap-2 py-4'>
        <p className='text-xl text-green-600'>{launchpad.token.ticker} launched successfully!</p>
        <p>
          If you participated in this launchpad, <br />
          you can claim your tokens and trade them on xExchange.
        </p>
      </div>
      <PriceInfo launchpad={launchpad} />
      <div className='flex flex-col gap-4'>
        <ActionOrConnect
          defaultComponent={<Button className='w-full'>Connect Wallet</Button>}
          connectedComponent={
            canClaim ? (
              <Button onClick={() => getTransaction(launchpad.id)} disabled={isLoading || loadingTransaction}>
                {isLoading || loadingTransaction ? 'Loading...' : `Claim ${launchpad.token.ticker}`}
              </Button>
            ) : (
              <Button disabled>{launchpad.token.ticker} claimed!</Button>
            )
          }
        />

        {launchpad.canEnableSwaps && (
          <Button onClick={() => enableSwaps(launchpad.slug)} disabled={enableSwapsLoading || loadingTransaction}>
            {enableSwapsLoading || loadingTransaction ? 'Loading...' : `Enable Swaps`}
          </Button>
        )}

        {isError && <p className='text-center text-sm text-status-danger'>An error has occurred while getting the claim transaction.</p>}

        {enableSwapsError && <p className='text-center text-sm text-status-danger'>An error has occurred while processing the enable swaps transaction.</p>}
      </div>
      <LaunchpadBalanceInfo launchpad={launchpad} />

      {launchpad.swapsEnabled && <LaunchpadPartnersLinks launchpad={launchpad} />}
    </Card>
  );
};
