import axios from "axios";
import { API_URL } from "config";
import { useGetIsLoggedIn } from "hooks";
import { useQuery } from "react-query";
import { Launchpad, LaunchpadsFetchingDTO, SaleStatus } from "types";

interface UseGetLaunchpadsProps {
  tab: 'all' | 'my',
  page: number,
  filter?: {
    name?: string;
    status?: string;
  };
  sortBy?: string;
}

const fetchLaunchpads = async (tab: 'all' | 'my', page?: UseGetLaunchpadsProps['page'], filter?: UseGetLaunchpadsProps['filter'], sortBy?: UseGetLaunchpadsProps['sortBy']) => {
  try {
    const url = tab === 'all' ? `/v1/launchpads` : `/v1/launchpads/my`;
    const filterByStatusToSend = filter?.status === 'All' ? undefined : filter?.status === 'SoldOut' ? 'ClaimingPeriod' : filter?.status
    const { data } = await axios.get(url, { baseURL: API_URL, params: { page: page, limit: 10, search: filter?.name, sortBy: sortBy, status: filterByStatusToSend } });
    return data as LaunchpadsFetchingDTO;
  } catch (error) {
    throw error;
  }
};

export const useGetLaunchpads = ({ tab, filter, page, sortBy }: UseGetLaunchpadsProps): { data?: LaunchpadsFetchingDTO, filteredData?: Launchpad[], isLoading?: boolean, error?: any, refetch: () => void } => {
  const isLoggedIn = useGetIsLoggedIn()

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['launchpads', tab, page, filter?.name, filter?.status, sortBy],
    queryFn: ({ queryKey }) => fetchLaunchpads(isLoggedIn ? queryKey[1] as 'all' | 'my' : 'all', page, filter, sortBy),
    refetchOnWindowFocus: false,
    retry: false,
  });

  return { data, filteredData: data?.launchpads, isLoading, error, refetch };
}