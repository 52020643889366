import { NonFungibleTokenOfAccountOnNetwork } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import { MVX_API_URL } from 'config';

export enum LotteryResult {
  WIN = 'winningTicketEvent',
  LOSE = 'losingTicketEvent',
  UNKNOWN = 'unknown'
}

const findClaimLotteryEvent = (data: any): any | undefined => {
  const rootEvent = data?.logs?.events?.find((event: any) => event.identifier === 'claimLottery');
  if (rootEvent) return rootEvent;

  for (const result of data?.results || []) {
    const event = result?.logs?.events?.find((event: any) => event.identifier === 'claimLottery');
    if (event) return event;
  }

  return undefined;
};

export const fetchLotteryResult = async (hash: string): Promise<LotteryResult | undefined> => {
  try {
    const { data } = await axios.get(`${MVX_API_URL}/transactions/${hash}`);
    if (data.status !== 'success') {
      return undefined;
    }

    const claimLotteryEvent = findClaimLotteryEvent(data);

    if (!claimLotteryEvent) {
      return undefined;
    }

    const result = Buffer.from(claimLotteryEvent.topics[0], 'base64').toString();
    if (result === LotteryResult.WIN) {
      return LotteryResult.WIN;
    } else if (result === LotteryResult.LOSE) {
      return LotteryResult.LOSE;
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};
