import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LoadingStateProps {
  title?: string;
  className?: string;
}

export const LoadingState = ({ title, className }: LoadingStateProps) => {
  return (
    <div className={`flex flex-col justify-center gap-4 max-w-xs items-center text-orange-600 ${className || ''}`}>
      <FontAwesomeIcon icon={faSpinner} className='text-2xl animate-spin duration-1000' />
      <div className='text-center'>
        <p>{title ?? 'Loading launchpad'}</p>
        <p>It may take a few seconds...</p>
      </div>
    </div>
  );
};
