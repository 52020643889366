import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ErrorState, LoadingState, NotFoundState } from 'components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HowItWorks, LaunchpadDetails, LaunchpadQuests, LaunchpadSale, LaunchpadStats } from './components';
import { useGetLaunchpad } from './hooks';

export const Launchpad = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: launchpad, error, isLoading, refetch: refetchLaunchpad } = useGetLaunchpad(slug!);

  useEffect(() => {
    document.title = launchpad?.token?.name ? `${launchpad.token.name} • MemeversX` : 'MemeversX';
  }, [launchpad]);

  if ((!launchpad && !error) || isLoading) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <LoadingState />
      </div>
    );
  }

  if (error?.response?.status === 404) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <NotFoundState />
      </div>
    );
  }

  if (error?.response?.status === 425) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
          <div className='text-md text-orange-500 bg-orange-300 w-12 h-12 shadow-sm shadow-orange-500 flex items-center justify-center'>
            <FontAwesomeIcon icon={faSpinner} />
          </div>
          <div className='text-center'>
            <p className='text-gray-900 text-lg'>Your launchpad is not yet live.</p>
            <p className='text-gray-600'>If you published it recently, please wait a few seconds for the changes to take effect.</p>
          </div>
          <Button variant='default' className='min-w-[140px]' onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (!launchpad || error) {
    return (
      <div className='container mx-auto flex justify-center py-20'>
        <ErrorState />
      </div>
    );
  }

  return (
    <div className='container mx-auto grid grid-cols-6 gap-6 py-12 lg:grid-cols-1'>
      <div className='flex flex-col col-span-4 gap-8 py-6 px-12 lg:gap-0 lg:col-span-1 sm:px-0'>
        <LaunchpadDetails launchpad={launchpad} />
        <LaunchpadStats launchpad={launchpad} />
        <div className='lg:mt-4 mt-0'>
          <LaunchpadQuests launchpadId={launchpad.id} swapsEnabled={launchpad.swapsEnabled} />
        </div>
        <HowItWorks launchpad={launchpad} />
      </div>
      <div className='flex flex-col col-span-2 gap-6 lg:gap-0 lg:py-6 lg:px-12 lg:col-span-1 lg:order-first sm:px-0'>
        <LaunchpadSale launchpad={launchpad} refetchLaunchpad={refetchLaunchpad} />
      </div>
    </div>
  );
};
