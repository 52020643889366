import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { TransactionToastGuardPropsType, CustomToast } from '@multiversx/sdk-dapp/UI';
import { WithClassnameType } from '@multiversx/sdk-dapp/UI/types';
import { useGetSignedTransactions } from '@multiversx/sdk-dapp/hooks';
import { useSelector, useDispatch } from '@multiversx/sdk-dapp/reduxStore/DappProviderContext';
import { customToastsSelector, transactionToastsSelector } from '@multiversx/sdk-dapp/reduxStore/selectors';
import { removeTransactionToast, addTransactionToast } from '@multiversx/sdk-dapp/reduxStore/slices';
import { store } from '@multiversx/sdk-dapp/reduxStore/store';
import { removeSignedTransaction } from '@multiversx/sdk-dapp/services/transactions/clearTransactions';
import { SignedTransactionsType, SignedTransactionsBodyType, TransactionBatchStatusesEnum } from '@multiversx/sdk-dapp/types';
import { TransactionToastType } from '@multiversx/sdk-dapp/types/toasts.types';
import { deleteCustomToast, getRegisteredCustomIconComponents, getRegisteredToastCloseHandler, getIsTransactionPending } from '@multiversx/sdk-dapp/utils';
import { createPortal } from 'react-dom';
import { TransactionToastGuard } from './TransasctionToastGuard';

export interface TransactionsToastListPropsType extends WithClassnameType {
  toastProps?: any;
  withTxNonce?: boolean;
  signedTransactions?: SignedTransactionsType;
  successfulToastLifetime?: number;
  parentElement?: Element | DocumentFragment;
  transactionToastClassName?: string;
  customToastClassName?: string;
  children?: React.ReactNode;
}

export const TransactionsToastListComponent = ({
  className = 'transactions-toast-list',
  transactionToastClassName,
  customToastClassName,
  signedTransactions,
  successfulToastLifetime,
  parentElement,
  children
}: TransactionsToastListPropsType) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const customToasts = useSelector(customToastsSelector);
  const transactionsToasts = useSelector(transactionToastsSelector);
  const dispatch = useDispatch();

  const { signedTransactions: signedTransactionsFromStore } = useGetSignedTransactions();

  const signedTransactionsToRender = signedTransactions || signedTransactionsFromStore;

  const signedTransactionsToRenderRef = useRef(signedTransactionsToRender);

  useEffect(() => {
    signedTransactionsToRenderRef.current = signedTransactionsToRender;
  }, [signedTransactionsToRender]);

  const handleDeleteCustomToast = (toastId: string) => {
    deleteCustomToast(toastId);
  };

  const handleDeleteTransactionToast = (toastId: string) => {
    dispatch(removeTransactionToast(toastId));
    removeSignedTransaction(toastId);
  };

  const handleSignedTransactionsListUpdate = useCallback(() => {
    for (const sessionId in signedTransactionsToRender) {
      const session = signedTransactionsToRender[sessionId];
      const skipSending = session?.customTransactionInformation?.signWithoutSending;

      if (skipSending) {
        continue;
      }

      const alreadyHasToastForThisSession = transactionsToasts.some((toast: TransactionToastType): boolean => `${toast.toastId}` === `${sessionId}`);

      if (!alreadyHasToastForThisSession) {
        dispatch(addTransactionToast(sessionId));
      }
    }
  }, [dispatch, signedTransactionsToRender, transactionsToasts]);

  useEffect(() => {
    handleSignedTransactionsListUpdate();
  }, [signedTransactionsToRender, handleSignedTransactionsListUpdate]);

  const MemoizedTransactionsToastsList = useMemo(
    () =>
      transactionsToasts.map(({ toastId, type, startTimestamp }: TransactionToastType, i) => {
        const transactionToastGuardProps: TransactionToastGuardPropsType = {
          signedTransactionsToRender,
          toastId,
          type,
          startTimestamp,
          successfulToastLifetime,
          handleDeleteTransactionToast,
          className: transactionToastClassName
        };
        return <TransactionToastGuard {...transactionToastGuardProps} key={startTimestamp + i} />;
      }),
    [transactionsToasts, signedTransactionsToRender, successfulToastLifetime, handleDeleteTransactionToast, transactionToastClassName]
  );

  const customToastsList = customToasts.map((props) => {
    const CustomComponent = getRegisteredCustomIconComponents(props.toastId) ?? null;
    const onCloseHandler = getRegisteredToastCloseHandler(props.toastId);

    return (
      <CustomToast
        key={props.toastId}
        {...props}
        component={CustomComponent as never}
        onDelete={() => {
          handleDeleteCustomToast(props.toastId);
          onCloseHandler?.();
        }}
        className={customToastClassName}
      />
    );
  });

  const clearNotPendingTransactionsFromStorage = () => {
    const toasts = transactionToastsSelector(store.getState());

    toasts.forEach((transactionToast: TransactionToastType) => {
      const currentTx: SignedTransactionsBodyType = signedTransactionsToRenderRef.current[transactionToast.toastId];

      if (!currentTx) {
        return;
      }

      const { status } = currentTx;
      const isPending = getIsTransactionPending(status);
      const isSigned = status === TransactionBatchStatusesEnum.signed;

      if (!isPending && !isSigned) {
        handleDeleteTransactionToast(transactionToast.toastId);
      }
    });
  };

  useEffect(() => {
    setIsBrowser(true);

    window?.addEventListener('beforeunload', clearNotPendingTransactionsFromStorage);

    return () => {
      window?.removeEventListener('beforeunload', clearNotPendingTransactionsFromStorage);
    };
  }, []);

  return (
    <>
      {isBrowser &&
        createPortal(
          <div>
            {customToastsList}
            {MemoizedTransactionsToastsList}
            {children}
          </div>,
          parentElement || document?.body
        )}
    </>
  );
};
