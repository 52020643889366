import { Card, FormatAmount, LaunchpadProgress, LaunchpadStatus } from "components";
import { LaunchpadSaleProps } from "./LaunchpadSale";
import { LaunchpadBalanceInfo } from "./LaunchpadBalanceInfo";
import { countDecimalPlaces } from "utils/countDecimalPlaces";
import { PriceInfo } from "./PriceInfo";

export const LaunchpadSaleSoldOut = ({ launchpad }: LaunchpadSaleProps) => {
  return (
    <Card>
      <div className="flex justify-between items-start">
        <div className="border border-gray-200 px-4 py-2">Fair Launch</div>
        <LaunchpadStatus status={launchpad.status} swapsEnabled={launchpad.swapsEnabled} />
      </div>
      <div className='flex flex-col text-center items-center gap-2 py-4'>
        <p className="text-xl text-green-600">{launchpad.token.ticker} Sale Completed!</p>
        <p>If you participated in this launchpad, <br />you can claim your tokens and trade them on xExchange.</p>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span>Sale Progress</span>
          <span className="text-md text-green-500">100%</span>
        </div>
        <div>
          <LaunchpadProgress progress={100} />
        </div>
        <div className="text-center text-md">
          <FormatAmount value={launchpad.currentRaisedAmount} egldLabel=' ' digits={countDecimalPlaces(launchpad.currentRaisedAmount, launchpad.token.decimals)} />{' / '}
          <FormatAmount value={launchpad.maxAmountToRaise} digits={countDecimalPlaces(launchpad.maxAmountToRaise, launchpad.token.decimals)} />
        </div>
      </div>
      <PriceInfo launchpad={launchpad} />
      <div>
        {/* TODO: update message */}
        <p className="text-center text-orange-500">You will be able to claim your tokens after the token is issued. It may take a few minutes.</p>
      </div>
      <LaunchpadBalanceInfo launchpad={launchpad} />
    </Card>
  );
};
