import { useGetAccount, useGetAccountInfo, useGetIsLoggedIn, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ActionOrConnect, Button, Card, FormatAmount, Input, LaunchpadIcon, LaunchpadProgress, LaunchpadStatus } from 'components';
import { API_URL } from 'config';
import useSendAndSignTransaction from 'hooks/useSendAndSignTransaction';
import { useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { useMutation, useQueryClient } from 'react-query';
import { countDecimalPlaces } from 'utils/countDecimalPlaces';
import { LaunchpadBalanceInfo } from './LaunchpadBalanceInfo';
import { LaunchpadSaleWithRefetchProps } from './LaunchpadSale';
import { PriceInfo } from './PriceInfo';
import { countdownRenderer } from './countdownRenderer';

export const useBuyTransaction = (sendAndSignTransaction: (transaction: any) => Promise<void>) => {

  const loginInfo = useGetLoginInfo();
  const mutation = useMutation<any, any, any, any>(({ id, numTickets }) => axios.post(`/v1/launchpads/${id}/buy`, { numTickets }, {
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
    }
  }).then((res) => res.data), {
    onSuccess: (data) => {
      sendAndSignTransaction(data as any);
    }
  });

  const getTransaction = (id: string, numTickets: string) => {
    mutation.mutate({ id, numTickets });
  };

  return { getTransaction, ...mutation };
};

export const LaunchpadSaleActive = ({ launchpad, refetchLaunchpad }: LaunchpadSaleWithRefetchProps) => {
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  const { balance } = useGetAccount();
  const queryClient = useQueryClient();

  const [amount, setAmount] = useState('');

  const progress = new BigNumber(launchpad.currentRaisedAmount).dividedBy(launchpad.maxAmountToRaise).multipliedBy(100).decimalPlaces(2).toString();

  const maxTokensPerUser = new BigNumber(launchpad.maxTokensPerUser).minus(launchpad.tokensBoughtByUser);
  const tokensLeft = new BigNumber(launchpad.maxTokensToSell).minus(launchpad.currentTokensBought);
  const availableToBuy = BigNumber.min(maxTokensPerUser, tokensLeft).toFixed();

  const allowanceErrors = useMemo(() => {
    const isInteger = new BigNumber(amount).isInteger();
    if (!isInteger) {
      return 'Tokens must be bought in whole amounts';
    }

    const isLessThanMin = new BigNumber(amount).shiftedBy(launchpad.token.decimals).isLessThan(1);
    if (isLessThanMin) {
      return 'Amount must be greater than 1';
    }

    const isLess = new BigNumber(amount).shiftedBy(launchpad.token.decimals).isLessThanOrEqualTo(availableToBuy);
    if (!isLess) {
      return `You can buy a maximum of ${new BigNumber(availableToBuy).shiftedBy(-launchpad.token.decimals).toFixed()} tokens`;
    }

    const amountInEgld = new BigNumber(amount).multipliedBy(launchpad.tokenPrice).toFixed();
    const hasEnoughBalance = new BigNumber(amountInEgld).isLessThanOrEqualTo(balance);
    if (!hasEnoughBalance) {
      return 'Insufficient balance';
    }

    return undefined;
  }, [amount, launchpad.maxTokensPerUser, balance]);

  const { sendAndSignTransaction, loadingTransaction, successTransaction } = useSendAndSignTransaction();
  const { getTransaction, isLoading, isError } = useBuyTransaction(sendAndSignTransaction);

  const endtime = launchpad.endTimestamp * 1000 + 6000;

  useEffect(() => {
    const timeToRefresh = new Date(endtime).getTime() - Date.now();

    const interval = setInterval(() => {
      if (timeToRefresh < 0) {
        return;
      }

      refetchLaunchpad?.();
      queryClient.invalidateQueries();
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (successTransaction) {
      setAmount('0');
      queryClient.invalidateQueries();
    }
  }, [successTransaction]);

  const handleMaxButton = () => {
    const maxTokens = new BigNumber(availableToBuy).shiftedBy(-launchpad.token.decimals).toFixed();
    setAmount(maxTokens);
  };

  return (
    <Card>
      <div className='flex justify-between items-start'>
        <div className='border border-gray-200 px-4 py-2'>Fair Launch</div>
        <LaunchpadStatus status={launchpad.status} swapsEnabled={launchpad.swapsEnabled} />
      </div>
      <div className='flex flex-col text-center  items-center'>
        <p className='text-xl text-gray-900'>Sale Ends In:</p>
        <Countdown date={new Date(endtime)} renderer={countdownRenderer} />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between'>
          <span>Sale Progress</span>
          <span className='text-md text-green-500'>{progress}%</span>
        </div>
        <div>
          <LaunchpadProgress progress={parseFloat(progress)} />
        </div>
        <div className='text-center text-md'>
          <FormatAmount value={launchpad.currentRaisedAmount} egldLabel=' ' digits={countDecimalPlaces(launchpad.currentRaisedAmount, launchpad.token.decimals)} />
          {' / '}
          <FormatAmount value={launchpad.maxAmountToRaise} digits={countDecimalPlaces(launchpad.maxAmountToRaise, launchpad.token.decimals)} />
        </div>
      </div>
      <PriceInfo launchpad={launchpad} />
      <div className='flex flex-col gap-2'>
        <div className='text-gray-600 flex justify-between'>
          <span>Amount to buy</span>
          <button className='text-primary-100 underline' onClick={handleMaxButton}>
            MAX
          </button>
        </div>
        <div className='relative'>
          <Input
            type='number'
            placeholder={`max. ${new BigNumber(availableToBuy).shiftedBy(-launchpad.token.decimals).toFixed(0)}`}
            value={amount === '0' ? '' : amount}
            onChange={(e) => setAmount(e.target.value)}
            disabled={isLoading || loadingTransaction || !isLoggedIn}
            className={`text-md ${amount.length > 0 ? 'pt-6 pb-8' : 'py-7'}`}
            min={1}
            errors={allowanceErrors !== undefined && amount !== '' ? [allowanceErrors] : undefined}
          />
          <div className='absolute top-[15px] right-4 flex items-center gap-2 text-gray-900 h-8'>
            <LaunchpadIcon icon={launchpad.token.assets?.pngUrl ?? launchpad.token.assets?.svgUrl} size='sm' />
            {launchpad.token.ticker}
          </div>
          <div>
            {amount.length > 0 && (
              <FormatAmount
                className='absolute top-[36px] left-[22px] text-gray-400 text-sm'
                value={(parseFloat(launchpad.tokenPrice) * parseFloat(amount)).toString()}
                digits={countDecimalPlaces(launchpad.tokenPrice, launchpad.token.decimals)}
              />
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-1'>
        <ActionOrConnect
          defaultComponent={<Button className='w-full'>Connect Wallet</Button>}
          connectedComponent={
            <Button onClick={() => getTransaction(launchpad.id, amount)} className='w-full' disabled={isLoading || loadingTransaction || allowanceErrors !== undefined}>
              {isLoading || loadingTransaction ? 'Loading...' : `Buy ${launchpad.token.ticker}`}
            </Button>
          }
        />
        {isLoggedIn && amount !== '' && isError && <p className='text-status-danger text-sm text-center'>An error has occurred while generating the transaction</p>}
      </div>
      <LaunchpadBalanceInfo launchpad={launchpad} />
    </Card>
  );
};
