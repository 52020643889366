import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface TransactionToastWrapperPropsType {
  className?: string;
  children?: ReactNode;
  id?: string;
}

export const TransactionToastWrapperComponent = ({ className = 'dapp-transaction-toast-wrapper', children, id }: TransactionToastWrapperPropsType) => (
  <div id={id} className={classNames(className)}>
    {children}
  </div>
);
