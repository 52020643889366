import { Dialog, DialogContent } from 'components';
import { KarmaQuestsPanel } from 'pages/Launchpad/components';
import { useGetPlatformQuests } from '../hooks';

interface KarmaQuestsModalProps {
  show: boolean;
  onHide: () => void;
  refetchUserStatus: (() => Promise<any>) | undefined;
}

export const KarmaQuestsModal = ({ show, onHide, refetchUserStatus }: KarmaQuestsModalProps) => {
  const { data: quests, isLoading, error, refetch } = useGetPlatformQuests();

  const handleRefetchDataUsed = () => {
    refetchUserStatus?.();
    refetch?.();
  }

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent className='p-0 border-0 max-h-screen w-fit max-w-screen overflow-scroll no-scrollbar bg-transparent lg:min-w-[600px] sm:min-w-fit sm:w-full py-2 md:py-14' hideCloseButton={true}>
        <KarmaQuestsPanel inModal={true} onClickHide={onHide} isLoading={isLoading} error={error} quests={quests} refetch={handleRefetchDataUsed} />
      </DialogContent>
    </Dialog>
  );
};
