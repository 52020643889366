import {
  ContractFunction,
  ResultsParser,
  SmartContract,
  TypedValue
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';

const resultsParser = new ResultsParser();

export const vmQuery = async (
  smartContract: SmartContract,
  apiAddress: string,
  endpoint: string,
  args: TypedValue[]
): Promise<TypedValue | undefined> => {
  try {
    const proxy = new ProxyNetworkProvider(apiAddress);

    const query = smartContract.createQuery({
      func: new ContractFunction(endpoint),
      args
    });
    const queryResponse = await proxy.queryContract(query);

    const endpointDefinition = smartContract.getEndpoint(endpoint);

    const { firstValue } = resultsParser.parseQueryResponse(
      queryResponse,
      endpointDefinition
    );
    return firstValue ?? undefined;
  } catch (err: any) {
    return undefined;
  }
};
