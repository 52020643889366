import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "./__base__";

interface ErrorStateProps {
  title?: string;
};

export const ErrorState = ({ title }: ErrorStateProps) => {
  return (
    <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
      <div className="text-md text-gray-800 bg-gray-400 w-12 h-12 shadow-sm shadow-gray-800 flex items-center justify-center">
        <FontAwesomeIcon icon={faFrown} />
      </div>
      <div className='text-center'>
        <p className='text-gray-900 text-lg'>{title ?? "Launchpads couldn’t be loaded"}</p>
        <p className='text-gray-600'>Something went wrong, please try again later.</p>
      </div>
      <Button variant='default' className='min-w-[140px]' onClick={() => window.location.reload()}>Retry</Button>
    </div>
  );
}
