import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "./__base__";
import { RouteNamesEnum } from "localConstants";
import { Link } from "react-router-dom";

export const NotFoundState = () => {
  return (
    <div className='flex flex-col justify-center gap-4 max-w-xs items-center'>
      <div className="text-md text-gray-800 bg-gray-400 w-12 h-12 shadow-sm shadow-gray-800 flex items-center justify-center">
        <FontAwesomeIcon icon={faFrown} />
      </div>
      <div className='text-center'>
        <p className='text-gray-900 text-lg'>Launchpads couldn’t be found</p>
        <p className='text-gray-600'>The launchpad you are looking for doesn’t exist.</p>
      </div>
      <Link to={RouteNamesEnum.explore}>
        <Button variant='default' className='min-w-[140px]'>Explore launchpads</Button>
      </Link>
    </div>
  );
}
