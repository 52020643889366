export const saveToLocalStorage = (key: string, information: string) => {
 localStorage.setItem(key, information);
};

export const retrieveFromLocalStorage = (key: string) => {
 const information = localStorage.getItem(key);
 return information;
};

export const retrieveAndDeleteFromLocalStorage = (key: string) => {
 const information = localStorage.getItem(key);

 if (information !== null) {
  localStorage.removeItem(key);
 }

 return information;
};