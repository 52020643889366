import {
  CHROME_EXTENSION_LINK,
  FIREFOX_ADDON_LINK
} from '@multiversx/sdk-dapp/constants/index';
import { useExtensionLogin } from '@multiversx/sdk-dapp/hooks/login/useExtensionLogin';
import { getIsNativeAuthSingingForbidden } from '@multiversx/sdk-dapp/services/nativeAuth/helpers';
import { OnProviderLoginType } from '@multiversx/sdk-dapp/types';
import { WithClassnameType } from '../../types';
import { getIsExtensionAvailable } from '@multiversx/sdk-dapp/utils/platform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export interface ExtensionLoginButtonPropsType extends WithClassnameType,
  OnProviderLoginType {
  buttonClassName?: string;
  loginButtonText?: string;
  disabled?: boolean;
}

const isExtensionAvailable = getIsExtensionAvailable();

export const ExtensionLoginButton: (props: ExtensionLoginButtonPropsType) => JSX.Element = ({
  token,
  callbackRoute,
  onLoginRedirect,
  disabled,
}) => {
  const [onInitiateLogin] = useExtensionLogin({
    callbackRoute,
    token,
    onLoginRedirect,
    nativeAuth: true
  });
  const disabledConnectButton = getIsNativeAuthSingingForbidden(token);
  const isFirefox = navigator.userAgent.indexOf('Firefox') != -1;

  const handleLogin = () => {
    onInitiateLogin();
  };

  return !isExtensionAvailable ? (
    <a
      rel='noreferrer'
      href={isFirefox ? FIREFOX_ADDON_LINK : CHROME_EXTENSION_LINK}
      target='_blank'
      className='flex pb-2 pr-2 justify-between items-center'
    >
      <div className='flex gap-3 items-center'>
        <div className='w-7 h-7 bg-black flex items-center justify-center'>
          <object data="/img/connect/extension.svg" type="image/svg+xml" className='h-[14px] w-auto' />
        </div>
        <span className="text-md text-gray-900">DeFi Wallet</span>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className='text-[#888888] text-md' />
    </a>
  ) : (
    <button
      disabled={disabled || disabledConnectButton}
      onClick={handleLogin}
      className='flex pb-2 pr-2 justify-between items-center'
    >
      <div className='flex gap-3 items-center'>
        <div className='w-7 h-7 bg-black flex items-center justify-center'>
          <object data="/img/connect/extension.svg" type="image/svg+xml" className='h-4 w-auto' />
        </div>
        <span className="text-md text-gray-900">DeFi Wallet</span>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className='text-[#888888] text-md' />
    </button>
  );
};
