interface LaunchpadProgressProps {
  size?: "md" | "lg";
  progress: number;
}

export const LaunchpadProgress = ({ size, progress }: LaunchpadProgressProps) => {
  const renderLines = () => {
    return (
      <div className="flex justify-between px-1">
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
        <span className="w-[2px] h-2 inline-block bg-gray-400"></span>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-1">
      {renderLines()}
      {/* TODO: solve inner shadow */}
      <div className={`${size === 'lg' ? 'h-[35px]' : 'h-[15px]'}  w-full bg-white border border-gray-300 shadow-inner`}>
        <div className="h-full bg-green-400" style={{ width: `${progress}%` }}></div>
      </div>
      {renderLines()}
    </div>
  );
};
