import { Button, Info } from 'components';
import { useState } from 'react';
import { UserStatusDTO } from 'types/karma.types';

interface KarmaUserStatsProps {
  userStatus: UserStatusDTO | undefined
}

export const KarmaUserStats = ({ userStatus }: KarmaUserStatsProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const onCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 2000);
  };

  if (!userStatus) {
    return null;
  }

  const referralLink = `${location.origin}?ref=${userStatus.referralCode}`;

  return (
    <div className='flex flex-col justify-start items-center'>
      <p className='karma-status-label mb-12'>Your Status</p>
      <div className='flex flex-col border-[2px] border-[#CED4DA] p-6 shadow'>
        <div className='flex flex-col gap-4 w-full'>
          <div className='flex flex-row border-[2px] border-[#CED4DA] p-6 justify-between items-center'>
            <p className='text-sm'>
              Place on <br /> the list
            </p>
            <p className='karma-points-small'>#{userStatus.rank}</p>
          </div>
          <div className='flex flex-row border-[2px] border-[#CED4DA] p-6 justify-between items-center'>
            <p className='text-sm'>
              Referred <br /> friends
            </p>
            <p className='karma-points-small'>{userStatus.referredUsers}</p>
          </div>
          <div className='flex flex-row border-[2px] border-[#CED4DA] p-6 justify-between items-center'>
            <p className='text-sm'>
              Karma from <br /> referrals
            </p>
            <p className='karma-points-small'>{userStatus.karmaFromReferrals}</p>
          </div>
          <div className='flex flex-row border-[2px] border-[#CED4DA] p-6 justify-between items-center'>
            <span className='text-sm mr-10 no-wrap'>
              Referral link
              <Info toLeft={true} className="text-xs hover:text-purple-600 ml-1">
                <p className="normal-case">Invite friends and earn 10%<br /> of their karma points,<br /> while they get a welcome <br /> bonus of 15 points!<br /> Up to 3 friends.</p>
              </Info>
            </span>
            <a className='text-sm text-green-700 underline' href={referralLink}>{referralLink}</a>
          </div>
        </div>

        <div className='group relative w-full'>
          <Button variant='outline' className='w-full mt-6' onClick={() => onCopyLink(referralLink)}>
            COPY REFERRAL LINK
          </Button>
          <div className={`absolute scale-0  flex flex-col items-center left-[calc(50%-60px)] top-[35px] ${isLinkCopied ? 'scale-100' : ''}`}>
            <div className='w-0 h-0 border-[16px] border-b-gray-900 border-transparent'></div>
            <div className='p-2 min-w-[120px] shadow-sm bg-gray-900 text-white text-center'>Link copied!</div>
          </div>
        </div>
      </div>
    </div>
  );
};
