import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithStylesImportType } from '@multiversx/sdk-dapp/hocs/useStyles';
import { useMetamaskProxyLogin } from '@multiversx/sdk-dapp/hooks/login/useMetamaskProxyLogin';
import { getIsNativeAuthSingingForbidden } from '@multiversx/sdk-dapp/services/nativeAuth/helpers';
import { OnProviderLoginType } from '@multiversx/sdk-dapp/types';
import { ReactNode } from 'react';
import { WithClassnameType } from '../../types';

export interface MetamaskProxyLoginButtonPropsType extends WithClassnameType, OnProviderLoginType {
  children?: ReactNode;
  buttonClassName?: string;
  loginButtonText?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const MetamaskProxyLoginButton: (props: MetamaskProxyLoginButtonPropsType & WithStylesImportType) => JSX.Element = ({
  token,
  callbackRoute,
  nativeAuth,
  onLoginRedirect,
  disabled,
  onClick
}) => {
  const [onInitiateLogin] = useMetamaskProxyLogin({
    callbackRoute,
    token,
    onLoginRedirect,
    nativeAuth: true
  });
  const disabledConnectButton = getIsNativeAuthSingingForbidden(token);

  const handleLogin = () => {
    onClick();
    onInitiateLogin();
  };

  return (
    <button onClick={handleLogin} disabled={disabled || disabledConnectButton} className='flex pb-2 pr-2 justify-between items-center'>
      <div className='flex gap-3 items-center'>
        <div className='w-7 h-7 bg-black flex items-center justify-center'>
          <object data='/img/connect/metamask.svg' type='image/svg+xml' className='h-4 w-auto' />
        </div>
        <span className='text-md text-gray-900'>Metamask</span>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className='text-[#888888] text-md' />
    </button>
  );
};
