import { faArrowRight, faArrowTrendUp, faChartSimple, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetIsLoggedIn, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import ashSwapLogo from 'assets/img/ashswap-logo.png';
import dexScreenerLogo from 'assets/img/dexscreener-logo.png';
import eCompassLogo from 'assets/img/ecompass-logo.png';
import jexchangeLogo from 'assets/img/jexchange-logo.png';
import peerMeLogo from 'assets/img/peerme-logo.png';
import xExchangeLogo from 'assets/img/xexchange-logo.png';
import { LaunchpadSaleProps } from './LaunchpadSale';
import { LoginMethodsEnum } from '@multiversx/sdk-dapp/types';

export const LaunchpadPartnersLinks = ({ launchpad }: LaunchpadSaleProps) => {
  const isLoggedIn = useGetIsLoggedIn();
  const loginInfo = useGetLoginInfo();
  const isOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const xExchangeLink = `https://xexchange.com/swap?firstToken=EGLD&secondToken=${launchpad.token.identifier}${isLoggedIn ? `&accessToken=${loginInfo.tokenLogin?.nativeAuthToken}` : ''}`;

  const ashSwapLink = `https://app.ashswap.io/?inputToken=EGLD&outputToken=${launchpad.token.identifier}${isLoggedIn ? `&accessToken=${loginInfo.tokenLogin?.nativeAuthToken}` : ''}`;
  const jexchangeLink = `https://app.jexchange.io/?paymentToken=EGLD&buyToken=${launchpad.token.identifier}${isLoggedIn ? `&accessToken=${loginInfo.tokenLogin?.nativeAuthToken}` : ''}`;
  const eCompassLink = `https://e-compass.io/charts/XEXCHANGE/${launchpad.token.ticker}/WEGLD?${isLoggedIn ? `&accessToken=${loginInfo.tokenLogin?.nativeAuthToken}` : ''}`;
  const dexScreenerLink = `https://dexscreener.com/multiversx/${launchpad.lpAddress}`;
  const peerMeLink = `https://peerme.io/start?accessToken=${loginInfo.tokenLogin?.nativeAuthToken}`;

  return (
    <div className='flex flex-col gap-3 py-3 w-full  text-black'>
      <div className='flex flex-row divide-x-[1px] divide-gray-400 w-full'>
        <div className='flex flex-row justify-start w-1/3'>
          <div className='flex flex-row h-fit items-center'>
            <p className='text-md mr-3'>Trading</p>
            <FontAwesomeIcon icon={faArrowTrendUp} className='h-3' />
          </div>
        </div>
        <div className='flex flex-col pl-5 md:pl-1 w-2/3 gap-3'>
          <a
            href={xExchangeLink}
            target={`${loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && !isOnMobile ? '_self' : '_blank'}`}
            className='flex flex-row text-sm items-center justify-between'
          >
            <div className='flex flex-row gap-3 items-center mr-1'>
              <img src={xExchangeLogo} className='h-6' />
              <p className=''>Trade on xExchange</p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className='h-3' />
          </a>
          <a
            href={ashSwapLink}
            target={`${loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && !isOnMobile ? '_self' : '_blank'}`}
            className='flex flex-row text-sm items-center justify-between'
          >
            <div className='flex flex-row gap-3 items-center'>
              <img src={ashSwapLogo} className='h-6' />
              <p className=''>Trade on AshSwap</p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className='h-3' />
          </a>
          <a
            href={jexchangeLink}
            target={`${loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && !isOnMobile ? '_self' : '_blank'}`}
            className='flex flex-row text-sm items-center justify-between'
          >
            <div className='flex flex-row gap-3 items-center mr-1'>
              <img src={jexchangeLogo} className='h-6' />
              <p className=''>Trade on JEXchange</p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className='h-3' />
          </a>
        </div>
      </div>
      <div className='flex flex-row divide-x-[1px] divide-gray-400 w-full'>
        <div className='flex flex-row justify-start w-1/3'>
          <div className='flex flex-row h-fit items-center'>
            <p className='text-md mr-3'>Charts</p>
            <FontAwesomeIcon icon={faChartSimple} className='h-3' />
          </div>
        </div>
        <div className='flex flex-col pl-5 md:pl-1 w-2/3 gap-3'>
          <a
            href={eCompassLink}
            target={`${loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && !isOnMobile ? '_self' : '_blank'}`}
            className='flex flex-row text-sm items-center justify-between'
          >
            <div className='flex flex-row gap-3 items-center'>
              <img src={eCompassLogo} className='h-6' />
              <p className=''>View on E-Compass</p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className='h-3' />
          </a>
          <a
            href={dexScreenerLink}
            target={`${loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && !isOnMobile ? '_self' : '_blank'}`}
            className='flex flex-row text-sm items-center justify-between'
          >
            <div className='flex flex-row gap-3 items-center'>
              <img src={dexScreenerLogo} className='h-6' />
              <p className=''>View on DEXScreener</p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className='h-3' />
          </a>
        </div>
      </div>
      <div className='flex flex-row divide-x-[1px] divide-gray-400 w-full'>
        <div className='flex flex-row justify-start w-1/3'>
          <div className='flex flex-row h-fit items-center'>
            <p className='text-md mr-3'>Other</p>
            <FontAwesomeIcon icon={faScrewdriverWrench} className='h-3' />
          </div>
        </div>
        <div className='flex flex-col pl-5 md:pl-1 w-2/3 gap-3'>
          <a
            href={peerMeLink}
            target={`${loginInfo.loginMethod === LoginMethodsEnum.walletconnectv2 && !isOnMobile ? '_self' : '_blank'}`}
            className='flex flex-row text-sm items-center justify-between'
          >
            <div className='flex flex-row gap-3  items-center'>
              <img src={peerMeLogo} className='h-6' />
              <p className=''>Create a DAO on PeerMe</p>
            </div>
            <FontAwesomeIcon icon={faArrowRight} className='h-3' />
          </a>
        </div>
      </div>
    </div>
  );
};
