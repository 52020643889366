import { AbiRegistry, SmartContract, Address } from '@multiversx/sdk-core/out';
import json from './airdrop.abi.json';
import { airdropSmartContractAddress } from 'config';

const abi = AbiRegistry.create(json);

export const airdropSmartContract = new SmartContract({
  address: new Address(airdropSmartContractAddress),
  abi
});
