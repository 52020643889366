export const countdownRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
  const padNumber = (val: number) => {
    return val < 10 ? `0${val}` : val.toString();
  }

  return (
    <div className="flex">
      <div className="flex flex-col">
        <span className="text-2xl text-orange-600">{padNumber(days)}</span> days
      </div>
      <span className="text-2xl text-gray-400">:</span>
      <div className="flex flex-col">
        <span className="text-2xl text-orange-600">{padNumber(hours)}</span> hrs
      </div>
      <span className="text-2xl text-gray-400">:</span>
      <div className="flex flex-col">
        <span className="text-2xl text-orange-600">{padNumber(minutes)}</span> min
      </div>
      <span className="text-2xl text-gray-400">:</span>
      <div className="flex flex-col">
        <span className="text-2xl text-orange-600">{padNumber(seconds)}</span> sec
      </div>
    </div>
  );
}
