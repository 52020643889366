import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "components";
import { useDeleteDraftLaunchpad } from "../hooks";

interface DeleteDraftLaunchpadModalProps {
  show: boolean;
  id: string;
  onHide: () => void;
}

export const DeleteDraftLaunchpadModal = ({ show, onHide, id }: DeleteDraftLaunchpadModalProps) => {
  const { deleteLaunchpad, isLoading, isError } = useDeleteDraftLaunchpad({
    onSuccess: onHide,
  });

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete launchpad?</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col'>
          <p>Do you want to delete this draft launchpad?</p>
        </div>
        <DialogFooter className='flex flex-col gap-4'>
          <div className="flex gap-2">
            <Button variant='ghost' className="w-full" onClick={onHide}>Cancel</Button>
            <Button variant='default' className="w-full border-status-danger bg-[#e35d6a] text-white hover:bg-status-danger" onClick={() => deleteLaunchpad(id)} disabled={isLoading}>
              {isLoading ? 'Loading..' : 'Delete'}
            </Button>
          </div>
          {isError && (<p className='text-sm text-status-danger text-center'>An error has occurred.</p>)}
        </DialogFooter>
      </DialogContent>
    </Dialog >
  );
};
