import { useGetAccount, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { UploadTokenBrandingDto } from 'types';

const postQuestIdToComplete = async (id: string, nativeAuthToken?: string): Promise<UploadTokenBrandingDto> => {

  try {
    const { data } = await axios.post(`/v1/quests/${id}/complete`, {}, {
      baseURL: API_URL, headers: {
        Authorization: `Bearer ${nativeAuthToken}`
      }
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const usePostQuestIdToComplete = (refetch: (() => Promise<any>) | (() => void) | undefined) => {
  const loginInfo = useGetLoginInfo();
  const [completeLoading, setCompleteLoading] = useState<string | undefined>(undefined);

  const mutation = useMutation<any, any, string>(
    (id) => postQuestIdToComplete(id, loginInfo.tokenLogin?.nativeAuthToken),
    {
      onSuccess: () => {
        setTimeout(() => {
          setCompleteLoading(undefined);
          refetch?.();
        }, 30000); // 30 seconds delay
      },
    }
  );

  const startPostQuestIdToComplete = (id: string) => {
    mutation.mutate(id);
    setCompleteLoading(id);
  };

  return { startPostQuestIdToComplete, completeLoading, ...mutation };
};
