import { ChangeEvent, useEffect, useState } from 'react';
import { CreateLaunchpadDTO, CreateTokenDTO, LaunchpadState, LaunchpadStepStatus } from 'types';
import { Button, Input } from 'components';
import { useMutation } from 'react-query';
import BigNumber from 'bignumber.js';
import axios, { AxiosError } from 'axios';
import { API_URL } from 'config';
import { useNavigate } from 'react-router-dom';
import { RouteNamesEnum } from 'localConstants';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';

interface CreateTokenStepProps {
  launchpad?: CreateLaunchpadDTO;
  onContinue: () => void;
}

export const useCreateLaunchpad = () => {
  const loginInfo = useGetLoginInfo();

  const postCreateLaunchpad = async (token: CreateTokenDTO): Promise<string> => {
    try {
      const payload = {
        ...token,
        supply: new BigNumber(token.supply).shiftedBy(token.decimals).toFixed()
      };

      const { data } = await axios.post(
        `/v1/launchpads/create`,
        payload,
        {
          baseURL: API_URL,
          headers: {
            Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
          }
        }
      );
      return data.id as string;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        throw axiosError.response.data;
      } else {
        throw { message: axiosError.message ?? 'An error occurred' };
      }
    }
  };

  const { data, error, isLoading, mutate } = useMutation<string, any, CreateTokenDTO>(postCreateLaunchpad);

  const createLaunchpad = (payload: CreateTokenDTO) => {
    mutate(payload);
  };

  return { data, error, isLoading, createLaunchpad };
};

const useUpdateLaunchpad = () => {
  const loginInfo = useGetLoginInfo();

  const patchUpdateLaunchpad = async ({ id, data }: { id: string, data: CreateTokenDTO; }): Promise<string> => {
    try {
      const payload = {
        step: LaunchpadState.WAITING_FOR_CREATE,
        token: {
          ...data,
          supply: new BigNumber(data.supply).shiftedBy(data.decimals).toFixed()
        }
      };
      const { data: response } = await axios.patch(
        `/v1/launchpads/create/${id}`,
        payload,
        {
          baseURL: API_URL,
          headers: {
            Authorization: `Bearer ${loginInfo.tokenLogin?.nativeAuthToken}`
          }
        }
      );
      return response.id as string;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        throw axiosError.response.data;
      } else {
        throw { message: axiosError.message ?? 'An error occurred' };
      }
    }
  };

  const { data, error, isLoading, mutate } = useMutation<string, any, { id: string, data: CreateTokenDTO; }>(patchUpdateLaunchpad);

  const updateLaunchpad = (id: string, data: CreateTokenDTO) => {
    mutate({ id, data });
  };

  return { data, error, isLoading, updateLaunchpad };
};

export const CreateTokenStep = ({ launchpad, onContinue }: CreateTokenStepProps) => {
  const navigate = useNavigate();

  const [isEdited, setIsEdited] = useState(launchpad ? false : true);
  const [formValues, setFormValues] = useState({
    name: launchpad?.token.name || '',
    ticker: launchpad?.token.ticker || '',
    decimals: 18,
    supply: launchpad?.token.supply
      ? new BigNumber(launchpad.token.supply).shiftedBy(-launchpad.token.decimals).toFixed()
      : ''
  });
  const { data: createLaunchpadId, error: createError, isLoading: createLoading, createLaunchpad } = useCreateLaunchpad();
  const { data: updatedLaunchpad, error: updateError, isLoading: updateLoading, updateLaunchpad } = useUpdateLaunchpad();

  const isLoading = createLoading || updateLoading;
  const error = createError || updateError;
  const isPendingVerification = launchpad?.currentStepData?.status === LaunchpadStepStatus.PENDING_VERIFICATION;

  useEffect(() => {
    if (createLaunchpadId) {
      navigate(RouteNamesEnum.createLaunchpad.replace(':id', createLaunchpadId));
    }
  }, [createLaunchpadId]);

  useEffect(() => {
    if (updatedLaunchpad) {
      onContinue();
    }
  }, [updatedLaunchpad]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);

    const { name, value } = e.target;

    let parsedValue: string | number = value;
    if (name === 'ticker') {
      parsedValue = value.toUpperCase();
    }
    if (name === 'decimals') {
      parsedValue = parseInt(value);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  const onSubmit = () => {
    if (!isEdited) {
      onContinue();
      return;
    }

    if (!launchpad) {
      createLaunchpad(formValues);
    } else {
      updateLaunchpad(launchpad.id, formValues);
    }
  };

  return (
    <div className='flex flex-col gap-12 py-12 text-gray-600'>
      <div className='flex flex-col gap-4 items-center'>
        <h1 className='text-2xl text-primary-200 text-center'>Define your token’s identity</h1>
        <p className='max-w-md text-center leading-4 text-md'>These details are permanent, so choose wisely!</p>
      </div>
      <div className='flex flex-col gap-4 mx-auto w-full max-w-md'>
        <Input
          type='text'
          name='name'
          label='Token Name'
          value={formValues!.name}
          placeholder='ex. PepeVerse'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.name ?? error?.invalidProperties?.token?.name}
          disabled={isLoading || isPendingVerification}
        />
        <Input
          type='text'
          name='ticker'
          label='Abbreviation (max 10 characters)'
          value={formValues!.ticker}
          placeholder='ex. PEPE'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.ticker ?? error?.invalidProperties?.token?.ticker}
          disabled={isLoading || isPendingVerification}
        />
        <Input
          type='number'
          name='supply'
          label='Supply'
          value={formValues!.supply}
          placeholder='ex. 100,000,000'
          onChange={handleInputChange}
          errors={error?.invalidProperties?.supply ?? error?.invalidProperties?.token?.supply}
          min={0}
          step={1}
          disabled={isLoading || isPendingVerification}
        />
      </div>
      <div className='flex flex-col items-center gap-2'>
        <Button
          className='min-w-[170px]'
          disabled={isLoading || isPendingVerification}
          onClick={onSubmit}
        >
          {isLoading ? 'Loading..' : 'Save & Continue'}
        </Button>
        {error?.message && (<p className='text-xs text-status-danger'>{error.message}</p>)}
        {isPendingVerification && (<p className='text-xs text-orange-600'>Token branding verification in progress.</p>)}
      </div>
    </div>
  );
};
