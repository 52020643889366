import { DefaultToastDeleteButton } from '@multiversx/sdk-dapp/UI/TransactionsToastList/components/TransactionToast/components';
import { TransactionToastContentElementsProps } from '@multiversx/sdk-dapp/UI/TransactionsToastList/components/TransactionToast/transactionToast.type';
import { DataTestIdsEnum } from '@multiversx/sdk-dapp/constants';
import { SignedTransactionType } from '@multiversx/sdk-dapp/types';
import { ReactNode } from 'react';
import XMarkIcon from '../../assets/img/xmark.svg?react';
import { TransactionDetailsComponent } from './TransactionDetails';
import { ToastDataState } from './getToastDataStateByStatus';

// TODO: Rename to "TransactionToastContentPropsType" when sdk-dapp@3.0.0
export interface TransactionToastContentProps {
  style: Record<string, string>;
  toastDataState: ToastDataState;
  transactions: SignedTransactionType[];
  showCloseButton?: boolean;
  onDeleteToast?: () => void;
  toastTitle?: string | ReactNode;
  isTimedOut?: boolean;
  customElements?: TransactionToastContentElementsProps;
}
export const TransactionToastContent = ({
  style,
  toastDataState,
  transactions,
  showCloseButton,
  onDeleteToast,
  toastTitle,
  isTimedOut,
  customElements = {
    CustomCloseButton: DefaultToastDeleteButton,
    TransactionDetails: TransactionDetailsComponent
  }
}: TransactionToastContentProps) => {
  const TransactionDetails = customElements?.TransactionDetails;
  const CustomCloseButton = customElements?.CustomCloseButton;

  const Icon = toastDataState.icon;

  return (
    <div className={style.content} data-testid={DataTestIdsEnum.transactionToastContent}>
      <div className='flex'>
        <div className='mt-1'>{<Icon />}</div>

        <div className='flex flex-col ml-2'>
          <span className='text-[18px]'>{toastDataState.title}</span>

          <div className={style.footer}>{TransactionDetails && <TransactionDetails transactions={transactions} title={toastTitle} isTimedOut={isTimedOut} />}</div>
        </div>

        {showCloseButton && CustomCloseButton &&
          <div className='border-l-[1px] border-l-gray-300 justify-center items-center flex pl-5 pr-2 cursor-pointer'>
            <XMarkIcon onClick={onDeleteToast} />
          </div>
        }
      </div>
    </div>
  );
};
