import { faDiscord, faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/__base__';
import { getClickableLink } from 'helpers';
import { Socials } from 'types';

interface LaunchpadUrlsProps {
  urls: Socials;
}

export const LaunchpadUrls = ({ urls }: LaunchpadUrlsProps) => {
  return (
    <div className='flex gap-2'>
      {urls.website && (
        <a href={getClickableLink(urls.website)} target='_blank' rel='noreferrer noopener'>
          <Button size='icon-sm' variant='outline' className='text-gray-600 border-gray-600 hover:bg-gray-600'>
            <FontAwesomeIcon icon={faGlobe} />
          </Button>
        </a>
      )}
      {urls.telegram && (
        <a href={getClickableLink(urls.telegram)} target='_blank' rel='noreferrer noopener'>
          <Button size='icon-sm' variant='outline' className='text-[#087990] border-[#087990] hover:bg-[#087990]'>
            <FontAwesomeIcon icon={faTelegram} />
          </Button>
        </a>
      )}
      {urls.twitter && (
        <a href={getClickableLink(urls.twitter)} target='_blank' rel='noreferrer noopener'>
          <Button size='icon-sm' variant='outline' className='text-[#212529] border-[#212529] hover:bg-[#212529]'>
            <FontAwesomeIcon icon={faXTwitter} />
          </Button>
        </a>
      )}
      {urls.discord && (
        <a href={getClickableLink(urls.discord)} target='_blank' rel='noreferrer noopener'>
          <Button size='icon-sm' variant='outline' className='text-[#520DC2] border-[#520DC2] hover:bg-[#520DC2]'>
            <FontAwesomeIcon icon={faDiscord} />
          </Button>
        </a>
      )}
    </div>
  );
};
