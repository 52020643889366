import { useQuery } from 'react-query';
import axios from 'axios';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { NonFungibleTokenOfAccountOnNetwork } from '@multiversx/sdk-network-providers/out';
import { airdropCollection } from 'config';

const fetchNft = async (apiAddress: string, address: string) => {
  try {
    const identifier = `${airdropCollection}-01`;
    const response = await axios.get<any>(`${apiAddress}/accounts/${address}/nfts/${identifier}`);
    return response.data as NonFungibleTokenOfAccountOnNetwork;
  } catch (error) {
    return undefined;
  }
};

export const useCheckIfUserHasAirdrop = (address: string) => {
  const { network } = useGetNetworkConfig();

  return useQuery<NonFungibleTokenOfAccountOnNetwork | undefined, Error>(['nft', address, network.apiAddress], () => fetchNft(network.apiAddress, address), {
    enabled: !!address,
    retry: false,
    refetchOnWindowFocus: false
  });
};
