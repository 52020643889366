import React, { ReactNode } from 'react';
import { getIsNativeAuthSingingForbidden } from '@multiversx/sdk-dapp/services/nativeAuth/helpers';
import {
  UseWebWalletLoginPropsType,
  useWebWalletLogin
} from '@multiversx/sdk-dapp/hooks/login/useWebWalletLogin';
import { WithClassnameType } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';

export interface WebWalletLoginButtonPropsType
  extends UseWebWalletLoginPropsType,
  WithClassnameType {
  disabled?: boolean;
}

export const WebWalletLoginButton: (
  props: WebWalletLoginButtonPropsType
) => JSX.Element = ({
  token,
  disabled,
}) => {
    const [onInitiateLogin] = useWebWalletLogin({
      callbackRoute: window.location.pathname,
      nativeAuth: true,
      token,
    });
    const disabledConnectButton = getIsNativeAuthSingingForbidden(token);

    const handleLogin = () => {
      onInitiateLogin();
    };

    return (
      <button
        disabled={disabled || disabledConnectButton}
        onClick={handleLogin}
        className='flex pb-2 pr-2 justify-between items-center'
      >
        <div className='flex gap-3 items-center'>
          <div className='w-7 h-7 bg-black flex items-center justify-center'>
            <object data="/img/connect/web-wallet.svg" type="image/svg+xml" className='h-4 w-auto' />
          </div>
          <span className="text-md text-gray-900">Web Wallet</span>
        </div>
        <FontAwesomeIcon icon={faChevronRight} className='text-[#888888] text-md' />
      </button>
    )
  };