import { Button, Card, FormatAmount, LaunchpadIcon, LaunchpadProgress, LaunchpadStatus } from "components";
import { RouteNamesEnum } from "localConstants";
import { Link } from "react-router-dom";
import { Launchpad, SaleStatus } from "types";
import BigNumber from "bignumber.js";
import { countDecimalPlaces } from "utils/countDecimalPlaces";
import Countdown from "react-countdown";
import { countdownRenderer } from "pages/Launchpad/components/countdownRenderer";

interface LaunchpadsListItemProps {
  launchpad: Launchpad;
}

export const LaunchpadsListItem = ({ launchpad }: LaunchpadsListItemProps) => {
  const progress = new BigNumber(launchpad.currentRaisedAmount).dividedBy(launchpad.maxAmountToRaise).multipliedBy(100).decimalPlaces(2).toString();
  const exchangeRateRaw = new BigNumber(1).shiftedBy(18).dividedBy(launchpad.tokenPrice);
  const exchangeRate = exchangeRateRaw.isLessThan(100) ? exchangeRateRaw.toFixed(4) : exchangeRateRaw.integerValue().toFixed();

  const renderProgress = () => {
    const startTime = launchpad.startTimestamp * 1000 + 6000;

    if (launchpad.status === SaleStatus.SCHEDULED) {
      return (
        <div className="flex-grow flex flex-col justify-end">
          <div className="flex flex-col p-6 border">
            <div className='flex flex-col text-center  items-center'>
              <Countdown date={new Date(startTime)} renderer={countdownRenderer} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span>Progress</span>
          <span className="text-md text-green-500">{progress}%</span>
        </div>
        <div>
          <LaunchpadProgress progress={parseFloat(progress)} size="lg" />
        </div>
        <div className="text-center text-md">
          <FormatAmount value={launchpad.currentRaisedAmount} egldLabel=' ' digits={countDecimalPlaces(launchpad.currentRaisedAmount, launchpad.token.decimals)} />{' / '}
          <FormatAmount value={launchpad.maxAmountToRaise} digits={countDecimalPlaces(launchpad.maxAmountToRaise, launchpad.token.decimals)} />
        </div>
      </div>
    );
  };

  return (
    <Card className="flex flex-col gap-6">
      <div className="flex justify-between items-start">
        <div className="w-32">
          <span className="border border-gray-200 px-4 py-2 h-11 inline-block">Fair Launch</span>
        </div>
        <div className="w-32 flex justify-end">
          <LaunchpadStatus status={launchpad.status} swapsEnabled={launchpad.swapsEnabled} />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-[-1.5rem]">
        <LaunchpadIcon icon={launchpad.token.assets?.pngUrl ?? launchpad.token.assets?.svgUrl} />
        <div className="text-center">
          <h5 className="text-primary-200 text-2xl leading-9">{launchpad.token.name}</h5>
          <p className="text-gray-900 text-xl leading-6">
            1 EGLD = {exchangeRate} {launchpad.token.ticker}
          </p>
        </div>
      </div>
      {renderProgress()}
      <div>
        <Link to={RouteNamesEnum.launchpadDetails.replace(':slug', launchpad.slug)}>
          <Button className="w-full" size="sm">View</Button>
        </Link>
      </div>
    </Card>
  );
};
