import { EnvironmentsEnum } from 'types';

export * from './sharedConfig';

export const API_URL = 'https://api.memeversx.com';
export const MVX_API_URL = 'https://api.multiversx.com';
export const EXPLORER_URL = 'https://explorer.multiversx.com';
export const sampleAuthenticatedDomains = [API_URL];
export const environment = EnvironmentsEnum.mainnet;
export const airdropSmartContractAddress = 'erd1qqqqqqqqqqqqqpgq672uprklqj6qvx4kw02mlwexqw9ss63f984s2f46zg';
export const airdropCollection = 'MMVXDROP-7a5acb';
export const metamaskSnapWalletAddress = 'https://snap-wallet.multiversx.com';
