import { ReactNode } from 'react';

interface ActionOrLinkTwitterProps {
  defaultComponent: ReactNode;
  connectedComponent: ReactNode;
  hasTwitterLinked: boolean;
}

const ActionOrLinkTwitter = ({ defaultComponent, connectedComponent, hasTwitterLinked }: ActionOrLinkTwitterProps) => {
  return hasTwitterLinked ? <>{connectedComponent}</> : <>{defaultComponent}</>;
};

export default ActionOrLinkTwitter;
