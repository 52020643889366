import * as React from "react"

import { cn } from "utils/cn"

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  errors?: string[];
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, errors, ...props }, ref) => {
    const isInvalidInput = errors && errors.length > 0;

    return (
      <div className="flex flex-col gap-2">
        {label && (<label className="text-gray-600">{label}</label>)}
        <textarea
          className={cn(
            `flex w-full border border-input bg-background px-5 py-2 ring-offset-background file:border-0 file:bg-transparent file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 ${isInvalidInput ? 'border-status-danger' : ''}`,
            className
          )}
          ref={ref}
          {...props}
        />
        {isInvalidInput && <>
          {errors.map(err => (
            <p className="text-xs text-status-danger" key={err}>{err}</p>
          ))}
        </>}
      </div>
    )
  }
)
Textarea.displayName = "Textarea"

export { Textarea }
