import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {
  AxiosInterceptorContext, // using this is optional
  DappProvider,
  Layout,
  NotificationModal,
  SignTransactionsModals
  // uncomment this to use the custom transaction tracker
  // TransactionsTracker
} from 'components';

import { apiTimeout, walletConnectV2ProjectId, environment, sampleAuthenticatedDomains, metamaskSnapWalletAddress } from 'config';
import { RouteNamesEnum } from 'localConstants';
import { PageNotFound } from 'pages';
import { routes } from 'routes';
import { BatchTransactionsContextProvider } from 'wrappers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TransactionsToastListComponent } from 'components/TransactionToast/TransactionToastList';
import * as Sentry from '@sentry/react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { useEffect } from 'react';
import { saveToLocalStorage } from 'helpers';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAVxVBoQBV1sVMKV8fIMb_sZdm66F0AkCM',
  authDomain: 'memeversx-b5e40.firebaseapp.com',
  projectId: 'memeversx-b5e40',
  storageBucket: 'memeversx-b5e40.appspot.com',
  messagingSenderId: '80560358528',
  appId: '1:80560358528:web:f481eb9e800aceed67d265',
  measurementId: 'G-BH2Z5WF8FJ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Sentry.init({
  dsn: 'https://45fbd82b699cf0f7843da879bcec11d7@o4506965832040448.ingest.us.sentry.io/4506965834203136',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient();

const AppContent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get('ref');

  useEffect(() => {
    if (referralCode) {
      saveToLocalStorage('referralCode', referralCode);
    }
  }, [referralCode]);

  return (
    <DappProvider
      environment={environment}
      customNetworkConfig={{
        name: 'customConfig',
        apiTimeout,
        walletConnectV2ProjectId,
        metamaskSnapWalletAddress
      }}
      dappConfig={{
        shouldUseWebViewProvider: true,
        logoutRoute: RouteNamesEnum.home
      }}
      customComponents={{
        transactionTracker: {
          // uncomment this to use the custom transaction tracker
          // component: TransactionsTracker,
          props: {
            onSuccess: (sessionId: string) => {
              console.log(`Session ${sessionId} successfully completed`);
            },
            onFail: (sessionId: string, errorMessage: string) => {
              console.log(`Session ${sessionId} failed. ${errorMessage ?? ''}`);
            }
          }
        }
      }}
    >
      <AxiosInterceptorContext.Listener>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <TransactionsToastListComponent />
            <NotificationModal />
            <SignTransactionsModals />
            <Routes>
              {routes.map((route) => (
                <Route path={route.path} key={`route-key-'${route.path}`} element={<route.component />} />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
        </QueryClientProvider>
      </AxiosInterceptorContext.Listener>
    </DappProvider>
  );
};

export const App = () => {
  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor authenticatedDomains={sampleAuthenticatedDomains}>
        <Router>
          <BatchTransactionsContextProvider>
            {/* uncomment the following line to enable the maintenance mode, then comment out the AppContent component */}
            {/* <Maintenance /> */}
            <AppContent />
          </BatchTransactionsContextProvider>
        </Router>
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
};
