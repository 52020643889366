import { useGetAccount, useGetIsLoggedIn, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import { API_URL } from 'config';
import { useQuery } from 'react-query';
import { QuestDTO } from 'types/karma.types';

const fetchLaunchpadQuests = async (id?: string, nativeAuthToken?: string) => {
  try {
    const { data } = await axios.get(`/v1/quests/launchpad/${id}`, { baseURL: API_URL, headers: { Authorization: `Bearer ${nativeAuthToken}` } });
    return data as QuestDTO[];
  } catch (error) {
    throw error;
  }
};

export const useGetLaunchpadQuests = (id?: string): {
  data?: QuestDTO[];
  isLoading?: boolean;
  error?: any;
  refetch?: () => Promise<any>;
} => {

  const loginInfo = useGetLoginInfo();
  const { address } = useGetAccount();

  const isLoggedIn = useGetIsLoggedIn();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['launchpad-quests', address, id, loginInfo.tokenLogin?.nativeAuthToken],
    queryFn: () => fetchLaunchpadQuests(id, loginInfo.tokenLogin?.nativeAuthToken),
    refetchOnWindowFocus: false,
    enabled: loginInfo.tokenLogin?.nativeAuthToken !== undefined && id !== undefined && isLoggedIn || !isLoggedIn
  });

  return { data, isLoading, error, refetch };
};
